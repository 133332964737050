import { get } from 'lodash';

const filterOption = (inputValue: string, option: any) => {
	const normalizedInput = inputValue
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');
	const normalizedSearchString =
		typeof get(option, 'props.children') === 'string'
			? get(option, 'props.children')
			: get(option, 'props.children.props.children');
	const targetString = normalizedSearchString
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '');
	const rgxp = new RegExp(`^${normalizedInput}`);

	return !!targetString.match(rgxp);
};

export default filterOption;
