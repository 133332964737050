import queryString from 'query-string';

export enum ResizeMode {
	CROP = 'crop',
	RESIZE = 'resize',
}

export enum FitMode {
	INSIDE = 'inside',
	FILL = 'fill',
	OUTSIDE = 'outside',
	COVER = 'cover',
}

/**
 * Joins given path segments into one, making sure each segment is separated from the other with exacly one slash.
 */
const slashJoin = (...paths: readonly string[]): string => {
	const lastIndex = paths.length - 1;

	return paths
		.filter((path: string) => path.length > 0)
		.map((path, index) => (index > 0 ? path.replace(/^\//, '') : path))
		.map((path, index) => (index < lastIndex ? path.replace(/\/$/, '') : path))
		.join('/');
};

const removeDeprecatedPrefix = (key: string): string => {
	return key.replace(/^\/?uploads\//, '');
};

const removeBaseUrl = (key: string): string => {
	if (key.startsWith(process.env.REACT_APP_IMAGE_URL as string)) {
		return key.substring((process.env.REACT_APP_IMAGE_URL as string).length);
	}
	if (key.startsWith(`${process.env.REACT_APP_STORAGE_SERVICE_URL as string}get/`)) {
		return key.substring(`${process.env.REACT_APP_STORAGE_SERVICE_URL as string}get/`.length);
	}
	return key;
};

const getImageId = (key: string): string => {
	return encodeUrlSafe(removeBaseUrl(removeDeprecatedPrefix(key)));
};

// URL can already be encoded from BE so we don't want to double-encode it
const encodeUrlSafe = (key: string): string => {
	return encodeURIComponent(decodeURIComponent(key));
};

/**
 * @deprecated Uses old lambda to resize images
 */
const getResizedImageUrlDeprecated = (
	key: string,
	width: number,
	height: number,
	resizeMode: ResizeMode = ResizeMode.CROP,
): string => {
	const dimensions = `${width}x${height}`;
	const imageId = getImageId(key);
	return slashJoin(process.env.REACT_APP_IMAGE_URL as string, dimensions, resizeMode, imageId);
};

/**
 * Uses newer storage service to resize images
 */
const getResizedImageUrlStorageService = (
	key: string,
	width: number,
	height: number,
	fitMode: FitMode = FitMode.INSIDE,
	withoutEnlargement = false,
	namespace = '',
): string => {
	const imageId = getImageId(key);
	const url = slashJoin(process.env.REACT_APP_STORAGE_SERVICE_URL as string, 'resize', imageId);
	const query = queryString.stringify({
		...(namespace && { namespace }),
		width,
		height,
		fit: fitMode,
		withoutEnlargement,
	});
	return `${url}?${query}`;
};

/**
 * Returns full URL of image with given dimensions which can be used in a `src` attribute.
 * Detects if image comes from old source or newer storage service
 */
const getResizedImageUrl = (
	key: string | undefined,
	width: number,
	height: number,
	resizeMode?: ResizeMode,
	fitMode?: FitMode,
	withoutEnlargement?: boolean,
	namespace = '',
): string => {
	if (key == null) {
		return '';
	}
	const urlInfo = key.split('?');
	if (urlInfo.length > 1) {
		const query = new URLSearchParams(urlInfo[1] || '');
		query.append('width', width.toString());
		
		if(fitMode) {
			query.append('fit', fitMode.toString());
		}
		if (height > 0) {
			query.append('height', height.toString());
		}
		if(namespace) {
			query.append('namespace', namespace);
		}
		if(withoutEnlargement) {
			query.append('withoutEnlargement', withoutEnlargement.toString());
		}

		return `${urlInfo[0]}?${query.toString()}`.replace('/get/', '/resize/');
	}
	
	if (key?.startsWith(process.env.REACT_APP_STORAGE_SERVICE_URL as string)) {
		return getResizedImageUrlStorageService(
			key,
			width,
			height,
			fitMode,
			withoutEnlargement,
			namespace,
		);
	}
	
	return getResizedImageUrlDeprecated(key, width, height, resizeMode);
};

export {
	slashJoin,
	removeDeprecatedPrefix,
	getResizedImageUrlDeprecated,
	getResizedImageUrlStorageService,
	getResizedImageUrl,
};
