import { LOGIN_PATHNAME } from 'pathnames';

export type CreateRefreshRequest = (response: Response) => Request;
const standardFetch = window.fetch;

const fetchFactory = (
	createRefreshRequest: CreateRefreshRequest,
	onRefreshResponse: (response: Response, originalRequest: Request) => Promise<Request>,
) => async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
	// send standard request
	const response = await standardFetch(input, init);

	// response is HTTP 401: Not Authorized
	if (response.status === 401) {
		// send refresh token request
		const refreshRequest = createRefreshRequest(response);
		const refreshResponse = await standardFetch(refreshRequest);

		if (!refreshResponse.ok) {
			window.history.pushState(null, '', LOGIN_PATHNAME);
		} else {
			// on refresh response
			const newRequest = await onRefreshResponse(refreshResponse, new Request(input, init));

			// refetch standard request
			return standardFetch(newRequest);
		}
	}
	return response;
};

export default fetchFactory;
