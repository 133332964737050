import { POWER_LIST_KW, POWER_LIST_HP } from 'constants/lists';

export default (type: string, value?: number, unit?: string) => {
	let fullPowerList = [];
	let powerList = [];

	if (unit === 'hp') {
		fullPowerList = POWER_LIST_HP;
	} else {
		fullPowerList = POWER_LIST_KW;
	}

	let powerTo = fullPowerList[fullPowerList.length - 1].value;
	let powerFrom = fullPowerList[0].value;

	if (type === 'to' && value) {
		powerTo = value;
	} else if (type === 'from' && value) {
		powerFrom = value;
	}

	powerList = fullPowerList.slice(
		fullPowerList.findIndex((item) => item.value === powerFrom),
		fullPowerList.findIndex((item) => item.value === powerTo) + 1,
	);
	return powerList;
};
