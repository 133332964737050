import React from 'react';
import { connect } from 'react-redux';
import { Dispatch as RDispatch } from 'redux';
import { Dispatch } from 'store';
import { Mutate, Get, GetDataError } from 'restful-react';
import { AuthErrors, APIError } from 'types';
import Cookies from 'js-cookie';
import { States as MutateStates } from 'restful-react/lib/Mutate';
import { merge, isEmpty } from 'lodash';
import { notification } from 'antd';
import getResponseErrorData from 'utils/getResponseErrorData';
import { Trans } from '@lingui/macro';

const mapDispatch = (dispatch: RDispatch) => ({
	setAccessToken: (dispatch as Dispatch).user.setAccessToken,
	removeUser: (dispatch as Dispatch).user.removeUser,
	setUser: (dispatch as Dispatch).user.setUser,
	setIsUserLoading: (dispatch as Dispatch).user.setIsUserLoading,
});

type LoginResponseData = {
	access_token: string;
	expires_in: number;
	token_type: 'bearer';
	scope: string | null;
	refresh_token: string;
};

type LoginStates = MutateStates<LoginResponseData, APIError<AuthErrors>>;

type LoginFunction = (credentials: { username: string; password: string }) => Promise<any>;

type LoginProps = ReturnType<typeof mapDispatch> & {
	children: (login: LoginFunction, states: LoginStates) => React.ReactNode;
};

const Login = ({ children, removeUser, setUser, setIsUserLoading }: LoginProps) => (
	<Get path="/api/me" lazy>
		{(_, { loading: isUserLoading, error: userError }, { refetch: getUser }) => (
			<Mutate path="/oauth/v2/token" verb="POST">
				{(mutate, states) => {
					const login: LoginFunction = async ({ username, password }) => {
						try {
							setIsUserLoading(true);
							removeUser();

							Cookies.remove('access_token');
							Cookies.remove('refresh_token');

							const { access_token, refresh_token } = await mutate({
								username,
								password,
								client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
								client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
								grant_type: 'password',
							});

							Cookies.set('access_token', access_token);
							Cookies.set('refresh_token', refresh_token);

							const user = await getUser();

							setUser(user);
							setIsUserLoading(false);
						} catch (e) {
							setIsUserLoading(false);
							removeUser();

							if (e.data !== undefined) {
								const error = e as GetDataError<APIError<AuthErrors>>;

								notification.error({
									message: getResponseErrorData(error).error_description,
								});
							} else {
								notification.error({
									message: <Trans>UNKNOWN_ERROR</Trans>,
								});
							}
						}
					};

					const error = merge({}, states.error, userError);

					const returnStates: LoginStates = {
						loading: states.loading || isUserLoading,
						error: !isEmpty(error) ? error : null,
					};

					return children(login, returnStates);
				}}
			</Mutate>
		)}
	</Get>
);

export default connect(() => ({}), mapDispatch)(Login);
