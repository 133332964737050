import { APIError } from 'types';
import { GetDataError } from 'restful-react/lib/Get';

function getResponseErrorData<TError = string>(
	error: GetDataError<APIError<TError>>,
): APIError<TError> {
	const errorData = error.data as APIError<TError>;

	return errorData;
}

export default getResponseErrorData;
