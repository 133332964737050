import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router';
import 'react-alice-carousel/lib/alice-carousel.css';
import { AdvertisedCarType } from 'types/car';
import { CAR_DETAIL_PATHNAME, ROOT_PATHNAME } from 'pathnames';
import { Loading } from 'components/Loading/Loading';
import useFetch from 'hooks/useFetch';

type ExternalCarDetailProps = RouteComponentProps<{ id: string }>;

export const ExternalCarDetail: React.FC<ExternalCarDetailProps> = ({ match }) => {
	const externalCarId = match.params.id;
	const { data, isLoading, error } = useFetch<AdvertisedCarType[]>(
		`/api/carvago-admin/listedcars?search=${encodeURIComponent(externalCarId)}`,
	);
	if (isLoading || !data) {
		return <Loading />;
	}
	if (error || data.length === 0 || data.length > 1) {
		return <Redirect to={`${ROOT_PATHNAME}?search=${encodeURIComponent(externalCarId)}`} />;
	}
	const internalCarId = data[0].id;
	return <Redirect to={CAR_DETAIL_PATHNAME.replace(':id', internalCarId.toString())} />;
};
