import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { Trans } from '@lingui/macro';
import { FormComponentProps } from 'antd/lib/form';

export type Values = {
	username: string;
	password: string;
};

type LoginType = FormComponentProps<Values> & {
	onSubmit: (values: Values) => void;
	loading: boolean;
};

const Login = (props: LoginType) => {
	const { getFieldDecorator, validateFields } = props.form;
	const { onSubmit, loading } = props;

	return (
		<Form
			onSubmit={(event) => {
				event.preventDefault();
				validateFields((err, values) => {
					if (!err) {
						onSubmit(values);
					}
				});
			}}
		>
			<Typography.Title level={4}>
				<Trans>LOG_IN_TITLE</Trans>
			</Typography.Title>
			<Form.Item>
				{getFieldDecorator('username', {
					rules: [{ required: true, message: 'Please input your username!' }],
				})(<Input placeholder="Username" size="large" disabled={loading} />)}
			</Form.Item>
			<Form.Item>
				{getFieldDecorator('password', {
					rules: [{ required: true, message: 'Please input your Password!' }],
				})(<Input type="password" placeholder="Password" size="large" disabled={loading} />)}
			</Form.Item>
			<Button size="large" type="primary" htmlType="submit" block loading={loading} data-testId="Login_form_login">
				<span>
					<Trans>LOG_IN_BUTTON</Trans>
				</span>
			</Button>
		</Form>
	);
};

export default Form.create<LoginType>({ name: 'login' })(Login);
