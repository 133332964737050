import styled from 'styled-components';

const TabIconWrapper = styled.div`
	color: ${({ theme }) => theme.colors.PRIMARY};
	margin-right: 15px;
	font-size: 30px;
	display: inline-block;
`;

export default TabIconWrapper;
