const getPlatformBaseUrl = (): string => {
	switch (process.env.REACT_APP_ENV) {
		case 'prod':
			return 'https://carvago.com/';
		case 'dev':
			return 'https://dev.carvago.dev/';
		case 'local':
			return 'http://localhost:3000/';
		default:
			throw Error(`Unsupported environemnt: ${process.env.REACT_APP_ENV}`);
	}
};

const getCarAuditPlatformBaseUrl = (): string => {
	switch (process.env.REACT_APP_ENV) {
		case 'prod':
			return 'https://carvago.com/';
		case 'dev':
		case 'local':
			return 'https://dev.carvago.dev/';
		default:
			throw Error(`Unsupported environemnt: ${process.env.REACT_APP_ENV}`);
	}
};

export { getPlatformBaseUrl, getCarAuditPlatformBaseUrl };
