import React from 'react';
import { getStatusColor } from 'utils/getStatusColor';
import { Tooltip, Icon } from 'antd';
import styled from 'styled-components';
import { CarStatus as ECarStatus } from 'types/car';
import { CarStatusLabel } from './CarStatusLabel';

interface Props {
	status: ECarStatus | string;
	label: string;
	errorLabel?: string;
}

const ErrorText = styled.div`
	display: flex;
	align-items: center;
	line-height: 1;
`;

const CustomIcon = styled(Icon)`
	margin-left: 5px;
`;

const Label = styled.span<{statusColor: string}>`
	color: ${props => props.statusColor};
`;

const CarStatus: React.FC<Props> = ({ status, label, errorLabel: error }) => {
	if (error) {
		return (
			<Tooltip placement="topRight" title={error}>
				<ErrorText>
					<Label statusColor={getStatusColor(status)}>
						<CarStatusLabel status={status} label={label} />
					</Label>{' '}
					<CustomIcon type="info-circle" />
				</ErrorText>
			</Tooltip>
		);
	}
	return <Label statusColor={getStatusColor(status)}>
		<CarStatusLabel status={status} label={label} />
	</Label>;
};

export default CarStatus;
