import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components/macro';
import defaultCarImage from 'static/images/default_car.jpeg';
import { getResizedImageUrl } from 'utils/images';

interface CarTableImageType {
	readonly imagePath?: string | null;
	readonly width?: string;
	readonly height?: string;
}

const Image = styled.img`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	object-fit: contain;
`;

export const CarTableImage: FunctionComponent<CarTableImageType> = ({
	imagePath,
	width = '62px',
	height = '47px',
}) => {
	const [error, setError] = useState<boolean>(false);
	const src =
		imagePath && !error
			? getResizedImageUrl(imagePath, parseInt(width, 10), parseInt(height, 10))
			: defaultCarImage;
	return <Image width={width} height={height} src={src} onError={() => setError(true)} />;
};
