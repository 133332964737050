import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { Dropdown, Icon, Menu, Switch, Tooltip } from 'antd';
import CarThumbnail from './CarReviewsImage';
import { ReviewText, StyledLink } from './styled';
import { Review } from './types';
import { ColumnProps } from 'antd/lib/table';

interface TableColumnsParams {
	readonly handleHomepageVisibilityToggle: (data: Review) => void;
	readonly deleteAfterConfirmation: (id: string) => void;
}

const { Item } = Menu;

export const ReviewEditLink: FunctionComponent<{ reviewId: string }> = ({ reviewId, children }) => (
	<StyledLink to={`/reviews-edit/${reviewId}`}>{children}</StyledLink>
);

export const reviewsTableColumns = ({
	handleHomepageVisibilityToggle,
	deleteAfterConfirmation,
}: TableColumnsParams): Array<ColumnProps<unknown>> => [
	{
		title: <Trans id="REVIEWS_ROW_PHOTO" />,
		width: 100,
		key: 'car_image',
		render: (data: Review) => <CarThumbnail reviewId={data.id} photo={data.photo} />,
	},
	{
		title: <Trans id="REVIEWS_ROW_ID" />,
		width: 150,
		key: 'review_id',
		render: (data: Review) => <ReviewEditLink reviewId={data.id}>{data.id}</ReviewEditLink>,
	},
	{
		title: <Trans id="REVIEWS_ROW_NAME" />,
		width: 150,
		key: 'review_customer_name',
		render: (data: Review) => <ReviewEditLink reviewId={data.id}>{data.customer_name}</ReviewEditLink>,
	},
	{
		title: <Trans id="REVIEWS_ROW_COUNTRY" />,
		width: 150,
		key: 'review_country',
		render: (data: Review) => <ReviewEditLink reviewId={data.id}>{data.country.name}</ReviewEditLink>,
	},
	{
		title: <Trans id="REVIEWS_ROW_ORDERING_NUMBER" />,
		width: 80,
		key: 'review_ordering_number',
		render: (data: Review) => <ReviewEditLink reviewId={data.id}>{data.ordering_number}</ReviewEditLink>,
	},
	{
		title: <Trans id="REVIEWS_ROW_TEXT" />,
		width: 300,
		key: 'review_text',
		render: (data: Review) => (
			<ReviewEditLink reviewId={data.id}>
				<Tooltip placement="top" title={data.review}>
					<ReviewText>{data.review}</ReviewText>
				</Tooltip>
			</ReviewEditLink>
		),
	},
	{
		title: <Trans id="REVIEWS_ROW_DATE" />,
		width: 100,
		key: 'review_date',
		render: (data: Review) => (
			<ReviewEditLink reviewId={data.id}>
				<div>
					{data.updated_at
						? moment(data.updated_at).format('D.M.Y')
						: moment(data.created_at).format('D.M.Y')}
				</div>
			</ReviewEditLink>
		),
	},
	{
		title: <Trans id="REVIEWS_ROW_AVAILABLE" />,
		width: 100,
		key: 'review_is_visible',
		render: (data: Review) => (
			<ReviewEditLink reviewId={data.id}>
				<Switch checked={data.is_visible} disabled />
			</ReviewEditLink>
		),
	},
	{
		title: <Trans id="REVIEWS_VISIBLE_ON_HOMEPAGE" />,
		width: 100,
		key: 'review_on_homepage',
		render: (data: Review) => (
			<Switch
				checked={data.homepage}
				disabled
				onChange={() => handleHomepageVisibilityToggle(data)}
				data-testid="reviews-column-visible-on-homepage"
			/>
		),
	},
	{
		title: <Trans id="REVIEWS_ROW_AKCE" />,
		key: 'controls',
		dataIndex: 'id',
		render: (id: string) => (
			<Dropdown
				overlay={
					<Menu>
						<Item key="1">
							<Link
								to={`/reviews-edit/${id}`}
								data-testid="Reviews-column-controls-edit"
							>
								<Trans id="REVIEWS_ROW_EDIT" />
							</Link>
						</Item>
						<Item
							key="2"
							onClick={() => {
								deleteAfterConfirmation(id);
							}}
							data-testid="Reviews-column-controls-delete"
						>
							<Trans id="REVIEWS_DELETE_CONFIRM_MODAL_CONFIRM" />
						</Item>
					</Menu>
				}
			>
				<Icon type="ellipsis" data-testid="Reviews-column-actions" />
			</Dropdown>
		),
		width: 100,
	},
];
