import { Table, Typography } from 'antd';
import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { getValueForField } from 'utils/getValueForField';
import { formatPrice } from 'utils/formatters';
import { useSelector } from 'react-redux';
import { selectReferenceData } from 'selectors/referenceData';
import useFetch from '../../../../../hooks/useFetch';
import { CarPricesPayload, CarPricesTableType, CarType } from '../types';
import CarPricePreview from './CarPricePreview';
import { CountryType } from 'types/country';
import styled from 'styled-components';
import { CustomColumnPropsType } from 'components/EditableTable';

const Section = styled.div`
	padding: 20px 0;
`;

export interface DBRecord extends Omit<CarType, 'price_currency'> {
	readonly price_currency: number;
}

interface CarPriceTabProps {
	readonly car: CarType;
}

const convertRecordToPayload = (record: CarPricesTableType | DBRecord): CarPricesPayload => {
	return {
		price: Number(record.price),
		price_without_vat:
			record.price_without_vat && record.price_without_vat > 0 && record.vat_reclaimable
				? Number(record.price_without_vat)
				: null,
		vat_reclaimable: record.vat_reclaimable,
		vat_rate: record.vat_rate ? Number(record.vat_rate) : null,
		price_currency: {
			id: record.price_currency,
		},
	};
};

const createColumns = (): Array<CustomColumnPropsType<CarType>> => [
	{
		title: <Trans id="CAR_PRICE_TABLE_VAT_RECLAIMABLE_TITLE" />,
		editable: false,
		dataIndex: 'vat_reclaimable',
		key: 'carcheck_vat_reclaimable',
		render: (_, record) =>
			record.price_information.original_price.vat_reclaimable ? (
				<Trans id="CAR_PRICE_TABLE_VAT_RECLAIMABLE_YES" />
			) : (
				<Trans id="CAR_PRICE_TABLE_VAT_RECLAIMABLE_NO" />
			),
		onCell: () => ({
			'data-testid': 'cell_vat_reclaimable',
		}),
	},
	{
		title: <Trans id="CAR_PRICE_TABLE_TITLE_VAT_TITLE" />,
		editable: false,
		dataIndex: 'vat_rate',
		key: 'carcheck_vat_rate',
		render: (_, record) => {
			const isVatReclaimable = record.price_information.original_price.vat_reclaimable;
			const vatRate = record.price_information.original_price.vat_rate;
			return isVatReclaimable ? getValueForField(vatRate && `${vatRate} %`) : '-';
		},
		onCell: () => ({
			'data-testid': 'cell_status',
		}),
	},
	{
		title: <Trans id="CAR_PRICE_TABLE_PRICE_TITLE" />,
		editable: false,
		dataIndex: 'price',
		key: 'carprice_price',
		render: (_, record) =>
			getValueForField(
				formatPrice(
					record.price_information.original_price.price,
					record.price_information.original_price.currency.name,
				),
			),
		onCell: () => ({
			'data-testid': 'cell_price',
		}),
	},
	{
		title: <Trans id="CAR_PRICE_TABLE_PRICE_WITHOUT_VAT_TITLE" />,
		editable: false,
		dataIndex: 'price_without_vat',
		key: 'carprice_price_without_vat',
		render: (_, record) =>
			getValueForField(
				record.price_information.original_price.price_without_vat &&
					formatPrice(
						record.price_information.original_price.price_without_vat,
						record.price_information.original_price.currency.name,
					),
			),
		onCell: () => ({
			'data-testid': 'cell_price_without_vat',
		}),
	},
	{
		title: <Trans id="CAR_PRICE_TABLE_CURRENCY_TITLE" />,
		editable: false,
		dataIndex: 'currency',
		key: 'carcheck_currency',
		render: (_, record) =>
			getValueForField(record.price_information.original_price.currency.name),
		onCell: () => ({
			'data-testid': 'cell_currency',
		}),
	},
];

const getRequestedList = (allowedCountryOptions: CountryType[]) =>
	allowedCountryOptions.map((item: CountryType) => ({
		country: {
			id: item.value,
		},
		currency: {
			id: item.currency.id,
		},
	}));

const CarPriceTable: React.FC<CarPriceTabProps> = ({ car }) => {
	const referenceData = useSelector(selectReferenceData);
	const countries = referenceData.allowedCountryOptions as CountryType[];
	const filteredCountries = countries.filter(
		(country) =>
			country.const_key === 'COUNTRY_CZECH_REPUBLIC' ||
			country.const_key === 'COUNTRY_SLOVAKIA' ||
			country.const_key === 'COUNTRY_POLAND',
	);
	const editedValues = {
		price: car.price_information.original_price.price,
		price_without_vat: car.price_information.original_price.price_without_vat,
		vat_reclaimable: car.price_information.original_price.vat_reclaimable,
		vat_rate: car.price_information.original_price.vat_rate,
		price_currency: car.price_information.original_price.currency.id,
	};

	const { fetchData: suggestedPrices, data } = useFetch(
		`/api/carvago-admin/listedcars/price/suggest-price-information`,
		{
			init: { method: 'POST' },
			lazy: true,
		},
	);

	useEffect(() => {
		const list = getRequestedList(filteredCountries);
		const payload = convertRecordToPayload(editedValues);

		suggestedPrices({}, { ...payload, requested_country_currency_tuples: list });
	}, []);

	return (
		<>
			<Typography.Title level={3}>
				<Trans id="CAR_PRICE_TITLE" />
			</Typography.Title>
			<Table<CarType>
				dataSource={[car]}
				columns={createColumns()}
				size="small"
				pagination={false}
				rowKey="id"
				loading={false}
				bordered
			/>
			<Section>
				<Typography.Title level={3}>
					<Trans id="CAR_PRICE_PREVIEW_TITLE" />
				</Typography.Title>
				<CarPricePreview suggestedPrices={data} />
			</Section>
		</>
	);
};

export default CarPriceTable;
