// Generated from src/AdvertisingRules.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');



var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0002\u0018\u00a9\b\u0001\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004",
    "\u0004\t\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t",
    "\u0007\u0004\b\t\b\u0004\t\t\t\u0004\n\t\n\u0004\u000b\t\u000b\u0004",
    "\f\t\f\u0004\r\t\r\u0004\u000e\t\u000e\u0004\u000f\t\u000f\u0004\u0010",
    "\t\u0010\u0004\u0011\t\u0011\u0004\u0012\t\u0012\u0004\u0013\t\u0013",
    "\u0004\u0014\t\u0014\u0004\u0015\t\u0015\u0004\u0016\t\u0016\u0004\u0017",
    "\t\u0017\u0003\u0002\u0003\u0002\u0003\u0003\u0006\u00033\n\u0003\r",
    "\u0003\u000e\u00034\u0003\u0003\u0003\u0003\u0003\u0004\u0003\u0004",
    "\u0003\u0004\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005",
    "\u0003\u0005\u0003\u0005\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006",
    "\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007",
    "\u0003\u0007\u0003\u0007\u0003\u0007\u0003\u0007\u0003\b\u0003\b\u0003",
    "\b\u0003\b\u0003\b\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003",
    "\t\u0003\t\u0003\t\u0003\n\u0003\n\u0003\n\u0003\n\u0003\u000b\u0003",
    "\u000b\u0003\u000b\u0003\f\u0003\f\u0007\fn\n\f\f\f\u000e\fq\u000b\f",
    "\u0003\r\u0005\rt\n\r\u0003\r\u0006\rw\n\r\r\r\u000e\rx\u0003\r\u0003",
    "\r\u0006\r}\n\r\r\r\u000e\r~\u0005\r\u0081\n\r\u0003\u000e\u0003\u000e",
    "\u0007\u000e\u0085\n\u000e\f\u000e\u000e\u000e\u0088\u000b\u000e\u0003",
    "\u000e\u0003\u000e\u0003\u000e\u0007\u000e\u008d\n\u000e\f\u000e\u000e",
    "\u000e\u0090\u000b\u000e\u0003\u000e\u0005\u000e\u0093\n\u000e\u0003",
    "\u000f\u0003\u000f\u0003\u0010\u0003\u0010\u0003\u0010\u0003\u0011\u0003",
    "\u0011\u0003\u0012\u0003\u0012\u0003\u0012\u0003\u0013\u0003\u0013\u0003",
    "\u0014\u0003\u0014\u0003\u0014\u0003\u0015\u0003\u0015\u0003\u0016\u0003",
    "\u0016\u0003\u0017\u0003\u0017\u0002\u0002\u0018\u0003\u0003\u0005\u0004",
    "\u0007\u0005\t\u0006\u000b\u0007\r\b\u000f\t\u0011\n\u0013\u000b\u0015",
    "\f\u0017\r\u0019\u000e\u001b\u000f\u001d\u0010\u001f\u0011!\u0012#\u0013",
    "%\u0014\'\u0015)\u0016+\u0017-\u0018\u0003\u0002\t\u0005\u0002\u000b",
    "\f\u000f\u000f\"\"\u0005\u0002C\\aac|\u0006\u00022;C\\aac|\u0004\u0002",
    "--//\u0003\u00022;\u0005\u0002\f\f\u000f\u000f))\u0005\u0002\f\f\u000f",
    "\u000f$$\u0002\u00b1\u0002\u0003\u0003\u0002\u0002\u0002\u0002\u0005",
    "\u0003\u0002\u0002\u0002\u0002\u0007\u0003\u0002\u0002\u0002\u0002\t",
    "\u0003\u0002\u0002\u0002\u0002\u000b\u0003\u0002\u0002\u0002\u0002\r",
    "\u0003\u0002\u0002\u0002\u0002\u000f\u0003\u0002\u0002\u0002\u0002\u0011",
    "\u0003\u0002\u0002\u0002\u0002\u0013\u0003\u0002\u0002\u0002\u0002\u0015",
    "\u0003\u0002\u0002\u0002\u0002\u0017\u0003\u0002\u0002\u0002\u0002\u0019",
    "\u0003\u0002\u0002\u0002\u0002\u001b\u0003\u0002\u0002\u0002\u0002\u001d",
    "\u0003\u0002\u0002\u0002\u0002\u001f\u0003\u0002\u0002\u0002\u0002!",
    "\u0003\u0002\u0002\u0002\u0002#\u0003\u0002\u0002\u0002\u0002%\u0003",
    "\u0002\u0002\u0002\u0002\'\u0003\u0002\u0002\u0002\u0002)\u0003\u0002",
    "\u0002\u0002\u0002+\u0003\u0002\u0002\u0002\u0002-\u0003\u0002\u0002",
    "\u0002\u0003/\u0003\u0002\u0002\u0002\u00052\u0003\u0002\u0002\u0002",
    "\u00078\u0003\u0002\u0002\u0002\t;\u0003\u0002\u0002\u0002\u000bB\u0003",
    "\u0002\u0002\u0002\rJ\u0003\u0002\u0002\u0002\u000fV\u0003\u0002\u0002",
    "\u0002\u0011[\u0003\u0002\u0002\u0002\u0013d\u0003\u0002\u0002\u0002",
    "\u0015h\u0003\u0002\u0002\u0002\u0017k\u0003\u0002\u0002\u0002\u0019",
    "s\u0003\u0002\u0002\u0002\u001b\u0092\u0003\u0002\u0002\u0002\u001d",
    "\u0094\u0003\u0002\u0002\u0002\u001f\u0096\u0003\u0002\u0002\u0002!",
    "\u0099\u0003\u0002\u0002\u0002#\u009b\u0003\u0002\u0002\u0002%\u009e",
    "\u0003\u0002\u0002\u0002\'\u00a0\u0003\u0002\u0002\u0002)\u00a3\u0003",
    "\u0002\u0002\u0002+\u00a5\u0003\u0002\u0002\u0002-\u00a7\u0003\u0002",
    "\u0002\u0002/0\u00070\u0002\u00020\u0004\u0003\u0002\u0002\u000213\t",
    "\u0002\u0002\u000221\u0003\u0002\u0002\u000234\u0003\u0002\u0002\u0002",
    "42\u0003\u0002\u0002\u000245\u0003\u0002\u0002\u000256\u0003\u0002\u0002",
    "\u000267\b\u0003\u0002\u00027\u0006\u0003\u0002\u0002\u000289\u0007",
    "K\u0002\u00029:\u0007P\u0002\u0002:\b\u0003\u0002\u0002\u0002;<\u0007",
    "P\u0002\u0002<=\u0007Q\u0002\u0002=>\u0007V\u0002\u0002>?\u0007\"\u0002",
    "\u0002?@\u0007K\u0002\u0002@A\u0007P\u0002\u0002A\n\u0003\u0002\u0002",
    "\u0002BC\u0007K\u0002\u0002CD\u0007U\u0002\u0002DE\u0007\"\u0002\u0002",
    "EF\u0007P\u0002\u0002FG\u0007W\u0002\u0002GH\u0007N\u0002\u0002HI\u0007",
    "N\u0002\u0002I\f\u0003\u0002\u0002\u0002JK\u0007K\u0002\u0002KL\u0007",
    "U\u0002\u0002LM\u0007\"\u0002\u0002MN\u0007P\u0002\u0002NO\u0007Q\u0002",
    "\u0002OP\u0007V\u0002\u0002PQ\u0007\"\u0002\u0002QR\u0007P\u0002\u0002",
    "RS\u0007W\u0002\u0002ST\u0007N\u0002\u0002TU\u0007N\u0002\u0002U\u000e",
    "\u0003\u0002\u0002\u0002VW\u0007N\u0002\u0002WX\u0007K\u0002\u0002X",
    "Y\u0007M\u0002\u0002YZ\u0007G\u0002\u0002Z\u0010\u0003\u0002\u0002\u0002",
    "[\\\u0007P\u0002\u0002\\]\u0007Q\u0002\u0002]^\u0007V\u0002\u0002^_",
    "\u0007\"\u0002\u0002_`\u0007N\u0002\u0002`a\u0007K\u0002\u0002ab\u0007",
    "M\u0002\u0002bc\u0007G\u0002\u0002c\u0012\u0003\u0002\u0002\u0002de",
    "\u0007C\u0002\u0002ef\u0007P\u0002\u0002fg\u0007F\u0002\u0002g\u0014",
    "\u0003\u0002\u0002\u0002hi\u0007Q\u0002\u0002ij\u0007T\u0002\u0002j",
    "\u0016\u0003\u0002\u0002\u0002ko\t\u0003\u0002\u0002ln\t\u0004\u0002",
    "\u0002ml\u0003\u0002\u0002\u0002nq\u0003\u0002\u0002\u0002om\u0003\u0002",
    "\u0002\u0002op\u0003\u0002\u0002\u0002p\u0018\u0003\u0002\u0002\u0002",
    "qo\u0003\u0002\u0002\u0002rt\t\u0005\u0002\u0002sr\u0003\u0002\u0002",
    "\u0002st\u0003\u0002\u0002\u0002tv\u0003\u0002\u0002\u0002uw\t\u0006",
    "\u0002\u0002vu\u0003\u0002\u0002\u0002wx\u0003\u0002\u0002\u0002xv\u0003",
    "\u0002\u0002\u0002xy\u0003\u0002\u0002\u0002y\u0080\u0003\u0002\u0002",
    "\u0002z|\u00070\u0002\u0002{}\t\u0006\u0002\u0002|{\u0003\u0002\u0002",
    "\u0002}~\u0003\u0002\u0002\u0002~|\u0003\u0002\u0002\u0002~\u007f\u0003",
    "\u0002\u0002\u0002\u007f\u0081\u0003\u0002\u0002\u0002\u0080z\u0003",
    "\u0002\u0002\u0002\u0080\u0081\u0003\u0002\u0002\u0002\u0081\u001a\u0003",
    "\u0002\u0002\u0002\u0082\u0086\u0007)\u0002\u0002\u0083\u0085\n\u0007",
    "\u0002\u0002\u0084\u0083\u0003\u0002\u0002\u0002\u0085\u0088\u0003\u0002",
    "\u0002\u0002\u0086\u0084\u0003\u0002\u0002\u0002\u0086\u0087\u0003\u0002",
    "\u0002\u0002\u0087\u0089\u0003\u0002\u0002\u0002\u0088\u0086\u0003\u0002",
    "\u0002\u0002\u0089\u0093\u0007)\u0002\u0002\u008a\u008e\u0007$\u0002",
    "\u0002\u008b\u008d\n\b\u0002\u0002\u008c\u008b\u0003\u0002\u0002\u0002",
    "\u008d\u0090\u0003\u0002\u0002\u0002\u008e\u008c\u0003\u0002\u0002\u0002",
    "\u008e\u008f\u0003\u0002\u0002\u0002\u008f\u0091\u0003\u0002\u0002\u0002",
    "\u0090\u008e\u0003\u0002\u0002\u0002\u0091\u0093\u0007$\u0002\u0002",
    "\u0092\u0082\u0003\u0002\u0002\u0002\u0092\u008a\u0003\u0002\u0002\u0002",
    "\u0093\u001c\u0003\u0002\u0002\u0002\u0094\u0095\u0007?\u0002\u0002",
    "\u0095\u001e\u0003\u0002\u0002\u0002\u0096\u0097\u0007#\u0002\u0002",
    "\u0097\u0098\u0007?\u0002\u0002\u0098 \u0003\u0002\u0002\u0002\u0099",
    "\u009a\u0007>\u0002\u0002\u009a\"\u0003\u0002\u0002\u0002\u009b\u009c",
    "\u0007>\u0002\u0002\u009c\u009d\u0007?\u0002\u0002\u009d$\u0003\u0002",
    "\u0002\u0002\u009e\u009f\u0007@\u0002\u0002\u009f&\u0003\u0002\u0002",
    "\u0002\u00a0\u00a1\u0007@\u0002\u0002\u00a1\u00a2\u0007?\u0002\u0002",
    "\u00a2(\u0003\u0002\u0002\u0002\u00a3\u00a4\u0007*\u0002\u0002\u00a4",
    "*\u0003\u0002\u0002\u0002\u00a5\u00a6\u0007+\u0002\u0002\u00a6,\u0003",
    "\u0002\u0002\u0002\u00a7\u00a8\u0007.\u0002\u0002\u00a8.\u0003\u0002",
    "\u0002\u0002\f\u00024osx~\u0080\u0086\u008e\u0092\u0003\b\u0002\u0002"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

function AdvertisingRulesLexer(input) {
	antlr4.Lexer.call(this, input);
    this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.PredictionContextCache());
    return this;
}

AdvertisingRulesLexer.prototype = Object.create(antlr4.Lexer.prototype);
AdvertisingRulesLexer.prototype.constructor = AdvertisingRulesLexer;

Object.defineProperty(AdvertisingRulesLexer.prototype, "atn", {
        get : function() {
                return atn;
        }
});

AdvertisingRulesLexer.EOF = antlr4.Token.EOF;
AdvertisingRulesLexer.T__0 = 1;
AdvertisingRulesLexer.WHITESPACE = 2;
AdvertisingRulesLexer.IN = 3;
AdvertisingRulesLexer.NOT_IN = 4;
AdvertisingRulesLexer.IS_NULL = 5;
AdvertisingRulesLexer.IS_NOT_NULL = 6;
AdvertisingRulesLexer.LIKE = 7;
AdvertisingRulesLexer.NOT_LIKE = 8;
AdvertisingRulesLexer.AND = 9;
AdvertisingRulesLexer.OR = 10;
AdvertisingRulesLexer.IDENTIFIER = 11;
AdvertisingRulesLexer.NUMBER_LITERAL = 12;
AdvertisingRulesLexer.STRING_LITERAL = 13;
AdvertisingRulesLexer.EQUALS = 14;
AdvertisingRulesLexer.NOT_EQUALS = 15;
AdvertisingRulesLexer.LESS_THAN = 16;
AdvertisingRulesLexer.LESS_THAN_EQUALS = 17;
AdvertisingRulesLexer.GREATER_THAN = 18;
AdvertisingRulesLexer.GREATER_THAN_EQUALS = 19;
AdvertisingRulesLexer.OPEN_PARENTHESIS = 20;
AdvertisingRulesLexer.CLOSE_PARENTHESIS = 21;
AdvertisingRulesLexer.COMMA = 22;

AdvertisingRulesLexer.prototype.channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];

AdvertisingRulesLexer.prototype.modeNames = [ "DEFAULT_MODE" ];

AdvertisingRulesLexer.prototype.literalNames = [ null, "'.'", null, "'IN'", 
                                                 "'NOT IN'", "'IS NULL'", 
                                                 "'IS NOT NULL'", "'LIKE'", 
                                                 "'NOT LIKE'", "'AND'", 
                                                 "'OR'", null, null, null, 
                                                 "'='", "'!='", "'<'", "'<='", 
                                                 "'>'", "'>='", "'('", "')'", 
                                                 "','" ];

AdvertisingRulesLexer.prototype.symbolicNames = [ null, null, "WHITESPACE", 
                                                  "IN", "NOT_IN", "IS_NULL", 
                                                  "IS_NOT_NULL", "LIKE", 
                                                  "NOT_LIKE", "AND", "OR", 
                                                  "IDENTIFIER", "NUMBER_LITERAL", 
                                                  "STRING_LITERAL", "EQUALS", 
                                                  "NOT_EQUALS", "LESS_THAN", 
                                                  "LESS_THAN_EQUALS", "GREATER_THAN",
                                                  "GREATER_THAN_EQUALS",
                                                  "OPEN_PARENTHESIS", "CLOSE_PARENTHESIS", 
                                                  "COMMA" ];

AdvertisingRulesLexer.prototype.ruleNames = [ "T__0", "WHITESPACE", "IN", 
                                              "NOT_IN", "IS_NULL", "IS_NOT_NULL", 
                                              "LIKE", "NOT_LIKE", "AND", 
                                              "OR", "IDENTIFIER", "NUMBER_LITERAL", 
                                              "STRING_LITERAL", "EQUALS", 
                                              "NOT_EQUALS", "LESS_THAN", 
                                              "LESS_THAN_EQUALS", "GREATER_THAN",
                                              "GREATER_THAN_EQUALS", "OPEN_PARENTHESIS",
                                              "CLOSE_PARENTHESIS", "COMMA" ];

AdvertisingRulesLexer.prototype.grammarFileName = "AdvertisingRules.g4";


exports.AdvertisingRulesLexer = AdvertisingRulesLexer;

