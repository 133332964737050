import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components/macro';
import defaultCarImage from 'static/images/default_car.jpeg';
import { CarTableImageType } from './types';
import { getResizedImageUrlStorageService } from 'utils/images';

const Image = styled.img`
	width: 6.2rem;
	height: 4.7rem;
`;

export const CarImage: FunctionComponent<CarTableImageType> = ({ imagePath, namespace }) => {
	const [error, setError] = useState<boolean>(false);
	const src =
		imagePath && !error
			? getResizedImageUrlStorageService(imagePath, 62, 47, undefined, undefined, namespace)
			: defaultCarImage;

	return <Image src={src} onError={() => setError(true)} />;
};
