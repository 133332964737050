import styled from 'styled-components/macro';

const CenterCenter = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex: 1;
`;

export default CenterCenter;
