import React, { ForwardRefRenderFunction } from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';
import { OptionType } from 'types/selectOptions';

import filterOption from './FilterCars/utils/filterOption';

const { Option } = Select;

type OptionSelectPropsType = SelectProps & {
	options?: OptionType[];
};

const renderOptionSelect: ForwardRefRenderFunction<Select, OptionSelectPropsType> = (
	{
		options,
		disabled,
		placeholder,
		className,
		defaultValue,
		onChange,
		mode = 'default',
		showSearch = true,
		allowClear,
		...rest
	},
	ref,
) => (
	<Select
		ref={ref}
		showSearch={showSearch}
		filterOption={filterOption}
		disabled={disabled}
		placeholder={placeholder}
		className={className}
		defaultValue={defaultValue}
		onChange={onChange}
		mode={mode}
		allowClear={allowClear}
		{...rest}
	>
		{options?.map((option) => (
			<Option key={option.value} value={option.value}>
				{option.label}
			</Option>
		))}
	</Select>
);

export const OptionSelect = React.forwardRef(renderOptionSelect);
