import React, { useState, useCallback } from 'react';

import { Trans } from '@lingui/react';
import { Radio, notification } from 'antd';
import apiFetch from 'utils/apiFetch';
import { AdvertisedCarType } from 'types/car';
import { RadioChangeEvent } from 'antd/lib/radio';
import { theme } from 'stylesheets/theme';
import styled from 'styled-components/macro';

export const Label = styled.div`
	font-weight: 600;
	margin-bottom: 0.4rem;
`;

export const RadioWrapper = styled.div`
	margin-bottom: 1rem;
`;

interface CarFeedProps {
	advertisedCar: AdvertisedCarType;
}

const CarFeed: React.FC<CarFeedProps> = (props) => {
	const { advertisedCar } = props;

	const [inFeed, setInFeed] = useState(() => {
		if (advertisedCar.available_in_feed == null) {
			return null;
		}

		return advertisedCar.available_in_feed;
	});

	const handleOnChange = useCallback(
		async (event: RadioChangeEvent) => {
			const originalValue = inFeed;
			setInFeed(event.target.value);
			try {
				await apiFetch(`/api/carvago-admin/listedcars/${advertisedCar.id}`, {
					method: 'PATCH',
					body: JSON.stringify({ available_in_feed: event.target.value }),
					headers: {
						'content-type': 'application/json',
					},
				});
			} catch (error: unknown) {
				setInFeed(originalValue);
				if (error instanceof Error) {
					notification.error({
						message: error.message,
					});
				} else {
					throw error;
				}
			}
		},
		[advertisedCar.id, inFeed],
	);

	return (
		<>
			<RadioWrapper>
				<Label>
					<Trans id="FEED_MANUAL_LABEL" />
				</Label>
				<Radio.Group
					onChange={handleOnChange}
					defaultValue={inFeed}
					value={inFeed}
					buttonStyle="solid"
				>
					<Radio.Button
						value={true}
						style={{
							backgroundColor: inFeed === true ? theme.colors.GREEN : undefined,
						}}
					>
						<Trans id="COMMON_YES" />
					</Radio.Button>
					<Radio.Button
						value={false}
						style={{
							backgroundColor: inFeed === false ? theme.colors.ORANGE : undefined,
						}}
					>
						<Trans id="COMMON_NO" />
					</Radio.Button>
					<Radio.Button
						value={null}
						style={{ backgroundColor: inFeed === null ? theme.colors.RED : undefined }}
					>
						<Trans id="COMMON_N/A" />
					</Radio.Button>
				</Radio.Group>
			</RadioWrapper>
			<RadioWrapper>
				<Label>
					<Trans id="FEED_COMPUTED_LABEL" />
				</Label>
				<Radio.Group
					onChange={handleOnChange}
					defaultValue={advertisedCar.available_in_feed_computed}
					buttonStyle="solid"
					disabled
				>
					<Radio.Button
						value={true}
						style={{
							backgroundColor:
								advertisedCar.available_in_feed_computed === true
									? theme.colors.GREEN
									: undefined,
						}}
					>
						<Trans id="COMMON_YES" />
					</Radio.Button>
					<Radio.Button
						value={false}
						style={{
							backgroundColor:
								advertisedCar.available_in_feed_computed === false
									? theme.colors.ORANGE
									: undefined,
						}}
					>
						<Trans id="COMMON_NO" />
					</Radio.Button>
					<Radio.Button
						value={null}
						style={{
							backgroundColor:
								advertisedCar.available_in_feed_computed === null
									? theme.colors.RED
									: undefined,
						}}
					>
						<Trans id="COMMON_N/A" />
					</Radio.Button>
				</Radio.Group>
			</RadioWrapper>
		</>
	);
};

export default CarFeed;
