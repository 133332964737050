// Generated from src/AdvertisingRules.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete listener for a parse tree produced by AdvertisingRulesParser.
function AdvertisingRulesListener() {
	antlr4.tree.ParseTreeListener.call(this);
	return this;
}

AdvertisingRulesListener.prototype = Object.create(antlr4.tree.ParseTreeListener.prototype);
AdvertisingRulesListener.prototype.constructor = AdvertisingRulesListener;

// Enter a parse tree produced by AdvertisingRulesParser#field.
AdvertisingRulesListener.prototype.enterField = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#field.
AdvertisingRulesListener.prototype.exitField = function(ctx) {
};


// Enter a parse tree produced by AdvertisingRulesParser#nonEmptyNumberList.
AdvertisingRulesListener.prototype.enterNonEmptyNumberList = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#nonEmptyNumberList.
AdvertisingRulesListener.prototype.exitNonEmptyNumberList = function(ctx) {
};


// Enter a parse tree produced by AdvertisingRulesParser#nonEmptyStringList.
AdvertisingRulesListener.prototype.enterNonEmptyStringList = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#nonEmptyStringList.
AdvertisingRulesListener.prototype.exitNonEmptyStringList = function(ctx) {
};


// Enter a parse tree produced by AdvertisingRulesParser#numberComparison.
AdvertisingRulesListener.prototype.enterNumberComparison = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#numberComparison.
AdvertisingRulesListener.prototype.exitNumberComparison = function(ctx) {
};


// Enter a parse tree produced by AdvertisingRulesParser#stringComparison.
AdvertisingRulesListener.prototype.enterStringComparison = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#stringComparison.
AdvertisingRulesListener.prototype.exitStringComparison = function(ctx) {
};


// Enter a parse tree produced by AdvertisingRulesParser#nullableComparison.
AdvertisingRulesListener.prototype.enterNullableComparison = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#nullableComparison.
AdvertisingRulesListener.prototype.exitNullableComparison = function(ctx) {
};


// Enter a parse tree produced by AdvertisingRulesParser#comparison.
AdvertisingRulesListener.prototype.enterComparison = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#comparison.
AdvertisingRulesListener.prototype.exitComparison = function(ctx) {
};


// Enter a parse tree produced by AdvertisingRulesParser#expression.
AdvertisingRulesListener.prototype.enterExpression = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#expression.
AdvertisingRulesListener.prototype.exitExpression = function(ctx) {
};


// Enter a parse tree produced by AdvertisingRulesParser#start.
AdvertisingRulesListener.prototype.enterStart = function(ctx) {
};

// Exit a parse tree produced by AdvertisingRulesParser#start.
AdvertisingRulesListener.prototype.exitStart = function(ctx) {
};



exports.AdvertisingRulesListener = AdvertisingRulesListener;