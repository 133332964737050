import { MILEAGE_LIST } from 'constants/lists';

export default (type: string, value?: number) => {
	let mileageTo = 200000;
	let mileageFrom = 2500;

	if (type === 'to' && value) {
		mileageTo = value;
	} else if (type === 'from' && value) {
		mileageFrom = value;
	}
	const mileageList = MILEAGE_LIST.slice(
		MILEAGE_LIST.findIndex((item) => item.value === mileageFrom),
		MILEAGE_LIST.findIndex((item) => item.value === mileageTo) + 1,
	);

	return mileageList;
};
