import { DEFAULT_CURRENCY } from 'constants/index';

import React, { FunctionComponent } from 'react';
import Section from 'components/Section';
import getYearOptions from 'utils/getYearOptions';
import { useSelector } from 'react-redux';
import { selectReferenceData } from 'selectors/referenceData';
import { get, mapValues, omitBy } from 'lodash';
import getMileageOptions from 'utils/getMileageOptions';
import getPowerOptions from 'utils/getPowerOptions';
import { Typography, Col, Form, Select, Button, Collapse, Input, Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { FiltersType } from 'types/filters';
import { formatPrice } from 'utils/formatters';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { OptionSelect } from 'components/OptionSelect';
import { compose } from 'recompose';

import { FeatureCheckbox } from './FeatureCheckbox';
import filterOption from './utils/filterOption';
import BlackListFilter from './BlackListFilter';
import {
	CustomCollapse,
	CustomDivider,
	CustomRow,
	FormItem,
	FeaturesRow,
	FormBlock,
	FormItemsWrap,
} from 'pages/Dashboard/components/FilterDesignComponents';
import { omitWithFalse } from '../../utils/omitter';
import { useLingui } from '@lingui/react';
import { MakeAndModel } from './MakeAndModel';
import TextArea from 'antd/lib/input/TextArea';
import { OptionType } from 'types/selectOptions';
import { GROUP_PREFIX } from 'utils/activeFilters';

type LevelSelectOptionType = {
	level: number | string;
	value: string;
};

type Values = {
	[key: string]: any;
};

type FilterCarsOuterType = {
	handleSubmit: (values: any) => void;
	currency?: string;
	initialValues: FiltersType;
	onFormChange?: () => void;
};

type FilterCarsInnerType = FormComponentProps<Values> &
	FilterCarsOuterType & {
		referenceData?: any;
		i18n: any;
	};

const FilterButtons = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 20px;
	align-items: center;
`;

const AnyCheckboxWrap = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 10px;

	> .ant-checkbox-wrapper {
		width: 80px;
	}

	.ant-select {
		width: calc(100% - 80px);
	}
`;

// parse make and model into arrays
const parseCarFilterValues = (values: any) => {
	const newValues = {} as any;
	const makeModels = [] as string[];
	const makeGroupModels = [] as string[];
	const makers = [] as string[];
	const modelPercentage = [] as string[];

	for (const valueKey in values) {
		const value = values[(valueKey as unknown) as string];

		if (valueKey.startsWith('make-id-')) {
			const modelsKey = `model-family-${valueKey}`;
			const models = values[modelsKey];

			if (models && models?.length > 0) {
				const modelKeys = models.filter(
					(model: string | number) => typeof model === 'number',
				);
				const modelsGroupKeys = models
					.filter(
						(model: string | number) =>
							typeof model === 'string' && model?.startsWith(GROUP_PREFIX),
					)
					.map((model: string) => model.substring(GROUP_PREFIX.length, model.length));
				// case you have selected particular models of this make
				makeModels.push(...modelKeys);
				makeGroupModels.push(...modelsGroupKeys);
			} else {
				// case you select only make
				makers.push(value);
			}
		} else if (valueKey.startsWith('model-family-make-id-')) {
			// we skip because we solved above base on `'make-id-`
		} else if (valueKey === 'market-score-interval') {
			// ensure all values are setup
			if (
				values['market-score-interval'] &&
				values['market-score-from'] &&
				values['market-score-to']
			) {
				newValues[valueKey] = value;
			}
		} else if (valueKey.startsWith('market-score-')) {
			// ensure all values are setup
			if (
				values['market-score-interval'] &&
				values['market-score-from'] &&
				values['market-score-to']
			) {
				newValues[valueKey] = value;
			}
		} else if (valueKey.startsWith('model-percentage-id')) {
			modelPercentage.push(value);
		} else {
			newValues[valueKey] = value;
		}
	}

	if (makeModels.length > 0) {
		newValues['model-family'] = makeModels;
	}

	if (makeGroupModels.length > 0) {
		newValues['model-family-group'] = makeGroupModels;
	}

	if (makers.length > 0) {
		newValues['make'] = makers;
	}

	if (modelPercentage.length > 0) {
		newValues['model-percentage'] = modelPercentage;
	}

	return newValues;
};

const enhance = compose<FilterCarsInnerType, FilterCarsOuterType>(
	Form.create<FilterCarsInnerType>({
		name: 'filter_cars',
		onValuesChange: ({ onFormChange }) => {
			if (onFormChange) {
				onFormChange();
			}
		},
	}),
);

const RenderFilterCars: FunctionComponent<FilterCarsInnerType> = ({
	form,
	handleSubmit,
	currency,
	initialValues = {},
}) => {
	const referenceData = useSelector(selectReferenceData);
	const {
		getFieldDecorator,
		getFieldValue,
		getFieldsValue,
		validateFields,
		setFieldsValue,
		setFields,
	} = form;
	const { i18n } = useLingui();

	const yearOptions = getYearOptions();

	const handleSubmitFilter = (values: FiltersType) => {};

	const handleResetFilter = () => {
		const resetValues = mapValues(getFieldsValue(), () => undefined);
		setFieldsValue(resetValues);
		handleSubmitFilter(resetValues);
	};

	const validatePercentageRatio = (values: any) => {
		const percentageKeys = Object.keys(values).filter((key) =>
			key.startsWith('model-percentage-id-'),
		);

		if (percentageKeys.length > 0) {
			/**
			 * we have 4 cases to catch in `percentageKeys`
			 * 	1. nothing selected                            -> OK
			 *  2. something selected (not all)                -> ERROR
			 *  3. selected all but sum of all is NOT 100%     -> ERROR
			 *  4. selected all but sum of all is exactly 100% -> OK
			 */
			const percentageValues = percentageKeys.map((key) => values[key]);
			const somethingSelected = percentageValues.some(Boolean);

			for (const key of percentageKeys) {
				setFields({
					[key]: { value: values[key], errors: null },
				});
			}

			if (!somethingSelected) {
				// case 1. - OK
				return true;
			}

			const every = percentageValues.every(Boolean);

			if (!every) {
				// case 2. - ERROR because is not all selected
				const percentageError = new Error(
					i18n._(t`FILTERS_ERROR_RATIO_PERCENTAGE_NOT_ALL_SET`),
				);

				for (const key of percentageKeys) {
					if (!values[key]) {
						setFields({
							[key]: {
								value: values[key],
								errors: [percentageError],
							},
						});
					}
				}

				// stop handle submit
				return false;
			}

			const sum = percentageValues.reduce((acc, cur) => acc + Number(cur), 0);
			if (sum !== 100) {
				// case 3. - all selected but sum is not 100%
				const percentageError = new Error(
					`${i18n._(t`FILTERS_ERROR_RATIO_PERCENTAGE_NOT_MATCH`)} (${sum}%)`,
				);

				for (const key of percentageKeys) {
					setFields({
						[key]: { value: values[key], errors: [percentageError] },
					});
				}
				// stop handle submit
				return false;
			}

			// case 4. all select and sum is 100%
			return true;
		}
	};

	const validateAndSubmitValues = (err: any, values: any) => {
		if (validatePercentageRatio(values) && !err) {
			// omitBy func removes undefined values from object
			handleSubmit(parseCarFilterValues(omitBy(values, omitWithFalse)));
		}
	};

	const feedAvailabilityOptions = [
		{
			value: 'yes',
			name: <Trans id="COMMON_YES" />,
		},
		{
			value: 'no',
			name: <Trans id="COMMON_NO" />,
		},
		{
			value: 'na',
			name: 'N/A',
		},
	];

	const feedAvailabilityComputedOptions = [
		{
			value: 'yes',
			name: <Trans id="COMMON_YES" />,
		},
		{
			value: 'no',
			name: <Trans id="COMMON_NO" />,
		},
		{
			value: 'na',
			name: <Trans id="FEED_AVAILABILITY_COMPUTED_OPTION_NA_LABEL" />,
		},
	];

	return (
		<Section>
			<Typography.Title level={2}>
				<Trans>FILTERS_TITLE</Trans>
			</Typography.Title>
			<Form
				onSubmit={(event) => {
					event.preventDefault();
					validateFields(validateAndSubmitValues);
				}}
			>
				<FormBlock>
					<CustomRow gutter={75}>
						<MakeAndModel form={form} initialValues={initialValues} />
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('price-level-from', {
										initialValue: initialValues['price-level-from'],
									})(
										<Select
											showSearch
											placeholder={
												<Trans>FILTER_PRICE_FROM_PLACEHOLDER</Trans>
											}
											allowClear
											filterOption={filterOption}
										>
											{get(
												referenceData,
												`priceLevels.${currency || DEFAULT_CURRENCY}`,
												[],
											).map((option: LevelSelectOptionType) => (
												<Select.Option
													key={option.level}
													value={option.level}
												>
													{formatPrice(
														option.value,
														currency || DEFAULT_CURRENCY,
													)}
												</Select.Option>
											))}
										</Select>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('price-level-to', {
										initialValue: initialValues['price-level-to'],
									})(
										<Select
											showSearch
											placeholder={<Trans>FILTER_PRICE_TO_PLACEHOLDER</Trans>}
											allowClear
											filterOption={filterOption}
										>
											{get(
												referenceData,
												`priceLevels.${currency || DEFAULT_CURRENCY}`,
												[],
											).map((option: LevelSelectOptionType) => (
												<Select.Option
													key={option.level}
													value={option.level}
												>
													{formatPrice(
														option.value,
														currency || DEFAULT_CURRENCY,
													)}
												</Select.Option>
											))}
										</Select>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('mileage-from', {
										initialValue: initialValues['mileage-from'],
									})(
										<OptionSelect
											options={getMileageOptions('from')}
											placeholder={
												<Trans>FILTER_MILEAGE_FROM_PLACEHOLDER</Trans>
											}
											allowClear
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('mileage-to', {
										initialValue: initialValues['mileage-to'],
									})(
										<OptionSelect
											options={getMileageOptions('to')}
											placeholder={
												<Trans>FILTER_MILEAGE_TO_PLACEHOLDER</Trans>
											}
											allowClear
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
					</CustomRow>
					<CustomRow gutter={75}>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('fuel-type', {
										initialValue: initialValues['fuel-type'],
									})(
										<OptionSelect
											options={referenceData.fuelTypeOptions}
											placeholder={
												<Trans>FILTER_FUEL_TYPE_PLACEHOLDER</Trans>
											}
											mode="multiple"
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('transmission', {
										initialValue: initialValues.transmission,
									})(
										<OptionSelect
											options={referenceData.transmissionOptions}
											placeholder={
												<Trans>FILTER_TRANSMISSION_PLACEHOLDER</Trans>
											}
											mode="multiple"
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('registration-date-from', {
										initialValue: initialValues['registration-date-from'],
									})(
										<OptionSelect
											options={yearOptions}
											placeholder={
												<Trans>FILTER_YEAR_FROM_PLACEHOLDER</Trans>
											}
											allowClear
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('registration-date-to', {
										initialValue: initialValues['registration-date-to'],
									})(
										<OptionSelect
											options={yearOptions}
											placeholder={<Trans>FILTER_YEAR_T0_PLACEHOLDER</Trans>}
											allowClear
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('power-from', {
										initialValue: initialValues['power-from'],
									})(
										<OptionSelect
											options={getPowerOptions('from')}
											placeholder={
												<Trans>FILTER_POWER_FROM_PLACEHOLDER</Trans>
											}
											allowClear
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('power-to', {
										initialValue: initialValues['power-to'],
									})(
										<OptionSelect
											options={getPowerOptions('to')}
											placeholder={<Trans>FILTER_POWER_TO_PLACEHOLDER</Trans>}
											allowClear
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
					</CustomRow>
					<CustomRow gutter={75}>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('color', {
										initialValue: initialValues.color,
									})(
										<OptionSelect
											options={referenceData.colorOptions}
											placeholder={<Trans>FILTER_COLOR_PLACEHOLDER</Trans>}
											mode="multiple"
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('drive', {
										initialValue: initialValues.drive,
									})(
										<OptionSelect
											options={referenceData.driveOptions}
											placeholder={<Trans>FILTER_DRIVE_PLACEHOLDER</Trans>}
											mode="multiple"
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('cubic-capacity-from', {
										initialValue: initialValues['cubic-capacity-from'],
									})(
										<Input
											placeholder={i18n._(
												t`FILTER_CUBIC_CAPACITY_FROM_PLACEHOLDER`,
											)}
											addonAfter="ccm"
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('cubic-capacity-to', {
										initialValue: initialValues['cubic-capacity-to'],
									})(
										<Input
											placeholder={i18n._(
												t`FILTER_CUBIC_CAPACITY_TO_PLACEHOLDER`,
											)}
											addonAfter="ccm"
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('car-style', {
										initialValue: initialValues['car-style'],
									})(
										<OptionSelect
											options={referenceData.carStyleOptions}
											placeholder={
												<Trans>FILTER_CAR_STYLE_PLACEHOLDER</Trans>
											}
											mode="multiple"
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('condition', {
										initialValue: initialValues.condition,
									})(
										<OptionSelect
											options={referenceData.conditionOptions}
											placeholder={
												<Trans>FILTER_CONDITION_PLACEHOLDER</Trans>
											}
											mode="multiple"
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
					</CustomRow>
					<CustomRow gutter={75} align="middle" type="flex">
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('seller', {
										initialValue: initialValues.seller,
									})(
										<OptionSelect
											options={referenceData.sellerTypeOptions}
											placeholder={<Trans>FILTER_SELLER_PLACEHOLDER</Trans>}
											mode="multiple"
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('status', {
										initialValue: initialValues.status,
									})(
										<OptionSelect
											options={referenceData.adStatusOptions}
											placeholder={<Trans>FILTER_STATUS_PLACEHOLDER</Trans>}
											mode="multiple"
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('source', {
										initialValue: initialValues.source,
									})(
										<OptionSelect
											options={referenceData.adSourceOptions}
											placeholder={<Trans>FILTER_SOURCE_PLACEHOLDER</Trans>}
											mode="multiple"
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('country', {
										initialValue: initialValues.country,
									})(
										<OptionSelect
											options={referenceData.countryOptions}
											placeholder={<Trans>FILTER_COUNTRY_PLACEHOLDER</Trans>}
											mode="multiple"
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('hidden', {
										initialValue:
											initialValues.hidden !== undefined
												? `${initialValues.hidden}`
												: undefined,
									})(
										<Select
											showSearch
											placeholder={
												<Trans>FILTER_VISIBILITY_ON_PLATFORM</Trans>
											}
											allowClear
										>
											<Select.Option key="1" value="true">
												<Trans>FILTER_HIDDEN_ON_PLATFORM</Trans>
											</Select.Option>
											<Select.Option key="2" value="false">
												<Trans>FILTER_VISIBLE_ON_PLATFORM</Trans>
											</Select.Option>
										</Select>,
									)}
								</FormItem>

								<FormItem>
									{getFieldDecorator('vat-reclaimable', {
										valuePropName: 'checked',
										initialValue: false,
									})(
										<Checkbox>
											<Trans>FILTERS_VAT_RECLAIMABLE</Trans>
										</Checkbox>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
					</CustomRow>
					<CustomRow gutter={75}>
						<Col span={8}>
							<FormItemsWrap>
								<FormItem>
									{getFieldDecorator('installment-from', {
										initialValue: initialValues['installment-from'],
									})(
										<Input
											placeholder={i18n._(
												t`FILTER_INSTALLMENT_FROM_PLACEHOLDER`,
											)}
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('installment-to', {
										initialValue: initialValues['installment-to'],
									})(
										<Input
											placeholder={i18n._(
												t`FILTER_INSTALLMENT_TO_PLACEHOLDER`,
											)}
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
						<Col span={8}>
							<FormItemsWrap>
								{getFieldDecorator('market-score-interval', {
									initialValue: initialValues['market-score-interval'],
								})(
									<OptionSelect
										options={
											[
												{
													value: `7_days_score`,
													label: i18n._(
														t`FILTER_MARKET_SCORE_INTERVAL_7_DAY`,
													),
												},
												{
													value: `30_days_score`,
													label: i18n._(
														t`FILTER_MARKET_SCORE_INTERVAL_30_DAY`,
													),
												},
												{
													value: `60_days_score`,
													label: i18n._(
														t`FILTER_MARKET_SCORE_INTERVAL_60_DAY`,
													),
												},
											] as OptionType[]
										}
										placeholder={i18n._(
											t`FILTER_MARKET_SCORE_INTERVAL_PLACEHOLDER`,
										)}
										allowClear
									/>,
								)}

								<FormItem>
									{getFieldDecorator('market-score-from', {
										initialValue: initialValues['market-score-from'],
									})(
										<Input
											placeholder={i18n._(
												t`FILTER_MARKET_SCORE_FROM_PLACEHOLDER`,
											)}
											disabled={!getFieldValue('market-score-interval')}
										/>,
									)}
								</FormItem>
								<FormItem>
									{getFieldDecorator('market-score-to', {
										initialValue: initialValues['market-score-to'],
									})(
										<Input
											placeholder={i18n._(
												t`FILTER_MARKET_SCORE_TO_PLACEHOLDER`,
											)}
											disabled={!getFieldValue('market-score-interval')}
											defaultValue={0}
										/>,
									)}
								</FormItem>
							</FormItemsWrap>
						</Col>
					</CustomRow>
				</FormBlock>
				<CustomDivider dashed />
				<CustomCollapse bordered={false} data-testid="filter_cars_more_filters">
					<Collapse.Panel header={<Trans>FILTERS_MORE_FILTERS</Trans>} key="1">
						<FeaturesRow gutter={75} align="middle" type="flex">
							<Col span={8}>
								{getFieldDecorator('feature', {
									initialValue: initialValues.feature,
								})(
									<OptionSelect
										options={referenceData.featuresOptions}
										placeholder={<Trans>FILTER_FEATURES_PLACEHOLDER</Trans>}
										mode="multiple"
									/>,
								)}
							</Col>
							<Col span={8}>
								<FeatureCheckbox
									type="FEATURE_SERVICE_BOOK"
									referenceData={referenceData}
									form={form}
									initialValues={initialValues.feature}
								/>
							</Col>
							<Col span={8}>
								{getFieldDecorator('active-warranty', {
									valuePropName: 'checked',
									initialValue: initialValues['active-warranty'],
								})(
									<Checkbox>
										<Trans>FILTERS_ACTIVE_WARRANTY</Trans>
									</Checkbox>,
								)}
							</Col>
						</FeaturesRow>
						<CustomDivider />
						<FormBlock>
							<CustomRow gutter={75}>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('origin-country', {
												initialValue: initialValues['origin-country'],
											})(
												<OptionSelect
													options={referenceData.countryOptions}
													placeholder={
														<Trans>
															FILTER_ORIGIN_COUNTRY_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('number-of-owners-to', {
												initialValue: initialValues['number-of-owners-to'],
											})(
												<Select
													showSearch
													placeholder={
														<Trans>
															FILTER_NUMBER_OF_OWNERS_FROM_PLACEHOLDER
														</Trans>
													}
													allowClear
													filterOption={filterOption}
												>
													<Select.Option value={1}>1</Select.Option>
													<Select.Option value={2}>
														<Trans id="FILTERS_UP_TO_2" />
													</Select.Option>
													<Select.Option value={3}>
														<Trans id="FILTERS_UP_TO_3" />
													</Select.Option>
												</Select>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('interior-color', {
												initialValue: initialValues['interior-color'],
											})(
												<OptionSelect
													options={referenceData.interiorColorOptions}
													placeholder={
														<Trans>
															FILTER_INTEOR_COLOR_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('interior-material', {
												initialValue: initialValues['interior-material'],
											})(
												<OptionSelect
													options={referenceData.interiorMaterialOptions}
													placeholder={
														<Trans>
															FILTER_INTERIOR_MATERIAL_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('number-of-seats-from', {
												initialValue: initialValues['number-of-seats-from'],
											})(
												<Select
													showSearch
													placeholder={
														<Trans>
															FILTER_NUMBER_OF_SEATS_FROM_PLACEHOLDER
														</Trans>
													}
													allowClear
													filterOption={filterOption}
												>
													<Select.Option value={2}>2</Select.Option>
													<Select.Option value={4}>4</Select.Option>
													<Select.Option value={5}>5</Select.Option>
													<Select.Option value={6}>6</Select.Option>
													<Select.Option value={7}>7</Select.Option>
												</Select>,
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('door-count', {
												initialValue: initialValues['door-count'],
											})(
												<OptionSelect
													options={referenceData.doorCountOptions}
													placeholder={
														<Trans>
															FILTER_NUMBER_OF_DOORS_PLACEHOLDER
														</Trans>
													}
													allowClear
												/>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
							</CustomRow>
							<CustomRow gutter={75}>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('number-of-gears', {
												initialValue: initialValues['number-of-gears'],
											})(
												<Select
													showSearch
													placeholder={
														<Trans>
															FILTER_NUMBER_OF_GEARS_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
													filterOption={filterOption}
												>
													<Select.Option value={4}>4</Select.Option>
													<Select.Option value={6}>6</Select.Option>
													<Select.Option value={7}>7</Select.Option>
													<Select.Option value={8}>8</Select.Option>
													<Select.Option value={9}>9</Select.Option>
													<Select.Option value={10}>10</Select.Option>
												</Select>,
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('fuel-consumption-combined-to', {
												initialValue:
													initialValues['fuel-consumption-combined-to'],
											})(
												<Select
													showSearch
													placeholder={
														<Trans>
															FILTER_CONSUMPTION_COMBINED_PLACEHOLDER
														</Trans>
													}
													allowClear
													filterOption={filterOption}
												>
													<Select.Option value={5}>5</Select.Option>
													<Select.Option value={6}>6</Select.Option>
													<Select.Option value={7}>7</Select.Option>
													<Select.Option value={8}>8</Select.Option>
													<Select.Option value={9}>9</Select.Option>
													<Select.Option value={10}>10</Select.Option>
												</Select>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('emission-class', {
												initialValue: initialValues['emission-class'],
											})(
												<OptionSelect
													options={referenceData.emissionClassOptions}
													placeholder={
														<Trans>
															FILTER_EMISSION_CLASS_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('number-of-airbags-from', {
												initialValue:
													initialValues['number-of-airbags-from'],
											})(
												<Select
													showSearch
													placeholder={
														<Trans>
															FILTER_NUMBER_OF_AIRBAGS_FROM_PLACEHOLDER
														</Trans>
													}
													allowClear
													filterOption={filterOption}
												>
													<Select.Option value={1}>1</Select.Option>
													<Select.Option value={2}>2</Select.Option>
													<Select.Option value={3}>3</Select.Option>
													<Select.Option value={4}>4</Select.Option>
												</Select>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('parking-camera', {
												initialValue: initialValues['parking-camera'],
											})(
												<OptionSelect
													options={referenceData.parkingCameraOptions}
													placeholder={
														<Trans>
															FILTER_PARKING_CAMERA_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('parking-sensors', {
												initialValue: initialValues['parking-sensors'],
											})(
												<OptionSelect
													options={referenceData.parkingSensorsOptions}
													placeholder={
														<Trans>
															FILTER_PARKING_SENSORS_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
							</CustomRow>
							<CustomRow gutter={75}>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('display', {
												initialValue: initialValues.display,
											})(
												<OptionSelect
													options={referenceData.displayOptions}
													placeholder={
														<Trans>FILTER_DISPLAY_PLACEHOLDER</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('air-conditioning', {
												initialValue: initialValues['air-conditioning'],
											})(
												<OptionSelect
													options={referenceData.airConditioningOptions}
													placeholder={
														<Trans>
															FILTER_AIR_CONDITIONING_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('sunroof', {
												initialValue: initialValues.sunroof,
											})(
												<OptionSelect
													options={referenceData.sunroofOptions}
													placeholder={
														<Trans>FILTER_SUNROOF_PLACEHOLDER</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('headlights-type', {
												initialValue: initialValues['headlights-type'],
											})(
												<OptionSelect
													options={referenceData.headlightsTypeOptions}
													placeholder={
														<Trans>FILTER_HEADLIGHTS_PLACEHOLDER</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
										<FormItem>
											{getFieldDecorator('tailgate-opening', {
												initialValue: initialValues['tailgate-opening'],
											})(
												<OptionSelect
													options={referenceData.tailgateOpeningOptions}
													placeholder={
														<Trans>
															FILTER_TAILGATE_OPENING_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
							</CustomRow>
							<CustomRow gutter={75}>
								<Col span={8}>
									<FormItemsWrap>
										<FormItem>
											{getFieldDecorator('sliding-doors', {
												initialValue: initialValues['sliding-doors'],
											})(
												<OptionSelect
													options={referenceData.slidingDoorsOptions}
													placeholder={
														<Trans>
															FILTER_SLIDING_DOORS_PLACEHOLDER
														</Trans>
													}
													mode="multiple"
												/>,
											)}
										</FormItem>
									</FormItemsWrap>
								</Col>
							</CustomRow>
						</FormBlock>
						<CustomDivider />
						<FormBlock>
							<CustomRow gutter={75}>
								<Col span={8}>
									<Typography.Text strong>
										<Trans>FILTERS_CRUISE_CONTROL_LABEL</Trans>
									</Typography.Text>
									<AnyCheckboxWrap>
										{getFieldDecorator('cruise-control-any', {
											valuePropName: 'checked',
											initialValue: initialValues['cruise-control-any'],
										})(
											<Checkbox>
												<Trans>FILTERS_YES</Trans>
											</Checkbox>,
										)}
										{getFieldDecorator('cruise-control', {
											initialValue: initialValues['cruise-control'],
										})(
											<OptionSelect
												options={referenceData.cruiseControlOptions}
												placeholder={<Trans>FILTER_ANY_PLACEHOLDER</Trans>}
												mode="multiple"
												disabled={!getFieldValue('cruise-control-any')}
											/>,
										)}
									</AnyCheckboxWrap>
								</Col>
								<Col span={8}>
									<Typography.Text strong>
										<Trans>FILTERS_AUXILIARY_HEATING_LABEL</Trans>
									</Typography.Text>
									<AnyCheckboxWrap>
										{getFieldDecorator('auxiliary-heating-any', {
											valuePropName: 'checked',
											initialValue: initialValues['auxiliary-heating-any'],
										})(
											<Checkbox>
												<Trans>FILTERS_YES</Trans>
											</Checkbox>,
										)}
										{getFieldDecorator('auxiliary-heating', {
											initialValue: initialValues['auxiliary-heating'],
										})(
											<OptionSelect
												options={referenceData.auxiliaryHeatingOptions}
												placeholder={<Trans>FILTER_ANY_PLACEHOLDER</Trans>}
												mode="multiple"
												disabled={!getFieldValue('auxiliary-heating-any')}
											/>,
										)}
									</AnyCheckboxWrap>
								</Col>
								<Col span={8}>
									<Typography.Text strong>
										<Trans>FILTER_TRAILER_COUPLING_PLACEHOLDER</Trans>
									</Typography.Text>
									<AnyCheckboxWrap>
										{getFieldDecorator('trailer-coupling-any', {
											valuePropName: 'checked',
											initialValue: initialValues['trailer-coupling-any'],
										})(
											<Checkbox>
												<Trans>FILTERS_YES</Trans>
											</Checkbox>,
										)}
										{getFieldDecorator('trailer-coupling', {
											initialValue: initialValues['trailer-coupling'],
										})(
											<OptionSelect
												options={referenceData.trailerCouplingOptions}
												placeholder={<Trans>FILTER_ANY_PLACEHOLDER</Trans>}
												mode="multiple"
												disabled={!getFieldValue('trailer-coupling-any')}
												filterOption={filterOption}
											/>,
										)}
									</AnyCheckboxWrap>
								</Col>
							</CustomRow>
						</FormBlock>
					</Collapse.Panel>
				</CustomCollapse>
				<CustomCollapse
					bordered={false}
					data-testid="filter_cars_blacklist"
					defaultActiveKey={initialValues['advertising-rules-apply'] ? ['1'] : []}
				>
					<Collapse.Panel header={<Trans id="FILTERS_BLACKLIST_TITLE" />} key="1">
						<FeaturesRow>
							<BlackListFilter form={form} initialValues={initialValues} />
						</FeaturesRow>
					</Collapse.Panel>
				</CustomCollapse>
				<CustomDivider dashed />
				<CustomRow gutter={[0, 35]} align="middle" type="flex">
					<Col span={4}>
						<Form.Item label={<Trans id="FEED_AVAILABILITY_LABEL" />}>
							{getFieldDecorator('available-in-feed', {
								initialValue: initialValues['available-in-feed'],
							})(
								<Select
									showSearch
									placeholder={<Trans id="FEED_AVAILABILITY_LABEL" />}
									allowClear
									filterOption={filterOption}
								>
									{feedAvailabilityOptions.map((option) => (
										<Select.Option key={option.value} value={option.value}>
											{option.name}
										</Select.Option>
									))}
								</Select>,
							)}
						</Form.Item>
					</Col>
				</CustomRow>
				<CustomRow gutter={[0, 10]} align="middle" type="flex">
					<Col span={4}>
						<Form.Item label={<Trans id="FEED_AVAILABILITY_COMPUTED_LABEL" />}>
							{getFieldDecorator('available-in-feed-computed', {
								initialValue: initialValues['available-in-feed-computed'],
							})(
								<Select
									showSearch
									placeholder={<Trans id="FEED_AVAILABILITY_COMPUTED_LABEL" />}
									allowClear
									filterOption={filterOption}
								>
									{feedAvailabilityComputedOptions.map((option) => (
										<Select.Option key={option.value} value={option.value}>
											{option.name}
										</Select.Option>
									))}
								</Select>,
							)}
						</Form.Item>
					</Col>
				</CustomRow>
				<CustomRow gutter={15} align="top" type="flex">
					<Col span={15}>
						<Form.Item label={`Advertised car IDs (advertised-car-id)`}>
							{getFieldDecorator('advertised-car-id', {
								initialValue: initialValues['advertised-car-id'],
							})(<TextArea placeholder="56932; 41223; 93256" />)}
						</Form.Item>
					</Col>
				</CustomRow>
				<FilterButtons>
					<Button
						htmlType="submit"
						type="primary"
						size="large"
						data-testid="filter_cars_submit_button"
					>
						<Trans id="FILTER_SUBMIT" />
					</Button>
					<Button
						htmlType="button"
						type="link"
						onClick={handleResetFilter}
						data-testid="filter_cars_reset_button"
					>
						<Trans>FILTER_RESET</Trans>
					</Button>
				</FilterButtons>
			</Form>
		</Section>
	);
};

export default enhance(RenderFilterCars);
