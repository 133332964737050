import React, { FC, useState, useCallback } from 'react';
import { Switch, notification } from 'antd';
import { InformationResponse } from 'types/carAudit';
import useFetch from 'hooks/useFetch';

type CarAuditSwitchProps = {
	readonly carAuditId?: string;
	readonly isVisible?: boolean;
};

const CarAuditSwitch: FC<CarAuditSwitchProps> = ({ isVisible, carAuditId }) => {
	const [isVisibleOnPlatform, setIsVisibleOnPlatform] = useState<boolean | undefined>(isVisible);

	const { fetchData: patchCarAuditInformation } = useFetch<InformationResponse>(
		`/api/carvago-admin/caraudit/information/${carAuditId}`,
		{
			init: {
				method: 'PATCH',
			},
			lazy: true,
			indicators: [isVisibleOnPlatform],
		},
	);

	const handleClick = useCallback(async () => {
		setIsVisibleOnPlatform((isHidden) => !isHidden);
		try {
			await patchCarAuditInformation({}, { visible: !isVisibleOnPlatform });
		} catch (error) {
			setIsVisibleOnPlatform((isHidden) => !isHidden);
			notification.error({
				message: error.message,
			});
		}
	}, [isVisibleOnPlatform, patchCarAuditInformation]);

	return <Switch checked={isVisibleOnPlatform} onClick={handleClick} size="small" />;
};

export default CarAuditSwitch;
