import React, { FunctionComponent } from 'react';
import { GenericActionModal } from 'components/modals/GenericActionModal';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useLingui } from '@lingui/react';

type DetailInfoModalProps = {
	isModalOpened: boolean;
	onCancel: () => void;
	type: DetailInfoModalType;
	bodyText: string;
};

export enum DetailInfoModalType {
	DESCRIPTION = 'DESCRIPTION',
	CAR_QUERY = 'CAR_QUERY',
	USER_QUERY = 'USER_QUERY',
}

const PreFormatted = styled.span`
	white-space: pre;
`;

export const DetailInfoModal: FunctionComponent<DetailInfoModalProps> = ({
	isModalOpened,
	onCancel,
	type,
	bodyText,
}) => {
	const { i18n } = useLingui();
	const getTitle = (key: DetailInfoModalType): string => {
		switch (key) {
			case DetailInfoModalType.DESCRIPTION:
				return i18n._(t`DESCRIPTION_TABLE_TEXT_TITLE`);
			case DetailInfoModalType.CAR_QUERY:
				return i18n._(t`ADVERTISING_RULE_CAR_QUERY`);
			case DetailInfoModalType.USER_QUERY:
				return i18n._(t`ADVERTISING_RULE_USER_QUERY`);
			default:
				return '';
		}
	};

	return (
		<GenericActionModal
			titleText={getTitle(type)}
			bodyText={<PreFormatted>{bodyText}</PreFormatted>}
			visible={isModalOpened}
			onCancel={onCancel}
		/>
	);
};
