import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'antd';
import moment from 'moment';
import { formatPrice, formatThousands } from 'utils/formatters';
import { get } from 'lodash';
import { AdvertisedCarType } from 'types/car';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import ownerImage from 'static/images/seller-carvago.svg';
import { CarTableImage } from 'components/CarTableImage';
import { CarFeedField } from 'components/Field/CarFeedField';
import CarStatusVisibilityField from 'components/Field/CarStatusVisibility';
import { ColumnProps } from 'antd/lib/table';

const NoWrap = styled.div`
	white-space: nowrap;
`;

const ImageWrap = styled(Link)`
	display: inline-block;
	position: relative;
`;

const CarvagoOwnerIcon = styled.div`
	width: 11px;
	height: 11px;
	background-image: url('${ownerImage}');
	background-size: cover;
	background-position: center;
	position: absolute;
	bottom: 0;
	right: 0;
`;

export const getTableColumns = ({ isArchive }: { isArchive: boolean }) => {
	const columns: Array<ColumnProps<unknown>> = [
		{
			title: '',
			key: 'car_image',
			width: 300,
			render: (data: AdvertisedCarType) =>
				isArchive ? (
					<CarTableImage width="280px" height="220px" imagePath={data.main_image} />
				) : (
					<ImageWrap to={`/car-detail/${data.id}`} data-testid="Dashboard-car-link">
						<CarTableImage width="280px" height="220px" imagePath={data.main_image} />
						{get(data, 'seller.type.const_key') === 'SELLERTYPE_CARVAGO' && (
							<CarvagoOwnerIcon />
						)}
					</ImageWrap>
				),
		},
		{
			title: <Trans>CARS_TABLE_HEAD_TITLE</Trans>,
			key: 'car_title',
			width: 140,
			render: (data: AdvertisedCarType) =>
				isArchive ? (
					data.title
				) : (
					<Link data-testid="Dashboard_car_title" to={`/car-detail/${data.id}`}>
						{data.title}
					</Link>
				),
		},
		{
			title: <Trans>CARS_TABLE_HEAD_YEAR</Trans>,
			key: 'car_registration',
			dataIndex: 'registration_date',
			render: (text: string) => moment(text).format('YYYY'),
		},
		{
			title: <Trans>CARS_TABLE_HEAD_MILEAGE</Trans>,
			key: 'car_mileage',
			dataIndex: 'mileage',
			render: (mil: number) => <NoWrap>{formatThousands(mil, 'km')}</NoWrap>,
		},
		{
			title: <Trans>CARS_TABLE_HEAD_ENGINE</Trans>,
			key: 'car_engine',
			render: (data: AdvertisedCarType) => (
				<>
					<div>{get(data, 'transmission.name')}</div>
					<div>{get(data, 'fuel_type.name')}</div>
				</>
			),
		},
		{
			title: <Trans>CARS_TABLE_HEAD_DRIVE</Trans>,
			key: 'car_drive',
			dataIndex: 'drive.name',
		},
		{
			title: (
				<div>
					<Trans>CARS_TABLE_HEAD_PRICE</Trans>
					<div>
						<Trans>CARS_TABLE_HEAD_PRICE_WO_VAT</Trans>
					</div>
				</div>
			),
			key: 'car_price',
			render: (data: AdvertisedCarType) => (
				<span>
					<NoWrap data-testid="car_table_price" data-test-value={data.price}>
						{formatPrice(data.price, data.price_currency.name)}
					</NoWrap>
					{get(data, 'vat') && (
						<Typography.Text
							type="secondary"
							data-testid="car_table_price_without_vat"
							data-test-value={data.price_without_vat}
						>
							<NoWrap>
								{formatPrice(data.price_without_vat || 0, data.price_currency.name)}
							</NoWrap>
						</Typography.Text>
					)}
				</span>
			),
		},
		{
			title: (
				<div>
					<Trans>CARS_TABLE_HEAD_CAR_ID</Trans>
					<div>
						<Trans>CARS_TABLE_HEAD_VIN</Trans>
					</div>
				</div>
			),
			key: 'car_id',
			render: (data: AdvertisedCarType) => (
				<span>
					<div> #{get(data, 'id')}</div>
					<div> {get(data, 'vin')}</div>
				</span>
			),
		},
		{
			title: (
				<div>
					<div>
						<Trans>CARS_TABLE_HEAD_STATUS</Trans>
					</div>
					<div>
						<Trans>CARS_TABLE_HEAD_VISIBILITY</Trans>
					</div>
				</div>
			),
			key: 'car_status_visibility',
			render: CarStatusVisibilityField,
		},
	];

	const feedColumn = {
		title: <Trans>CARS_TABLE_HEAD_FEED</Trans>,
		key: 'car_feed',
		width: 180,
		render: CarFeedField,
	};

	if (isArchive === false) {
		columns.splice(2, 0, feedColumn);
		return columns;
	}

	return columns;
};
