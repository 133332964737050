import React, { FunctionComponent } from 'react';
import Section from 'components/Section';
import { AdvertisedCarType, PriceInformationType } from 'types/car';

import { CurrentSellTable } from './CurrentSellTable';

import { PriceHistoryTable } from './PriceHistoryTable';
import CarPriceTable from '../../tables/CarPriceTable';

type FinancePropsType = {
	car: AdvertisedCarType & {
		price_information: PriceInformationType;
	};
};

export const Finance: FunctionComponent<FinancePropsType> = ({ car }) => {
	return (
		<>
			<Section data-testid="Carprice_table">
				<CarPriceTable car={car} />
			</Section>
			<Section>
				<CurrentSellTable carId={car.id} />
			</Section>

			<Section data-testid="Price_history_table">
				<PriceHistoryTable car={car} />
			</Section>
		</>
	);
};
