import React from 'react';
import styled from 'styled-components/macro';
import LoginForm from 'forms/Login';
import { Trans } from '@lingui/macro';
import User from 'render-components/User';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import * as pathnames from 'pathnames';
import queryString from 'query-string';

import LoginRenderComponent from './render-components/Login';

const Wrapper = styled.div`
	display: flex;
	min-height: 100vh;
	justify-content: center;
	align-items: center;
`;

export const Content = styled.div`
	width: 345px;
`;

export const Block = styled.div`
	background: #fff;
	padding: 20px;
`;

export const ForgotPassword = styled.div`
	text-align: center;
	margin-top: 14px;
`;

const Login = ({ location }: RouteComponentProps) => (
	<User>
		{(user, { loading }) => {
			if (user !== null && !loading) {
				const queryParams = queryString.parse(location.search);
				const next = queryParams.next as string | undefined;

				return <Redirect to={next || pathnames.ROOT_PATHNAME} />;
			}

			return (
				<Wrapper>
					<Content>
						<Block>
							<LoginRenderComponent>
								{(login, { loading: loginLoading }) => (
									<LoginForm onSubmit={login} loading={loginLoading} />
								)}
							</LoginRenderComponent>
						</Block>
						<ForgotPassword>
							<a href="">
								<Trans>FORGOTT_PASSWORD</Trans>
							</a>
						</ForgotPassword>
					</Content>
				</Wrapper>
			);
		}}
	</User>
);

export default Login;
