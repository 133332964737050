import React, { useEffect, FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { useDispatch } from 'react-redux';
import CenterCenter from 'components/CenterCenter';
import { Spin } from 'antd';
import useFetch from 'hooks/useFetch';

const MyCenterCenter = styled(CenterCenter)`
	padding: 10px;
`;

export const ReferenceDataProvider: FunctionComponent = ({ children }) => {
	const dispatch = useDispatch();

	const { data: summaryData, isLoading: isDataLoading } = useFetch(
		'/api/carvago-admin/reference-data/summary',
	);
	const { data: sourcesData, isLoading: isSourcesLoading } = useFetch(
		'/api/carvago-admin/listedcars/sources',
	);

	useEffect(() => {
		if (summaryData && sourcesData) {
			dispatch.referenceData.setReferenceData({
				...summaryData,
				sources: sourcesData,
			});
		}
	}, [summaryData, sourcesData]);

	if (isDataLoading || isSourcesLoading) {
		return (
			<MyCenterCenter>
				<Spin size="large" />
			</MyCenterCenter>
		);
	}

	return <>{children}</>;
};
