import { DEFAULT_CURRENCY } from 'constants/index';

import React from 'react';
import styled from 'styled-components/macro';
import { PriceHistory } from 'types/priceHistory';
import { Popover, Spin, Typography, Divider } from 'antd';
import { Trans } from '@lingui/macro';
import { ModelType } from 'hooks/useFetch';
import CenterCenter from 'components/CenterCenter';
import { convert, getExchangeRate } from 'utils/exchangeRatesConversion';
import { ExchangeRate } from 'types/exchangeRate';
import { round, get } from 'lodash';
import { formatPrice } from 'utils/formatters';
import moment from 'moment';

type OriginalCurrencyPricePopupPropsType = {
	row: PriceHistory;
	children: React.ReactNode;
	exchangeRatesModel: ModelType<ExchangeRate[]>;
	dealerHistory?: boolean;
};

const Container = styled.div`
	min-width: 300px;
`;

const Heading = styled.div`
	font-weight: bold;
	color: ${({ theme }) => theme.colors.PRIMARY};
	font-size: 0.8rem;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const Cell = styled.div<{ alignRight?: boolean }>`
	flex: 1 0 33%;
	padding: ${({ alignRight }) => (!alignRight ? '0 10px 0 0' : '0 0 0 10px')};

	font-size: 0.8rem;
	text-align: ${({ alignRight }) => (alignRight ? 'right' : 'left')};
`;

const Trigger = styled.span`
	border-bottom: dashed 1px rgba(0, 0, 0, 0.45);
	cursor: help;
`;

const calculateRate = (originalPrice: number, listPrice: number | null) => {
	if (listPrice) {
		return round(listPrice / originalPrice, 2);
	}
	return null;
};

const calculateData = (row: PriceHistory, rates: ExchangeRate[], dealerHistory?: boolean) => {
	const calculatedPrice = dealerHistory
		? get(row, 'list_price', null)
		: get(row, 'original_price_exchanged', null);
	const calculatedCurrency = dealerHistory ? row.currency_name : get(row, 'price_currency.name');
	const currencyName = get(
		row,
		'original_currency_data.name',
		get(row, 'original_price_currency.name'),
	);

	return {
		current: {
			price: {
				original: {
					price: row.original_price,
					currency: currencyName,
				},
				calculated: {
					price: convert(row.original_price, currencyName, DEFAULT_CURRENCY, rates),
					currency: DEFAULT_CURRENCY,
				},
			},
			rate: {
				original: {
					price: 1,
					currency: currencyName,
				},
				calculated: {
					price: getExchangeRate(currencyName, DEFAULT_CURRENCY, rates),
					currency: DEFAULT_CURRENCY,
				},
			},
		},
		fixed: {
			price: {
				original: {
					price: row.original_price,
					currency: currencyName,
				},
				calculated: {
					price: calculatedPrice,
					currency: calculatedCurrency,
				},
			},
			rate: {
				original: {
					price: 1,
					currency: currencyName,
				},
				calculated: {
					price: calculateRate(row.original_price, calculatedPrice),
					currency: DEFAULT_CURRENCY,
				},
			},
		},
	};
};

const OriginalCurrencyPricePopup = ({
	children,
	row,
	exchangeRatesModel,
	dealerHistory,
}: OriginalCurrencyPricePopupPropsType) => {
	if (!exchangeRatesModel.data || exchangeRatesModel.isLoading) {
		return (
			<CenterCenter>
				<Spin />
			</CenterCenter>
		);
	}

	const calculatedData = calculateData(row, exchangeRatesModel.data, dealerHistory);

	if (
		get(row, 'original_currency_data.name', get(row, 'original_price_currency.name')) ===
		DEFAULT_CURRENCY
	) {
		return <>{children}</>;
	}

	return (
		<Popover
			content={
				<Container data-testid="Dealer_price_history_original_price_popup">
					<Heading>
						<Trans>ORIGINAL_PRICE_CURRENCY_NOW</Trans>
					</Heading>
					<Row>
						<Cell>
							<Trans>ORIGINAL_PRICE_CURRENCY_PRICE</Trans>
						</Cell>
						<Cell alignRight>
							{formatPrice(
								calculatedData.current.price.original.price,
								calculatedData.current.price.original.currency,
							)}
						</Cell>
						<Cell alignRight>
							{formatPrice(
								calculatedData.current.price.calculated.price,
								calculatedData.current.price.calculated.currency,
							)}
						</Cell>
					</Row>
					<Row>
						<Cell>
							<Typography.Text type="secondary">
								<Trans>ORIGINAL_PRICE_CURRENCY_RATE</Trans>
							</Typography.Text>
						</Cell>
						<Cell alignRight>
							<Typography.Text type="secondary">
								{formatPrice(
									calculatedData.current.rate.original.price,
									calculatedData.current.rate.original.currency,
								)}
							</Typography.Text>
						</Cell>
						<Cell alignRight>
							<Typography.Text type="secondary">
								{formatPrice(
									calculatedData.current.rate.calculated.price,
									calculatedData.current.rate.calculated.currency,
								)}
							</Typography.Text>
						</Cell>
					</Row>
					<Divider />
					<Heading>
						<Trans>
							ORIGINAL_PRICE_CURRENCY_THEN{moment(row.created_at).format('DD.MM.YYYY, HH:mm')}
						</Trans>
					</Heading>
					<Row>
						<Cell>
							<Trans>ORIGINAL_PRICE_CURRENCY_PRICE</Trans>
						</Cell>
						<Cell alignRight>
							{formatPrice(
								calculatedData.fixed.price.original.price,
								calculatedData.fixed.price.original.currency,
							)}
						</Cell>
						<Cell alignRight>
							{formatPrice(
								calculatedData.fixed.price.calculated.price,
								calculatedData.fixed.price.calculated.currency,
							)}
						</Cell>
					</Row>
					<Row>
						<Cell>
							<Typography.Text type="secondary">
								<Trans>ORIGINAL_PRICE_CURRENCY_RATE</Trans>
							</Typography.Text>
						</Cell>
						<Cell alignRight>
							<Typography.Text type="secondary">
								{formatPrice(
									calculatedData.fixed.rate.original.price,
									calculatedData.fixed.rate.original.currency,
								)}
							</Typography.Text>
						</Cell>
						<Cell alignRight>
							<Typography.Text type="secondary">
								{formatPrice(
									calculatedData.fixed.rate.calculated.price,
									calculatedData.fixed.rate.calculated.currency,
								)}
							</Typography.Text>
						</Cell>
					</Row>
				</Container>
			}
		>
			<Trigger>{children}</Trigger>
		</Popover>
	);
};

export default OriginalCurrencyPricePopup;
