import { CurrencyType } from './currency';
import { AdvertisedCarType } from './car';
import { DeliveryType } from './delivery';
import { Address } from './address';
import { WarrantyTypeDefend, WarrantyTypeGuard } from './warranty';
import { OrderAdditionalServiceType } from 'types/service';

export type OrderUser = {
	id: number;
	email: string;
	first_name?: string;
	last_name?: string;
	phone_country_code?: string;
	phone_number?: string;
	account?: {
		id: number;
		salesforce_id?: number;
	};
	dealer: boolean;
};

export type OrderPaymentOption = {
	id: number;
	const_key: string;
	name: string;
	description: string;
	order: number;
	payment_type: string;
};

export type OrderProduct = {
	id: number;
	name: string;
	description: string;
	order: number;
	items: unknown;
	features: unknown;
};

export type OrderAddress = Address & {
	id: number;
};

export type OrderType = {
	advertised_car: AdvertisedCarType;
	advertised_car_price: number;
	advertised_car_price_currency: CurrencyType;
	advertised_car_price_without_vat: number;
	advertised_car_vat_reclaimable: boolean;
	bindingly_ordered: boolean;
	car_audit_information: null | any;
	car_delivery_date: null | string;
	company_address?: OrderAddress;
	currency: CurrencyType;
	financing: null;
	financing_required: boolean;
	id: number;
	invoice_address?: OrderAddress;
	is_car_audit_paid: boolean;
	order_additional_services: OrderAdditionalServiceType[];
	order_documents: any[];
	order_photos: any[];
	payment_option?: OrderPaymentOption;
	product?: OrderProduct;
	product_name: string;
	product_price: number;
	product_price_currency: CurrencyType;
	products: OrderProduct[];
	promo_code: PromoCode;
	reserved: boolean;
	selected_delivery?: DeliveryType | null;
	selected_warranty: WarrantyTypeGuard;
	status: OrderStatusPlatform;
	status_updated_at: string;
	sub_status: OrderSubStatusPlatform | null;
	user: OrderUser;
	warranty: WarrantyTypeDefend;
};

export enum OrderStatusPlatform {
    // CarAudit
	CAR_AUDIT_REQUESTED = 'car-audit-requested',
	CAR_AUDIT_PAID = 'car-audit-paid',
	CAR_AUDIT_IN_PROGRESS = 'car-audit-in-progress',
	CAR_AUDIT_DELIVERED = 'car-audit-delivered',
	// user decides whether they buy the car
	ORDER_ACCEPTED = 'order-accepted',
	LOST = 'lost',
	// car contract and payment for the car
	CONTRACT_WAITING = 'contract-waiting',
	CONTRACT_ACCEPTED = 'contract-accepted',
	CONTRACT_PAID = 'contract-paid',
	IMPORT = 'import',
	SERVICE = 'service',
	DELIVERY = 'delivery',
	// final statutes
	COMPLETED = 'completed',
	CANCELED_WITH_ALTERNATIVE = 'canceled-with-alternative',
}

export enum OrderSubStatusPlatform {
	// user decides whether they buy the car, sub status for LOST
	CUSTOMER_LOST = 'customer-lost',

	// order tracking IMPORT sub statuses
	PURCHASING = 'purchase',
	NEGOTIATING_PICKUP_DATE = 'negotiating-pickup-date',
	PICKUP = 'pickup',
	CAR_ON_TRANSFER_POINT = 'car-on-transfer-point',
	CAR_IN_LOGISTIC_CENTER = 'car-in-logistic-center',

	// order tracking SERVICE sub statuses
	SERVICE_CHECK = 'service-check',
	ADMINISTRATION = 'administration',
	TECHNICAL_INSPECTION = 'technical-inspection',
	REGISTRATION = 'registration',
	CAR_PREPARATION = 'car-preparation',

	// order tracking DELIVERY sub statuses
	NEGOTIATING_DELIVERY_DATE = 'negotiating-delivery-date',

	// order tracking COMPLETED sub status
	DELIVERED = 'delivered',
}

// customer accept order after received car audit
export const ORDER_ACCEPTED = 6;

// orderSteps should correspond steps in platform.
export const orderSteps: Record<string, number> = {
	[OrderStatusPlatform.CAR_AUDIT_REQUESTED]: 2,
	[OrderStatusPlatform.CAR_AUDIT_PAID]: 3,
	[OrderStatusPlatform.CAR_AUDIT_IN_PROGRESS]: 4,
	[OrderStatusPlatform.CAR_AUDIT_DELIVERED]: 5,
	[OrderStatusPlatform.ORDER_ACCEPTED]: 6,
	[OrderStatusPlatform.LOST && OrderSubStatusPlatform.CUSTOMER_LOST]: 6,
	[OrderStatusPlatform.CONTRACT_WAITING]: 6.5,
	[OrderStatusPlatform.CONTRACT_ACCEPTED]: 7,
	[OrderStatusPlatform.CONTRACT_PAID]: 8,
	[OrderStatusPlatform.IMPORT]: 9,
	[OrderStatusPlatform.SERVICE]: 10,
	[OrderStatusPlatform.DELIVERY]: 11,
	[OrderStatusPlatform.DELIVERY && OrderSubStatusPlatform.NEGOTIATING_DELIVERY_DATE]: 11.5,
	[OrderStatusPlatform.COMPLETED && OrderSubStatusPlatform.DELIVERED]: 12,
	[OrderStatusPlatform.CANCELED_WITH_ALTERNATIVE]: 12,
	[OrderStatusPlatform.LOST]: 12,
};

export type PromoCode = {
	amount: number;
	code: string;
};

export enum OrderFinancingStatus {
	PENDING = 'financing-pending',
	PENDING_OLD = 'pending',
	APPROVED = 'financing-approved',
	APPROVED_OLD = 'approved',
	REJECTED = 'financing-rejected',
	REJECTED_OLD = 'rejected',
	REJECTED_BY_CUSTOMER = 'financing-customer-rejected',
	REJECTED_BY_CUSTOMER_OLD = 'customer-rejected',
}


