export class FetchError extends Error {
		message: string;

		statusCode?: number;

		constructor(message = 'Fetch error', statusCode?: number) {
			super(message);
			// https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-2.html#support-for-newtarget
			// eslint-disable-next-line no-proto
			Object.setPrototypeOf(this, new.target.prototype); // restore prototype chain
			this.message = message;
			this.statusCode = statusCode;
		}

		setMessage (message: string) {
			this.message = `Fetch error: ${message}`;
		}
}

export class UnauthenticatedError extends FetchError {
	constructor() {
		super('User not authenticated.');
	}
}

export const throwFetchError = async (res: Response) => {
	const error = new FetchError('Fetch error', res.status);
	if (res.headers.get('content-type') === 'application/json') {
		try {
			const response = await res.json();
			if (response?.message) {
				error.setMessage(response.message);
			}
		} catch (err) {
			error.setMessage('Unable to parse response body.');
		}
	}
	throw error;
};
