import React from 'react';
import { AdvertisedCarType } from 'types/car';
import CarVisibility from 'components/CarVisibility';
import CarStatus from 'components/CarStatus';

const CarStatusVisibilityField: React.FC<AdvertisedCarType> = ({
	hidden,
	error_reason,
	status,
	status_name,
}) => {
	return (
		<>
			<div>
				{!status ? (
					<>-</>
				) : (
					<CarStatus
						status={status}
						label={status_name ?? ''}
						errorLabel={error_reason}
					/>
				)}
			</div>
			<div>
				<CarVisibility hidden={Boolean(hidden)} error={Boolean(error_reason)} />
			</div>
		</>
	);
};

export default CarStatusVisibilityField;
