import { Menu } from 'antd';
import { t } from '@lingui/macro';
import React, { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { SUPPORTED_LOCALES } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from '../../models/locale/selectors';
import { Dispatch } from '../../store';
import { LocaleType } from 'constants/index';

const Item = styled.a<{ active: boolean }>`
	cursor: pointer;
	color: rgba(255, 255, 255, 0.65);

	${({ active }) => active && css`
		color: white;
		font-weight: bold;
	`}
`;

const LocaleIcon = styled.i`
	font-size: 14px !important;
	bottom: 2px;
	position: relative;
`;

const getLocaleLabel = (locale: LocaleType) => {
	switch (locale) {
		case 'cs':
			return t`LANGUAGE_CZECH`;
		case 'en':
			return t`LANGUAGE_ENGLISH`;
	}
};

const LanguageSelector: FC = (menuProps) => {
	const currentLocale = useSelector(selectLocale);
	const dispatch: Dispatch = useDispatch();

	const handleChangeLanguage = (locale: LocaleType) => {
		if (currentLocale === locale) {
			return;
		}
		dispatch.locale.setLocaleEffect(locale);
		window.location.reload();
	};

	return (
		<>
			{SUPPORTED_LOCALES.map((locale) => (
				<Menu.Item
					key={locale}
					data-testid={`Menu_item_${locale}`}
					// Props injected from antd Menu component
					{...menuProps}
				>
					<Item
						active={currentLocale === locale}
						onClick={() => handleChangeLanguage(locale)}
					>
						<LocaleIcon className="anticon">{locale.toUpperCase()}</LocaleIcon>
						<span>{getLocaleLabel(locale)}</span>
					</Item>
				</Menu.Item>
			))}
		</>
	);
};

export default LanguageSelector;

