import { i18n } from '@lingui/core';
import { en, cs } from 'make-plural/plurals';
import { LocaleType } from 'constants/index';
import { CompiledMessage } from '@lingui/core/cjs/i18n';

const localeImports = {
	cs: () => import('./cs/messages.js'),
	en: () => import('./en/messages.js'),
};

export const loadLocale = async (locale: LocaleType): Promise<Record<string, CompiledMessage>> => {
	const { messages } = await localeImports[locale]();
	return messages;
};

export const activateLocale = (locale: LocaleType, messages: Record<string, CompiledMessage>): void => {
	i18n.loadLocaleData({
		en: { plurals: en },
		cs: { plurals: cs },
	});
	i18n.load(locale, messages);
	i18n.activate(locale);
};
