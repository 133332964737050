import React, { FormEvent, FunctionComponent } from 'react';
import {
	CustomCollapse,
	CustomDivider,
	CustomRow,
	FeaturesRow,
	FormBlock,
	FormItemsWrap,
} from 'pages/Dashboard/components/FilterDesignComponents';
import { Col, Input, Select, Typography, Form, Button, Collapse } from 'antd';
import { Trans } from '@lingui/macro';
import filterOption from 'components/FilterCars/utils/filterOption';
import { useSelector } from 'react-redux';
import { selectReferenceData } from 'selectors/referenceData';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { LanguageType } from 'types/language';
import { OptionType } from 'types/selectOptions';
import { compose } from 'recompose';
import Section from 'components/Section';
import styled from 'styled-components';
import { FeedType } from 'types/feed';
import { isEmpty, omitBy } from 'lodash';
import { I18n } from '@lingui/core';
import { omitter } from '../../../utils/omitter';
import TextArea from 'antd/lib/input/TextArea';

type FeedFormType = {
	readonly initialValues: FeedType;
	readonly form: WrappedFormUtils;
	readonly onSubmit: (values: FeedType) => void;
};

type FeedFormPropsBase = {
	readonly referenceData?: any;
	readonly i18n?: I18n;
	readonly onFeedGenerate: CallableFunction;
	readonly googleFeedUrl: string;
	readonly fbFeedUrl: string;
	readonly onFormChange: () => void;
	readonly initialValues: FeedType;
};

type FinancingFormProps = FeedFormType & FeedFormPropsBase;

const CopyContainer = styled.div`
	margin-top: 22px;
`;

const enhance = compose<FinancingFormProps, FeedFormPropsBase>(
	Form.create<FinancingFormProps>({
		name: 'generate-feed',
		onValuesChange: (props) => props.onFormChange(),
	}),
);

const SORT_OPTIONS = [
	{ value: 'price_asc', name: <Trans id="FILTERS_FEED_SORT_PRICE_ASC" /> },
	{ value: 'price_desc', name: <Trans id="FILTERS_FEED_SORT_PRICE_DESC" /> },
	{ value: 'recommended', name: <Trans id="FILTERS_FEED_SORT_RECOMMENDED" /> },
	{ value: 'mileage', name: <Trans id="FILTERS_FEED_SORT_MILEAGE" /> },
	{ value: 'discount', name: <Trans id="FILTERS_FEED_SORT_DISCOUNT" /> },
	{ value: 'days-on-display', name: <Trans id="FILTERS_FEED_SORT_DAYS_ON_DISPLAY" /> },
];

const FeedForm: FunctionComponent<FinancingFormProps> = ({
	form,
	initialValues = {},
	onFeedGenerate,
	googleFeedUrl,
	fbFeedUrl,
}) => {
	const referenceData = useSelector(selectReferenceData);
	const { getFieldDecorator, validateFields } = form;

	const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		validateFields((err: any, values: FeedType) => {
			if (!err) {
				onFeedGenerate(omitBy(values, omitter));
			}
		});
	};

	return (
		<Section>
			<Form onSubmit={handleSubmit}>
				<CustomCollapse
					bordered={false}
					data-testid="generate_feed"
					defaultActiveKey={!isEmpty(initialValues) ? ['1'] : []}
				>
					<Collapse.Panel header={<Trans id="FILTERS_FEED_TITLE" />} key="1">
						<FeaturesRow>
							<FormBlock>
								<CustomRow gutter={15} align="top" type="flex">
									<Col span={4}>
										<Form.Item label={<Trans id="FEED_SORT_LABEL" />}>
											{getFieldDecorator('sort')(
												<Select
													showSearch
													placeholder={<Trans id="FEED_SORT_LABEL" />}
													allowClear
													filterOption={filterOption}
												>
													{SORT_OPTIONS.map((option) => (
														<Select.Option
															key={option.value}
															value={option.value}
														>
															{option.name}
														</Select.Option>
													))}
												</Select>,
											)}
										</Form.Item>
									</Col>
								</CustomRow>
								<CustomRow gutter={15} align="top" type="flex">
									<Col span={4}>
										<Form.Item label={<Trans id="FEED_LANG_LABEL" />}>
											{getFieldDecorator('locale', {
												initialValue: initialValues['locale'],
											})(
												<Select
													showSearch
													placeholder={<Trans id="FEED_LANG_LABEL" />}
													allowClear
													filterOption={filterOption}
												>
													{referenceData.languageOptions.map(
														(option: LanguageType) => (
															<Select.Option
																key={option.code}
																value={option.code}
															>
																{option.name}
															</Select.Option>
														),
													)}
												</Select>,
											)}
										</Form.Item>
									</Col>
									<Col span={4}>
										<Form.Item label={<Trans id="FEED_CURRENCY_LABEL" />}>
											{getFieldDecorator('currency', {
												initialValue: initialValues['currency'],
											})(
												<Select
													showSearch
													placeholder={<Trans id="FEED_CURRENCY_LABEL" />}
													allowClear
													filterOption={filterOption}
												>
													{referenceData.currencyOptions.map(
														(option: OptionType) => (
															<Select.Option
																key={option.value}
																value={option.label}
															>
																{option.label}
															</Select.Option>
														),
													)}
												</Select>,
											)}
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item label={'utm_source'}>
											{getFieldDecorator('utm_source', {
												initialValue: initialValues['utm_source'],
											})(
												<Input
													type="text"
													placeholder="utm_source"
													size="large"
												/>,
											)}
										</Form.Item>
										<Form.Item label={'utm_medium'}>
											{getFieldDecorator('utm_medium', {
												initialValue: initialValues['utm_medium'],
											})(
												<Input
													type="text"
													placeholder="utm_medium"
													size="large"
												/>,
											)}
										</Form.Item>
									</Col>
									<Col span={5}>
										<Form.Item label={'utm_campaign'}>
											{getFieldDecorator('utm_campaign', {
												initialValue: initialValues['utm_campaign'],
											})(
												<Input
													type="text"
													placeholder="utm_campaign"
													size="large"
												/>,
											)}
										</Form.Item>
										<Form.Item label={'utm_content'}>
											{getFieldDecorator('utm_content', {
												initialValue: initialValues['utm_content'],
											})(
												<Input
													type="text"
													placeholder="utm_content"
													size="large"
												/>,
											)}
										</Form.Item>
									</Col>
									<Col span={6}>
										<FormItemsWrap>
											<Form.Item label={'Limit'}>
												{getFieldDecorator('limit', {
													initialValue: initialValues['limit'],
												})(
													<Input
														type="number"
														placeholder="limit"
														size="large"
													/>,
												)}
											</Form.Item>
										</FormItemsWrap>
									</Col>
								</CustomRow>
							</FormBlock>
							<CustomDivider />
							<FormBlock>
								<CustomRow gutter={[0, 35]} align="middle" type="flex">
									<Col span={8}>
										<Form.Item label={'Google Feed'}>
											<Input
												type="text"
												value={googleFeedUrl}
												placeholder="Google"
												size="large"
												disabled
											/>
										</Form.Item>
									</Col>
									<Col span={2}>
										<CopyContainer>
											<Typography.Paragraph
												copyable={{
													text: googleFeedUrl,
												}}
											/>
										</CopyContainer>
									</Col>
									<Col span={8}>
										<Form.Item label={'Facebook Feed'}>
											<Input
												type="text"
												value={fbFeedUrl}
												placeholder="Facebook"
												size="large"
												disabled
											/>
										</Form.Item>
									</Col>
									<Col span={2}>
										<CopyContainer>
											<Typography.Paragraph
												copyable={{
													text: fbFeedUrl,
												}}
											/>
										</CopyContainer>
									</Col>
								</CustomRow>
							</FormBlock>
							<Button
								htmlType="submit"
								type="primary"
								size="large"
								data-testid="filter_cars_submit_button"
							>
								<Trans id="FILTER_FEED_SUBMIT" />
							</Button>
						</FeaturesRow>
					</Collapse.Panel>
				</CustomCollapse>
			</Form>
		</Section>
	);
};

export default enhance(FeedForm);
