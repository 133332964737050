import React, { useState, FunctionComponent, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import Layout from 'components/PrivateLayout';
import { Typography, Divider, Button, Table, notification } from 'antd';
import { Trans, t } from '@lingui/macro';
import Section from 'components/Section';
import { TableSearchHeader } from 'components/TableSearchHeader';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import useFetch from 'hooks/useFetch';
import apiFetch from 'utils/apiFetch';

import { CancelConfirmModal } from './components/DeleteConfirmModal';
import { DetailInfoModal, DetailInfoModalType } from './components/DetailInfoModal';
import { getTableColumns } from './components/FilterTableColumns';
import { useLingui } from '@lingui/react';

const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

const StyledText = styled(Typography.Text)`
	margin-bottom: 0.5em;
	margin-left: 0.5em;
`;

const TableSection = styled(Section)`
	margin-top: 30px;
`;

const CustomTable = styled(Table)`
	.ant-table-row td {
		padding: 5px 10px;
		font-size: 12px;
	}
`;

const PAGE_SIZE = 10;

type AdvertisingRulesType = RouteComponentProps;

const AdvertisingRules: FunctionComponent<AdvertisingRulesType> = ({ history }) => {
	const [collapsed, setCollapsed] = useState(true);
	const [currentPage, setCurrentPage] = useState(1);
	const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
	const [isDetailInfoModalVisible, setIsDetailInfoModalVisible] = useState(false);
	const [detailInfoModalBodyText, setDetailInfoModalBodyText] = useState('');
	const [detailInfoModalType, setDetailInfoModalType] = useState(DetailInfoModalType.DESCRIPTION);
	const [idToBeDeleted, setIdToBeDeleted] = useState<string>();
	const [searchString, setSearchString] = useState<string>('');
	const { i18n } = useLingui();

	const deleteFilterAfterConfirmation = (id: string) => {
		setIdToBeDeleted(id);
		setIsConfirmDeleteModalVisible(true);
	};

	const { data: filterReferenceData, isLoading, fetchData, totalCount, error } = useFetch(
		`/api/carvago-admin/advertising-rule?limit=${PAGE_SIZE}&page=${currentPage}`,
		{
			lazy: false,
		},
	);

	const filteredData = useMemo(() => {
		if (!searchString) return filterReferenceData;

		return filterReferenceData.filter((item: any) => String(item.name).toLowerCase().includes(searchString.toLowerCase()));
	}, [filterReferenceData, searchString]);

	useEffect(() => {
		if (error) {
			notification.error({
				message: <Trans>ORDER_STATUS_ERROR</Trans>,
			});
			throw error;
		}
	}, [error]);

	const onClick = (bodyText: string, type: DetailInfoModalType) => {
		setDetailInfoModalBodyText(bodyText);
		setDetailInfoModalType(type);
		setIsDetailInfoModalVisible(true);
	};

	const onCancel = () => {
		setIsDetailInfoModalVisible(false);
	};

	const errorMessage = i18n._(t`ORDER_STATUS_ERROR`);

	return (
		<Layout collapsed={collapsed} setCollapsed={setCollapsed}>
			<TableSection>
				<Header>
					<TitleContainer>
						<Typography.Title level={2}>
							<Trans>ADVERTISING_RULE_PAGE_TITLE</Trans>
						</Typography.Title>
						<StyledText>
							<Trans>ADVERTISING_RULE_PAGE_SUBTITLE</Trans>
						</StyledText>
					</TitleContainer>

					<Link to="/advertising-rule-edit" data-testid="Advertising-rules-newrule-button">
						<Button htmlType="submit" type="primary" size="large">
							<Trans>ADVERTISING_RULE_PAGE_NEW_FILTER_BUTTON</Trans>
						</Button>
					</Link>
				</Header>

				<Divider dashed />

				<TableSearchHeader
					placeholderTranslationKey="ADVERTISING_RULE_TABLE_FILTER_BY_NAME"
					handleSearch={setSearchString}
					dataTestId="search_advertising_rules_input"
				/>

				<CustomTable
					data-testid="Advertising-rules-table"
					columns={getTableColumns({
						fetchData,
						history,
						deleteFilterAfterConfirmation,
						onClick,
						errorMessage,
					})}
					dataSource={filteredData}
					scroll={{ x: 1080 }}
					onRow={(_, index) => ({ 'data-testid': `Advertising_rules_table_row_${index}` })}
					loading={isLoading}
					rowKey={({ id }: any) => id}
					pagination={
						totalCount >= PAGE_SIZE && {
							position: 'both',
							total: totalCount,
							pageSize: PAGE_SIZE,
							onChange: setCurrentPage,
							size: 'middle',
						}
					}
					size="middle"
				/>
			</TableSection>
			<CancelConfirmModal
				isModalOpened={isConfirmDeleteModalVisible}
				onCancel={() => {
					setIsConfirmDeleteModalVisible(false);
					setIdToBeDeleted(undefined);
				}}
				onConfirm={() => {
					setIsConfirmDeleteModalVisible(false);
					apiFetch(`/api/carvago-admin/advertising-rule/${idToBeDeleted}`, {
						method: 'DELETE',
					})
						.then(() => {
							fetchData();
						})
						.catch(() => {
							notification.error({
								message: errorMessage,
							});
						})
						.finally(() => {
							setIdToBeDeleted(undefined);
						});
				}}
			/>
			<DetailInfoModal
				isModalOpened={isDetailInfoModalVisible}
				onCancel={onCancel}
				type={detailInfoModalType}
				bodyText={detailInfoModalBodyText}
			/>
		</Layout>
	);
};

export default withRouter(AdvertisingRules);
