// Generated from src/AdvertisingRules.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');

// This class defines a complete generic visitor for a parse tree produced by AdvertisingRulesParser.

function AdvertisingRulesVisitor() {
	antlr4.tree.ParseTreeVisitor.call(this);
	return this;
}

AdvertisingRulesVisitor.prototype = Object.create(antlr4.tree.ParseTreeVisitor.prototype);
AdvertisingRulesVisitor.prototype.constructor = AdvertisingRulesVisitor;

// Visit a parse tree produced by AdvertisingRulesParser#field.
AdvertisingRulesVisitor.prototype.visitField = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by AdvertisingRulesParser#nonEmptyNumberList.
AdvertisingRulesVisitor.prototype.visitNonEmptyNumberList = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by AdvertisingRulesParser#nonEmptyStringList.
AdvertisingRulesVisitor.prototype.visitNonEmptyStringList = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by AdvertisingRulesParser#numberComparison.
AdvertisingRulesVisitor.prototype.visitNumberComparison = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by AdvertisingRulesParser#stringComparison.
AdvertisingRulesVisitor.prototype.visitStringComparison = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by AdvertisingRulesParser#nullableComparison.
AdvertisingRulesVisitor.prototype.visitNullableComparison = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by AdvertisingRulesParser#comparison.
AdvertisingRulesVisitor.prototype.visitComparison = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by AdvertisingRulesParser#expression.
AdvertisingRulesVisitor.prototype.visitExpression = function(ctx) {
  return this.visitChildren(ctx);
};


// Visit a parse tree produced by AdvertisingRulesParser#start.
AdvertisingRulesVisitor.prototype.visitStart = function(ctx) {
  return this.visitChildren(ctx);
};



exports.AdvertisingRulesVisitor = AdvertisingRulesVisitor;