import React, { FC, useState } from 'react';
import { Upload, Button } from 'antd';
import { RcCustomRequestOptions } from 'antd/lib/upload/interface';
import apiFetch from 'utils/apiFetch';

type CarAuditSwitchProps = {
	readonly carAuditId?: string;
	readonly isVisible?: boolean;
	readonly equipmentDocumentUrl?: string;
};

const CarAuditUploadEquipmentPDF: FC<CarAuditSwitchProps> = ({
	equipmentDocumentUrl,
	carAuditId,
}) => {
	const [uploadedFileUrl, setUploadedFileUrl] = useState(equipmentDocumentUrl);

	const fileList = uploadedFileUrl
		? [
			{
				size: 1,
				type: 'pdf',
				uid: uploadedFileUrl,
				// normally we have only very long URL from API
				// but after upload we have just file name ( `file?.name?.toString()` )
				...(uploadedFileUrl.startsWith('https://')
					? { name: 'equipment.pdf', url: uploadedFileUrl }
					: { name: uploadedFileUrl }),
			},
		  ]
		: [];
	const handleDocumentUpload = async ({ file, onSuccess }: RcCustomRequestOptions) => {
		if (file) {
			const formData = new FormData();
			formData.append('file', file);

			await apiFetch(`api/carvago-admin/caraudit/equipment-document/${carAuditId}`, {
				method: 'POST',
				body: formData,
			}).then((res) => res.json());

			setUploadedFileUrl(file?.name?.toString());

			onSuccess({}, file);
		}
	};

	return (
		<Upload
			accept={'document/pdf'}
			customRequest={handleDocumentUpload}
			fileList={fileList}
			showUploadList={{
				showRemoveIcon: false,
				showDownloadIcon: true,
				showPreviewIcon: false,
			}}
		>
			<Button>Upload PDF</Button>
		</Upload>
	);
};

export default CarAuditUploadEquipmentPDF;
