import React from 'react';
import { Result, Button } from 'antd';
import { Trans } from '@lingui/macro';
import * as pathnames from 'pathnames';
import Navigate from 'render-components/Navigate';

const NotFound = () => (
	<Navigate>
		{({ replace }) => (
			<Result
				status="404"
				title="404"
				subTitle={<Trans>PAGE_NOT_FOUND</Trans>}
				extra={
					<Button type="primary" onClick={() => replace(pathnames.ROOT_PATHNAME)} size="large">
						<Trans>TO_DASHBOARD</Trans>
					</Button>
				}
			/>
		)}
	</Navigate>
);

export default NotFound;
