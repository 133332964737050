import useFetch, { someLoading, someError } from 'hooks/useFetch';
import { useCallback } from 'react';
import { AdvertisedCarTypeStringId } from 'types/car';

interface UseCarDataType {
	carQuery: string;
	countQuery?: string;
	lazy?: boolean;
	body?: any;
}

interface UseCarDataResult {
	isLoading: boolean;
	carCount: number;
	carDataWithPrices: AdvertisedCarTypeStringId[];
	hasError: boolean;
	fetchCarData: (body?: any) => void;
}

export const useCarData = ({
	carQuery,
	countQuery,
	lazy = false,
}: UseCarDataType): UseCarDataResult => {
	const carsWithPrice = useFetch(carQuery, { lazy, init: { method: 'POST' }});
	const count = useFetch(countQuery ?? "", { lazy: !countQuery || lazy });

	const fetchLazyData = (body?: any) => {
		carsWithPrice.fetchData({}, body);
		if (countQuery) {
			count.fetchData();
		}
	};

	return {
		isLoading: someLoading([carsWithPrice]),
		carCount: parseInt(carsWithPrice?.response?.headers.get("X-Total") ?? count.data?.count ?? 0, 10),
		carDataWithPrices: carsWithPrice.data ?? [],
		hasError: someError([carsWithPrice, count]),
		fetchCarData: fetchLazyData,
	};
};
