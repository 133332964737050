import React from 'react';
import CenterCenter from 'components/CenterCenter';
import { Spin } from 'antd';

export const Loading: React.FC = () => {
	return (
		<CenterCenter>
			<Spin size="large" />
		</CenterCenter>
	);
};
