import React, { FunctionComponent, useCallback } from 'react';
import styled, { css } from 'styled-components/macro';
import { Alert, Table } from 'antd';
import { ColumnProps, PaginationConfig, SorterResult } from 'antd/lib/table';
import { Trans } from "@lingui/macro";

const StyledTable = styled(Table)<{ noPadding: boolean }>`
	.ant-table-row td {
		${({noPadding}) => noPadding && css`padding: 0 !important;`}
		font-size: 12px;
		&:first-child {
			padding-left: 10px !important;
		}
	}
`;

type CustomTablePropsType = {
	tableColumns: Array<ColumnProps<any>>;
	dataSource: any[];
	dataTestIdTableName: string;
	isLoading?: boolean;
	totalItemCount: number;
	pageSize: number;
	noPadding?: boolean;
	onPageChange: (page: number, pageSize?: number | undefined) => void;
	onChange?: (pagination: PaginationConfig, filters: any, sorter: SorterResult<any>) => void;
	onRow?: { [key: string]: any };
	onRowClick?: (id: string) => void;
	error?: unknown;
	currentPage?: number;
};

export const CustomTable: FunctionComponent<CustomTablePropsType> = ({
	tableColumns,
	dataSource,
	dataTestIdTableName,
	isLoading,
	totalItemCount,
	pageSize,
	onPageChange,
	onChange,
	onRow = {},
	onRowClick,
	noPadding = false,
	error,
	currentPage,
}) => {
	const onRowClickHandler = useCallback((id: string) => {
		if (onRowClick && id) {
			onRowClick(id);
		}
	}, [onRowClick]);

	return (
		<>
			{!error ?
				<StyledTable
					data-testid={`${dataTestIdTableName}-table`}
					columns={tableColumns}
					dataSource={dataSource}
					onChange={onChange}
					scroll={{ x: 1080 }}
					onRow={(record: any, index) => ({
						...onRow,
						'data-testid': `${dataTestIdTableName}-table-row-${index}`,
						onClick: () => {
							onRowClickHandler(record.id);
						},
					})}
					loading={isLoading}
					rowKey={({ id }: any) => id}
					pagination={
						totalItemCount >= pageSize && {
							current: currentPage,
							position: 'both',
							total: totalItemCount,
							pageSize,
							onChange: onPageChange,
							size: 'middle',
						}
					}
					size="middle"
					noPadding={noPadding}
				/>
				: <Alert
					style={{ marginBottom: '10px' }}
					type="error"
					showIcon
					message={<Trans id="LOAD_ERROR" />}
				/>
			}
		</>
	);
};
