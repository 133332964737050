import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Layout, Menu, Icon, Spin } from 'antd';
import { Trans } from '@lingui/macro';
import User from 'render-components/User';
import { Redirect, withRouter, RouteComponentProps, Link } from 'react-router-dom';
import * as pathnames from 'pathnames';
import store from 'store';
import queryString from 'query-string';
import { ReferenceDataProvider } from 'components/ReferenceDataProvider';
import CenterCenter from 'components/CenterCenter';
import Navigation, { StyledNavigation } from 'components/Navigation';
import LanguageSelector from './LanguageSelector';

const { Sider, Content } = Layout;

const StyledLayout = styled(Layout)`
	min-height: 100vh;
`;

const StyledSider = styled(Sider)`
	overflow: auto;
	height: 100vh;
	position: sticky;
	top: 0;
	left: 0;
`;

const Logout = styled.div`
	cursor: pointer;
	color: rgba(255, 255, 255, 0.65);
	transition: color 0.3s;

	&:hover {
		color: white;
	}

	.anticon {
		margin-right: 10px;
	}
`;

const MenuStyledDivider = styled(Menu.Divider)`
	opacity: 0.65;
`;

const LayoutWrapper = styled.div<{ fixedHeader: boolean }>`
	${StyledLayout} {
		${(props) =>
		props.fixedHeader &&
			css`
				padding-top: 70px;
			`};
	}

	${StyledNavigation} {
		${(props) =>
		props.fixedHeader &&
			css`
				position: fixed;
				width: 100%;
				z-index: 111;
				height: 70px;
				border-bottom: 1px ${({ theme }) => theme.colors.BORDER_GRAY} solid;
				top: 0;
			`};
	}
`;
const StyledContent = styled(Content)<{ noPadding: boolean }>`
	padding: ${(props) => (props.noPadding ? 0 : 20)}px;
`;

type PrivateLayoutType = {
	children: React.ReactNode;
	header?: React.ReactNode;
	noSider?: boolean;
	noPadding?: boolean;
	fixedHeader?: boolean;
	collapsed?: boolean;
	setCollapsed?: (isCollapsed: boolean) => void;
} & RouteComponentProps;

const PrivateLayout = ({
	children,
	location,
	header,
	noSider = false,
	noPadding = false,
	fixedHeader = false,
	collapsed = false,
	setCollapsed,
}: PrivateLayoutType) => (
	<LayoutWrapper fixedHeader={fixedHeader}>
		<StyledLayout>
			<User>
				{(user, { loading: userLoading }) => {
					if (!user && !userLoading) {
						return (
							<Redirect
								to={{
									pathname: pathnames.LOGIN_PATHNAME,
									search: queryString.stringify({
										next: `${location.pathname}${location.search}`,
									}),
								}}
							/>
						);
					}

					if (userLoading) {
						return (
							<CenterCenter>
								<Spin size="large" />
							</CenterCenter>
						);
					}

					let selectedItem = '1';

					switch (window.location.pathname) {
						case pathnames.ADVERTISING_RULES_LISTING_PATHNAME:
							selectedItem = '2';
							break;
						case pathnames.REVIEWS_PATHNAME:
							selectedItem = '3';
							break;
						default:
							selectedItem = '1';
					}

					return (
						<>
							<ReferenceDataProvider>
								<Navigation header={header} />
								<Layout>
									{!noSider && (
										<StyledSider
											collapsible
											collapsed={collapsed}
											onCollapse={() => setCollapsed && setCollapsed(!collapsed)}
										>
											<Menu theme="dark" defaultSelectedKeys={[selectedItem]} mode="inline">
												<Menu.Item key="1" data-testid="Menu_item_my_cars">
													<Link to={pathnames.ROOT_PATHNAME}>
														<Icon type="dashboard" />
														<span>
															<Trans id="NAVIGATION_CARS" />
														</span>
													</Link>
												</Menu.Item>

												<Menu.Item key="2" data-testid="Menu_item_blacklist">
													<Link to={pathnames.ADVERTISING_RULES_LISTING_PATHNAME}>
														<Icon type="close-circle" />
														<span>
															<Trans id="NAVIGATION_ADVERTISING_RULES" />
														</span>
													</Link>
												</Menu.Item>

												<Menu.Item key="3" data-testid="Menu_item_reviews">
													<Link to={pathnames.REVIEWS_PATHNAME}>
														<Icon type="solution" theme="outlined" />
														<span>
															<Trans id="NAVIGATION_REVIEWS" />
														</span>
													</Link>
												</Menu.Item>

												<MenuStyledDivider />
												<LanguageSelector />
												<MenuStyledDivider />

												<Menu.Item key="999" data-testid="Menu_item_logout">
													<Logout
														onClick={() => {
															store.dispatch.user.removeUser();
														}}
													>
														<Icon type="logout" />
														<span>
															<Trans>LOGOUT</Trans>
														</span>
													</Logout>
												</Menu.Item>
											</Menu>
										</StyledSider>
									)}
									<StyledContent noPadding={noPadding}>{children}</StyledContent>
								</Layout>
							</ReferenceDataProvider>
						</>
					);
				}}
			</User>
		</StyledLayout>
	</LayoutWrapper>
);

export default withRouter(PrivateLayout);
