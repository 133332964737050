import { createSelector } from 'reselect';
import { iRootState } from 'store';
import { UserState } from 'models/user';

const selectUserState = (state: iRootState): UserState => {
	return state.user;
};

export const selectUser = createSelector(selectUserState, (userState) => {
	return userState.user;
});

export const selectIsUserLoading = createSelector(selectUserState, (userState) => {
	return userState.isUserLoading;
});
