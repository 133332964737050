import React, { useEffect, useState, FC, useCallback } from 'react';
import Section from 'components/Section';
import useFetch from 'hooks/useFetch';
import InfoTable from './InfoTable';
import { AdvertisedCarResponse, CarAuditInformation, TEASCarResponse } from 'types/carAudit';
import styled from 'styled-components';
import { Button, notification } from 'antd';
import { Trans } from '@lingui/macro';
import { AdvertisedCarType } from 'types/car';

const Wrapper = styled.div`
	margin-bottom: 3rem;
`;

const LeftButtons = styled.div`
	text-align: left;
	margin-bottom: 10px;

	button:not(:first-child) {
		margin-left: 15px;
	}
`;

interface CarAuditProps {
	readonly id: string | number;
	readonly orderId?: number;
	readonly infoID?: string;
	readonly isOrderDetail?: boolean;
}

const useCarAuditData = (id: string | number) => {
	const [advertisedID, setAdvertisedID] = useState<number | undefined>();
	const [teasVehicleId, setTeasVehicleId] = useState<string | undefined>();
	const [carAuditData, setCarAuditData] = useState<Array<CarAuditInformation>>();

	const { data, isLoading } = useFetch<Array<AdvertisedCarResponse>>(
		`api/carvago-admin/caraudit/advertised-car?ids[]=${id}`,
	);

	useEffect(() => {
		const carAdvertisedData = data?.find((item) => item.car_audit_information);
		const carAdvertisedID = carAdvertisedData?.advertised_car.id;
		const carAdvertisedTeasVehicleId = carAdvertisedData?.advertised_car.teas_vehicle_id;

		setTeasVehicleId(carAdvertisedTeasVehicleId);
		setCarAuditData(carAdvertisedData?.car_audit_information);
		setAdvertisedID(carAdvertisedID);
	}, [data]);

	return {
		data: carAuditData,
		advertID: advertisedID,
		isLoading,
		teasVehicleId,
	};
};

const CarAudit: FC<CarAuditProps> = ({ id, orderId, isOrderDetail }) => {
	const { data, advertID, isLoading } = useCarAuditData(id);
	const [teasVehicleId, setTeasVehicleId] = useState<string>();

	const { data: teasVehicle, isLoading: loadingTeasVehicleId } = useFetch<AdvertisedCarType>(
		`/api/carvago-admin/listedcars/${id}`,
		{ lazy: false },
	);

	useEffect(() => {
		if (teasVehicle && teasVehicle.teas_vehicle_id) {
			setTeasVehicleId(teasVehicle.teas_vehicle_id);
		}
	}, [teasVehicle, setTeasVehicleId]);

	const {
		fetchData: fetchCreateTeasVehicle,
		isLoading: loadingCreateTeasVehicle,
	} = useFetch<TEASCarResponse>(`/api/carvago-admin/teas/vehicle`, {
		init: {
			method: 'POST',
		},
		lazy: true,
	});

	const createTeasVehicle = useCallback(() => {
		fetchCreateTeasVehicle({}, { id: Number(id) })
			.then((res) => {
				setTeasVehicleId(res.teas_vehicle_id);
			})
			.catch((error) => {
				notification.error({
					message: error.message,
				});
			});
	}, [fetchCreateTeasVehicle, id]);

	return (
		<Wrapper>
			<Section>
				<LeftButtons>
					{teasVehicleId == null ? (
						<Button
							type="primary"
							onClick={createTeasVehicle}
							disabled={loadingTeasVehicleId}
							loading={loadingCreateTeasVehicle}
						>
							<Trans>CAR_CHECK_SEND_CAR_TO_TEAS</Trans>
						</Button>
					) : (
						<a
							href={`${process.env.REACT_APP_OMNETIC_URL}/vehicles/${teasVehicleId}/conditions/inspections`}
						>
							<Trans>CAR_CHECK_TEAS_ID_BUTTON {String(teasVehicleId)}</Trans>
						</a>
					)}
				</LeftButtons>
				<InfoTable
					isOrderDetail={isOrderDetail}
					orderId={orderId}
					advertisedID={advertID}
					carAudits={data}
					isLoading={isLoading}
				/>
			</Section>
		</Wrapper>
	);
};

export default CarAudit;
