import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import StatusInfo from 'components/StatusInfo';
import { Icon, Popover, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Trans } from '@lingui/macro';
import { formatPrice } from 'utils/formatters';
import { get } from 'lodash';
import useFetch from 'hooks/useFetch';
import { ORDER_ACCEPTED, OrderStatusPlatform, orderSteps, OrderType } from 'types/order';
import { CurrencyType } from 'types/currency';
import { PriceHistory } from 'types/priceHistory';
import { DEFAULT_CURRENCY } from 'constants/index';

type CurrentSellTablePropsType = {
	carId: number;
};

const StyledAnchor = styled.a`
	color: ${({ theme }) => theme.colors.GRAY};
	text-decoration: underline;
	margin-right: 8px;
	cursor: pointer;

	&:hover {
		color: ${({ theme }) => theme.colors.GRAY};
		text-decoration: none;
	}
`;

const getPopoverContent = (record: OrderType) => (
	<div>
		<div>
			{record.user.first_name} {record.user.last_name}
		</div>

		<StyledText>Carvago</StyledText>

		<StyledAnchor
			target="_blank"
			href={`${process.env.REACT_APP_API_URI}admin/?entity=Order&action=show&id=${record.id}`}
			rel="noopener noreferrer"
		>
			{record.id}
		</StyledAnchor>

		<Icon type="link" />
	</div>
);

const StyledText = styled(Typography.Text)`
	display: flex;
	margin-bottom: 20px;
`;

const columns: Array<ColumnProps<OrderType>> = [
	{
		title: <Trans>FINANCE_TABLE_TITLE_STATE</Trans>,
		dataIndex: 'status',
		render: (status: string) => <StatusInfo status={status} />,
	},
	{
		title: <Trans>FINANCE_TABLE_TITLE_BUYER</Trans>,
		dataIndex: 'user.email',
		render: (email: string, record) => (
			<Popover placement="bottom" content={getPopoverContent(record)}>
				<StyledAnchor href={`mailto:${email}`}>{email}</StyledAnchor>
			</Popover>
		),
	},
	{
		title: <Trans>FINANCE_TABLE_TITLE_BUYER_TYPE</Trans>,
		key: 'customer_type',
		render: (_, record) =>
			get(record, 'user.account.dealer') ? <Trans>DEALER</Trans> : <Trans>RETAIL</Trans>,
	},
	{
		title: <Trans>FINANCE_TABLE_TITLE_SELL_PRICE</Trans>,
		key: 'price',
		render: ({
			salesPrice,
			salesPriceCurrency,
			originalSalesPrice,
			originalSalesPriceCurrency,
		}: {
			salesPrice: number;
			salesPriceCurrency: CurrencyType;
			originalSalesPrice: number;
			originalSalesPriceCurrency: CurrencyType;
		}) => (
			<div>
				{formatPrice(originalSalesPrice, originalSalesPriceCurrency?.name)}&nbsp;
				({formatPrice(salesPrice, salesPriceCurrency?.name)})
			</div>
		),
	},
	{
		title: <Trans id="FINANCE_TABLE_TITLE_FIXED_PRICE" />,
		key: 'fixed_price',
		render: ({
			status,
			advertised_car_price,
			advertised_car_price_currency,
		}: {
			status: OrderStatusPlatform;
			advertised_car_price: number;
			advertised_car_price_currency: CurrencyType;
		}) => {
			const isOrderAccepted = orderSteps[status] >= ORDER_ACCEPTED;
			return isOrderAccepted ? (
				<>{formatPrice(advertised_car_price, advertised_car_price_currency.name)}</>
			) : (
				<>-</>
			);
		},
	},
	{
		title: <Trans>FINANCE_TABLE_TITLE_TRANSACTION_LINK_ID</Trans>,
		dataIndex: 'id',
		render: (id: number) => (
			<>
				<StyledAnchor
					target="_blank"
					rel="noopener noreferrer"
					href={`${process.env.REACT_APP_API_URI}admin/?entity=Order&action=show&id=${id}`}
				>
					{id}
				</StyledAnchor>
				<Icon type="link" />
			</>
		),
	},
];

export const CurrentSellTable: FunctionComponent<CurrentSellTablePropsType> = ({ carId }) => {
	const { data: tableData, isLoading } = useFetch(
		`/api/carvago-admin/listedcars/${carId}/orders`,
	);

	const { data: priceHistoryData } = useFetch<PriceHistory[]>(
		`/api/carvago-admin/listedcars/${carId}/price-history`,
		{ indicators: [carId] },
	);

	const salesPrice = priceHistoryData && priceHistoryData[0].price;
	const salesPriceCurrency = priceHistoryData && priceHistoryData[0].price_currency;
	const originalSalesPrice = priceHistoryData && priceHistoryData[0].original_price;
	const originalSalesPriceCurrency =
		priceHistoryData && priceHistoryData[0].original_price_currency;

	return (
		<>
			<Typography.Title level={3}>
				<Trans>CURRENT_SALE_TABLE_TITLE</Trans>
			</Typography.Title>
			<Table
				rowKey={(record) => get(record, 'id')}
				bordered
				loading={isLoading}
				columns={columns}
				data-testid="Current_sell_table"
				onRow={(_, index) => ({ 'data-testid': `Current_sell_table_row_${index + 1}` })}
				dataSource={
					tableData && tableData.length > 0
						? [
							{
								...tableData[0],
								salesPrice,
								salesPriceCurrency,
								originalSalesPrice,
								originalSalesPriceCurrency,
							},
						  ]
						: []
				}
				pagination={false}
				scroll={{ x: 700 }}
				size="small"
			/>
		</>
	);
};
