import { iRootState } from 'store';
import { LocaleType } from 'constants/index';
import { createSelector } from 'reselect';

const selectModel = (state: iRootState): LocaleType | null => state.locale;

const selectLocale = createSelector(selectModel, (locale) => locale);

export {
	selectLocale,
};
