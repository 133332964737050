import { Button, Icon } from 'antd';
import { DetailInfoModalType } from '../pages/AdvertisingRules/components/DetailInfoModal';
import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import { truncate } from 'lodash';
import { MAXIMUM_BLACKLIST_QUERY_LENGTH } from '../constants';

export const InfoView = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

interface CellProps {
	readonly content: string;
	readonly row?: any;
	readonly onClick: (bodyText: string, titleText: DetailInfoModalType) => void
}

export const AdvertisingRuleNameCell: FunctionComponent<CellProps> = ({
	content,
	row ,
	onClick,
}) => (
	<InfoView data-testid="Advertising-rules-column-name">
		{content}
		{row?.description &&
			<Button
				type="link"
				onClick={() => onClick(row.description, DetailInfoModalType.DESCRIPTION )}
			>
				<Icon type="info-circle" />
			</Button>
		}
	</InfoView>
);

export const AdvertisingRuleCarQueryCell: FunctionComponent<CellProps> = ({
	content,
	onClick,
}) => (
	<InfoView data-testid="Advertising-rules-column-advertising-query">
		{truncate(content, {
			length: MAXIMUM_BLACKLIST_QUERY_LENGTH,
			omission: '…',
		})}
		<Button type="link" onClick={() => onClick(content, DetailInfoModalType.CAR_QUERY)}>
			<Icon type="info-circle" />
		</Button>
	</InfoView>
);

export const AdvertisingRuleUserQueryCell: FunctionComponent<CellProps> = ({
	content,
	onClick,
}) => (
	<InfoView data-testid="Advertising-rules-column-user-query">
		{truncate(content, {
			length: MAXIMUM_BLACKLIST_QUERY_LENGTH,
			omission: '…',
		})}
		<Button type="link" onClick={() => onClick(content, DetailInfoModalType.USER_QUERY)}>
			<Icon type="info-circle" />
		</Button>
	</InfoView>
);

