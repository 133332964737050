const Sentry = require('@sentry/browser');
const SentryIntegrations = require('@sentry/integrations');

export default function setupSentry() {
	const sentryOptions = {
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_ENV,
		release: process.env.REACT_APP_SENTRY_RELEASE,
		maxBreadcrumbs: 50,
		attachStacktrace: true,
	};

	if (process.env.REACT_APP_ENV === 'local') {
		// don't send errors to sentry
		sentryOptions.beforeSend = () => null;
	}

	if (['local', 'dev', 'stage'].includes(process.env.REACT_APP_ENV)) {
		// dump errors in the console
		sentryOptions.integrations = [
			new SentryIntegrations.Debug(),
		];
	}

	Sentry.init(sentryOptions);

	return {
		Sentry,
		captureException: (err, ctx) => {
			Sentry.configureScope((scope) => {
				if (err.message) {
					// deduplicate errors by error message
					scope.setFingerprint([err.message]);
				}

				if (err.statusCode) {
					scope.setExtra('statusCode', err.statusCode);
				}

				if (ctx) {
					const { res, errorInfo, query, pathname } = ctx;

					if (res && res.statusCode) {
						scope.setExtra('statusCode', res.statusCode);
					}

					scope.setExtra('query', query);
					scope.setExtra('pathname', pathname);

					if (errorInfo) {
						Object.keys(errorInfo).forEach((key) => scope.setExtra(key, errorInfo[key]));
					}
				}
			});

			return Sentry.captureException(err);
		},
	};
}
