import React from 'react';
import styledMap from 'styled-map';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import { ThemeExportType } from 'stylesheets/theme';

const StyledIcon = styled.div<{ status: string }>`
	display: inline-block;
	width: 7px;
	height: 7px;
	margin-right: 8px;
	border-radius: 50%;
	background: ${styledMap('status', {
		accepted: ({ theme }: ThemeExportType) => theme.colors.SUCCES_GREEN,
		prepared: ({ theme }: ThemeExportType) => theme.colors.ORANGE,
		active: ({ theme }: ThemeExportType) => theme.colors.SUCCES_GREEN,
		reserved: ({ theme }: ThemeExportType) => theme.colors.LIGHT_BLUE,
		sold: ({ theme }: ThemeExportType) => theme.colors.LIGHT_GRAY,
		delisted: ({ theme }: ThemeExportType) => theme.colors.LIGHT_GRAY,
		complaint: ({ theme }: ThemeExportType) => theme.colors.RED,
		rejected: ({ theme }: ThemeExportType) => theme.colors.PRIMARY,
		error: ({ theme }: ThemeExportType) => theme.colors.RED,
		financing: ({ theme }: ThemeExportType) => theme.colors.SUCCES_GREEN,
		order: ({ theme }: ThemeExportType) => theme.colors.SUCCES_GREEN,
		interest: ({ theme }: ThemeExportType) => theme.colors.ORANGE,
		'product-completed': ({ theme }: ThemeExportType) => theme.colors.SUCCES_GREEN,
		'awaiting-payment': ({ theme }: ThemeExportType) => theme.colors.ORANGE,
		default: 'gray',
	})};
`;

type StatusInfoType = {
	status: string;
};

const StatusInfo = ({ status }: StatusInfoType) => {
	let statusText;

	switch (status) {
		case 'accepted':
			statusText = <Trans>ORDER_STATUS_ACCEPTED</Trans>;
			break;
		case 'prepared':
			statusText = <Trans>ORDER_STATUS_PREPARED</Trans>;
			break;
		case 'active':
			statusText = <Trans>ORDER_STATUS_ACTIVE</Trans>;
			break;
		case 'reserved':
			statusText = <Trans>ORDER_STATUS_RESERVED</Trans>;
			break;
		case 'sold':
			statusText = <Trans>ORDER_STATUS_SOLD</Trans>;
			break;
		case 'delisted':
			statusText = <Trans>ORDER_STATUS_DELISTED</Trans>;
			break;
		case 'complaint':
			statusText = <Trans>ORDER_STATUS_COMPLAINT</Trans>;
			break;
		case 'product-completed':
			statusText = <Trans>ORDER_STATUS_PRODUCT_COMPLETED</Trans>;
			break;
		case 'awaiting-payment':
			statusText = <Trans>ORDER_STATUS_AWAITING_PAYMENT</Trans>;
			break;
		case 'rejected':
			statusText = <Trans>ORDER_STATUS_REJECTED</Trans>;
			break;
		case 'error':
			statusText = <Trans>ORDER_STATUS_ERROR</Trans>;
			break;
		case 'interest':
			statusText = <Trans>ORDER_STATUS_INTEREST</Trans>;
			break;
		case 'financing':
			statusText = <Trans>ORDER_STATUS_FINANCING</Trans>;
			break;
		case 'order':
			statusText = <Trans>ORDER_STATUS_ORDER</Trans>;
			break;
		default:
			statusText = status;
	}

	return (
		<>
			<StyledIcon status={status} />
			{statusText}
		</>
	);
};

export default StatusInfo;
