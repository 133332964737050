import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import useFetch from 'hooks/useFetch';
import { reviewsTableColumns } from './ReviewsTableColumn';
import { CustomTable } from 'components/CustomTable';
import {
	FilterReviewsType,
	Review,
	ReviewTableProps,
	TotalVisibleReviewsPerCountry,
} from './types';
import { stringify } from 'query-string';
import ReviewsAlerts from './ReviewsAlerts';
import { useSelector } from 'react-redux';
import { selectSupportedReviewsCountries } from 'selectors/referenceData';
import {
	fetchTotalVisibleReviewsPerCountry,
	toggleReviewHomepageVisibility,
} from './fetchTotalVisibleReviewsPerCountry';
import { Trans } from '@lingui/macro';
import { Alert, notification } from 'antd';
import apiFetch from 'utils/apiFetch';
import { CancelConfirmModal } from './DeleteConfirmModal';

const ReviewsTable: FunctionComponent<ReviewTableProps> = ({
	filters,
	dataTestIdTableName,
	handleClickOnVisiblePerCountryButtons,
}) => {
	const supportedReviewsCountries = useSelector(selectSupportedReviewsCountries);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalVisibleReviewsPerCountry, setTotalVisibleReviewsPerCountry] = useState<
		TotalVisibleReviewsPerCountry[]
	>([]);
	const [idToBeDeleted, setIdToBeDeleted] = useState<string>();
	const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
	const [isToggleErrorAlertVisible, setIsToggleErrorAlertVisible] = useState(true);
	const DEFAULT_TABLE_PAGE_SIZE = 20;
	const getTotalVisibleReviewsPerCountry = useCallback(async (): Promise<void> => {
		if (supportedReviewsCountries) {
			const totalVisibleReviewsPerCountry = await Promise.all(
				supportedReviewsCountries.map(async (country) => ({
					country: country.name as string,
					countryId: country.id,
					homepageReviewsCount: await fetchTotalVisibleReviewsPerCountry(country.id),
				})),
			);

			setTotalVisibleReviewsPerCountry(totalVisibleReviewsPerCountry);
		}
	}, [supportedReviewsCountries]);

	useEffect(() => {
		//set current page to 1 when filters changed
		setCurrentPage(1);
		setIsToggleErrorAlertVisible(true);
	}, [filters]);

	useEffect(() => {
		getTotalVisibleReviewsPerCountry();
		setIsToggleErrorAlertVisible(true);
	}, [getTotalVisibleReviewsPerCountry]);

	const query = stringify(
		{
			limit: DEFAULT_TABLE_PAGE_SIZE,
			page: currentPage,
			country: filters.reviewsCountry,
			homepage: filters.homepage,
			'is-visible': filters.visibility,
			'is-prioritized': filters.priority,
			fulltext: filters.fulltext,
		},
		{ skipNull: true, skipEmptyString: true },
	);

	const {
		data,
		totalCount,
		isLoading,
		fetchData,
		error,
	} = useFetch(`/api/carvago-admin/reviews?${query}`, { lazy: false });

	useEffect(() => {
		if (error) {
			notification.error({
				message: <Trans id="REVIEWS_LOAD_ERROR" />,
			});
			throw error;
		}
	}, [error]);

	const deleteAfterConfirmation = (id: string) => {
		setIdToBeDeleted(id);
		setIsConfirmDeleteModalVisible(true);
	};

	const handleHomepageVisibilityToggle = async (data: Review) => {
		if (!data.is_visible && !data.homepage) {
			setIsToggleErrorAlertVisible(false);
			return;
		}

		setIsToggleErrorAlertVisible(true);
		try {
			await toggleReviewHomepageVisibility(data);
			await Promise.all([fetchData(), getTotalVisibleReviewsPerCountry()]);
		} catch {
			notification.error({ message: <Trans id="ORDER_STATUS_ERROR" /> });
		}
	};

	const handleVisibleReviewsPerCountry = (values: FilterReviewsType) => {
		handleClickOnVisiblePerCountryButtons(values);
	};

	return (
		<>
			{totalVisibleReviewsPerCountry.length && (
				<ReviewsAlerts
					reviews={totalVisibleReviewsPerCountry}
					showVisibleReviewsPerCountry={handleVisibleReviewsPerCountry}
				/>
			)}
			{!isToggleErrorAlertVisible && (
				<Alert
					type="error"
					message={<Trans id="REVIEWS_HOMEPAGE_VISIBILITY_ERROR" />}
					closable
					style={{ marginBottom: '10px' }}
					showIcon
				/>
			)}
			<CustomTable
				tableColumns={reviewsTableColumns({
					deleteAfterConfirmation,
					handleHomepageVisibilityToggle,
				})}
				dataSource={data}
				dataTestIdTableName={dataTestIdTableName}
				isLoading={isLoading}
				totalItemCount={totalCount}
				pageSize={DEFAULT_TABLE_PAGE_SIZE}
				onPageChange={setCurrentPage}
				onRow={{ style: { cursor: 'pointer' } }}
				error={error}
				currentPage={currentPage}
			/>
			<CancelConfirmModal
				isModalOpen={isConfirmDeleteModalVisible}
				onCancel={() => {
					setIsConfirmDeleteModalVisible(false);
					setIdToBeDeleted(undefined);
				}}
				onConfirm={() => {
					setIsConfirmDeleteModalVisible(false);
					apiFetch(`/api/carvago-admin/reviews/${idToBeDeleted}`, {
						method: 'DELETE',
					})
						.then(() => {
							fetchData();
						})
						.catch(() => {
							notification.error({
								message: <Trans id="REVIEWS_STATUS_ERROR" />,
							});
						})
						.finally(() => {
							setIdToBeDeleted(undefined);
						});
				}}
			/>
		</>
	);
};

export default ReviewsTable;
