import React, { FunctionComponent } from 'react';
import { FiltersType } from 'types/filters';
import { WrappedFormUtils } from 'antd/es/form/Form';
import { Checkbox, Col, Select } from 'antd';
import { Trans } from '@lingui/react';
import { useSelector } from 'react-redux';
import { selectReferenceData } from 'selectors/referenceData';
import { CustomDivider, CustomRow, FormBlock, FormItem, FormItemsWrap } from '../../pages/Dashboard/components/FilterDesignComponents';
import filterOption from './utils/filterOption';
import { CountryOptionType } from 'types/countryOption';
import { CUSTOMER_OPTIONS } from 'constants/blacklist';
import { UserDealerOptionType } from 'types/user';

const DEFAULT_COUNTRY_ID = 10; // Czech Republic

interface BlackListFilterProps {
	initialValues: FiltersType;
	form: WrappedFormUtils;
}

const BlackListFilter: FunctionComponent<BlackListFilterProps> = ({
	form,
	initialValues,
}) => {
	const referenceData = useSelector(selectReferenceData);
	const { getFieldDecorator} = form;

	return (
		<>
			<FormBlock>
				<FormItemsWrap>
					<FormItem>
						{getFieldDecorator('advertising-rules-apply', {
							valuePropName: 'checked',
							initialValue: initialValues['advertising-rules-apply'],
						})(
							<Checkbox>
								<Trans id="FILTERS_BLACKLIST_CHECKBOX" />
							</Checkbox>,
						)}
					</FormItem>
				</FormItemsWrap>
			</FormBlock>
			<CustomDivider />
			<FormBlock>
				<CustomRow gutter={75} align="top" type="flex">
					<Col span={8}>
						<FormItemsWrap>
							<FormItem>
								{getFieldDecorator('advertising-rules-user-country', {
									initialValue: initialValues['advertising-rules-user-country'] ?? DEFAULT_COUNTRY_ID,
								})(
									<Select
										showSearch
										placeholder={<Trans id="FEED_COUNTRY_LABEL" />}
										filterOption={filterOption}
									>
										{referenceData.countryOptions.map(
											(option: CountryOptionType) => (
												<Select.Option key={option.value} value={option.value}>
													{option.label}
												</Select.Option>
											),
										)}
									</Select>,
								)}
							</FormItem>
							<FormItem>
								{getFieldDecorator('advertising-rules-user-dealer', {
									initialValue: 'false',
								})(
									<Select
										showSearch
										placeholder={<Trans id="FEED_CUSTOMER_LABEL" />}
										filterOption={filterOption}
									>
										{CUSTOMER_OPTIONS.map(
											(option: UserDealerOptionType) => (
												<Select.Option key={option.label} value={option.value.toString()}>
													{option.label}
												</Select.Option>
											),
										)}
									</Select>,
								)}
							</FormItem>
						</FormItemsWrap>
					</Col>
				</CustomRow>
			</FormBlock>
		</>
	);
};

export default BlackListFilter;
