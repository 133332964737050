import React, { FunctionComponent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { History } from 'history';

type Actions = { push: History['push']; replace: History['replace']; goBack: History['goBack'] };

type NavigateProps = RouteComponentProps & {
	children: (actions: Actions) => React.ReactNode;
};

const Navigate: FunctionComponent<NavigateProps> = ({ history, children }: NavigateProps) => {
	return <>{children({ push: history.push, replace: history.replace, goBack: history.goBack })}</>;
};

export default withRouter(Navigate);
