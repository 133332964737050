import { LocaleType } from 'constants/index';
import Cookies from 'js-cookie';
import { Dispatch } from '../../store';

export const localeEffects = (dispatch: Dispatch) => ({
	setLocaleEffect: (payload: LocaleType): void => {
		Cookies.set('locale', payload, {
			path: '/',
		});

		dispatch.locale.setLocale(payload);
	},
});
