import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import Section from 'components/Section';

export const StyledLink = styled(Link)`
	display: block;
	color: rgba(0, 0, 0, 0.65);
	padding: 12px 8px;
	cursor: pointer;
`;

export const ReviewText = styled.div`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const TableSection = styled(Section)`
	margin-top: 3rem;
`;
