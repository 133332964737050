import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Button, Divider, Typography } from 'antd';
import { Trans } from '@lingui/macro';
import { parse, stringify } from 'query-string';
import { Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import Layout from 'components/PrivateLayout';
import ReviewsTable from './ReviewsTable';
import { TableSearchHeader } from 'components/TableSearchHeader';
import { Header, TableSection, TitleContainer } from './styled';
import { FilterReviewsType, FiltersType } from './types';
import ReviewsFilter from './ReviewsFilter';

type ReviewType = RouteComponentProps;

const Reviews: FunctionComponent<ReviewType> = () => {
	const location = useLocation();
	const history = useHistory();

	const [collapsed, setCollapsed] = useState(true);
	const [filters, setFilters] = useState<FiltersType>(parse(location.search, {
		parseBooleans: true,
		parseNumbers: true,
	}));

	const handleFilterSubmit = useCallback((values: FilterReviewsType) => {
		setFilters(prevState => ({
			...prevState,
			homepage: values.homepage,
			reviewsCountry: values.reviewsCountry,
			visibility: values.visibility,
			priority: values.priority,
		}));
	}, []);

	useEffect(() => {
		const stringFilters = stringify(filters, {skipEmptyString: true, skipNull: true});

		history.push({
			pathname: history.location.pathname,
			search: stringFilters,
		});

	}, [filters, history, handleFilterSubmit]);

	const handleSearch = useCallback((value: string) => {
		setFilters(prevState => ({
			...prevState,
			fulltext: value,
		}));
	}, []);

	const handleClickOnVisiblePerCountryButtons = useCallback((values: FilterReviewsType) => {
		handleFilterSubmit(values);
		handleSearch('');
	}, []);

	return (
		<Layout collapsed={collapsed} setCollapsed={setCollapsed}>
			<TableSection>
				<Header>
					<TitleContainer>
						<Typography.Title level={2}>
							<Trans id="REVIEWS_TITLE" />
						</Typography.Title>
					</TitleContainer>
					<Link to="/reviews-edit" data-testid="Reviews-add-button">
						<Button htmlType="submit" type="primary" size="large">
							<Trans id="REVIEWS_ADD" />
						</Button>
					</Link>
				</Header>

				<Divider dashed />

				<TableSearchHeader
					placeholderTranslationKey="REVIEWS_SEARCH_NAME_PLACEHOLDER"
					handleSearch={handleSearch}
					dataTestId="search_review_name_input"
					defaultValue={filters.fulltext}
				/>

				<ReviewsFilter handleSubmit={handleFilterSubmit} initialValues={filters} />
				<ReviewsTable
					filters={filters}
					dataTestIdTableName="Reviews-table"
					handleClickOnVisiblePerCountryButtons={handleClickOnVisiblePerCountryButtons}
				/>
			</TableSection>
		</Layout>
	);
};

export default Reviews;
