import { theme } from 'stylesheets/theme';
import { CarStatus } from 'types/car';

export const getStatusColor = (status: CarStatus | string): string => {
	let color;
	switch (status) {
		case 'prepared':
		case 'assigned':
		case 'expired':
			color = theme.colors.ORANGE;
			break;
		case 'active':
		case 'accepted':
		case 'actual':
		case 'evaluated':
		case 'published':
			color = theme.colors.SUCCES_GREEN;
			break;
		case 'reserved':
			color = theme.colors.LIGHT_BLUE;
			break;
		case 'sold':
		case 'delisted':
		case 'archived':
		case 'created':
		case 'draft':
			color = theme.colors.GRAY;
			break;
		case 'error':
		case 'complaint':
		case 'outdated':
		case 'rejected':
			color = theme.colors.RED;
			break;
		default:
			color = theme.colors.LIGHT_GRAY;
			break;
	}

	return color;
};
