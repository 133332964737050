import { message } from 'antd';

export const handleValidateReviewCarImageProperties = async (file: File): Promise<boolean> => {
	if (!file) return false;

	const isJpgOrPng =
		file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG/WEBP file!');
		return false;
	}

	const isLessThan10MB = file.size / 1024 / 1024 < 10;
	if (!isLessThan10MB) {
		message.error('Image must be smaller than 10MB!');
		return false;
	}

	const bmp = await createImageBitmap(file);
	const { width } = bmp;
	bmp.close(); // free memory

	if (width < 1920) {
		message.error('Image must be at least 1920px wide!');
		return false;
	}

	return true;
};
