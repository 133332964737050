import { createModel } from '@rematch/core';
// eslint-disable-next-line import/no-cycle
import { RootModel } from './index';

export const referenceData = createModel<RootModel>()({
	state: {} as any,
	reducers: {
		setReferenceData: (state, payload: any) => {
			return payload;
		},
	},
});
