import styled from 'styled-components/macro';
import { Icon } from 'antd';
import { Link } from 'react-router-dom';

export const LangWarning = styled.div`
	margin-top: 1rem;
	line-height: 1.2rem;
	color: #40a9ff;
`;

export const StatusIconWrapper = styled.div`
	margin-right: 0.4rem;
`;

export const StyledLink = styled(Link)`
	color: #000;
`;

export const StyledIcon = styled(Icon)`
	padding: 0 1.6rem;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow-x: auto;
`;

export const MenuLabel = styled.div`
	margin-bottom: 2rem;
	font-weight: 600;
`;

export const MenuForm = styled.div`
	display: flex;
	padding: 3rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #fff;
`;

export const MenuFooter = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const NestedItem = styled.div`
	.ant-select-dropdown-menu & {
		padding-left: 2rem;
	}
`;

export const Wrapper = styled.div`
	display: flex;
`;

export const Language = styled.div`
	line-height: 32px;
	margin-right: 10px;

	&:first-child{
		margin-top: 2.2rem;
	}
`;

export const Label = styled.div`
	font-weight: 600;
	margin-bottom: 1rem;
`;

export const TranslationsLabel = styled(Label)`
	width: 100%;
	text-align: left;
`;

export const InputWrap = styled.div`
	padding: 0 5px;
`;

export const StyledUploadCar = styled.div`
	margin-bottom: 2rem;
	width: 100%;
	display: flex;
	justify-content: left;
`;
