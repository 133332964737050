import React from 'react';
import { CarStatus } from 'types/car';
import { Trans } from '@lingui/react';

interface Props {
	status: CarStatus | string;
	label: string;
}

const getStatusTranslationId = (status: CarStatus | string): string | null => {
	switch (status) {
		case CarStatus.PREPARED:
			return 'ORDER_STATUS_PREPARED';
		case CarStatus.ACTIVE:
			return 'ORDER_STATUS_ACTIVE';
		case CarStatus.ACCEPTED:
			return 'ORDER_STATUS_ACCEPTED';
		case CarStatus.RESERVED:
			return 'ORDER_STATUS_RESERVED';
		case CarStatus.SOLD:
			return 'ORDER_STATUS_SOLD';
		case CarStatus.DELISTED:
			return 'ORDER_STATUS_DELISTED';
		case CarStatus.ERROR:
			return 'ORDER_STATUS_ERROR';
		case CarStatus.COMPLAINT:
			return 'ORDER_STATUS_COMPLAINT';
		case CarStatus.REJECTED:
			return 'ORDER_STATUS_REJECTED';
		default:
			return null;
	}
};

export const CarStatusLabel: React.FC<Props> = ({ status, label }) => {
	if (label) {
		return <>{label}</>;
	}
	const translationId = getStatusTranslationId(status);
	if (translationId) {
		return <Trans id={translationId} />;
	}
	return <>{status}</>;
};

