import { isArray } from 'lodash';
import { FiltersType } from 'types/filters';
import { FeedType } from 'types/feed';

type QueryType = {
	[key: string]: any;
};

export type QueryObjectType = FiltersType & FeedType;

export default (query: QueryType) => {
	const newQueryObject: QueryType = {};
	Object.keys(query).forEach((key) => {
		if (isArray(query[key])) {
			newQueryObject[key.replace('[]', '') as keyof QueryObjectType] = query[key].map((item: any) => {
				// eslint-disable-next-line
				return !isNaN(item) ? parseInt(item, 10) : item;
			});
		} else if (key.indexOf('[]') > -1) {
			newQueryObject[key.replace('[]', '') as keyof QueryObjectType] = query[key]
				.split(';')
				// eslint-disable-next-line
				.map((item: any) => (!isNaN(item) ? parseInt(item, 10) : item));
		} else if (query[key] === 'true' || query[key] === 'false') {
			(newQueryObject[key as keyof QueryObjectType] as boolean) = query[key] === 'true';
		} else if (typeof query[key] === 'boolean') {
			newQueryObject[key as keyof QueryObjectType] = query[key];
		} else if(key !== 'search' && !isNaN(query[key])){
			// we skip `search` because BE accept body with this prop as string 
			// even this prop can be potentional ID what is a pure number
			// change na BE is unfortunately overcoplicated
			newQueryObject[key as keyof QueryObjectType] = parseInt(query[key], 10) as unknown as number;
		} else {
			newQueryObject[key as keyof QueryObjectType] = query[key];
		}
	});

	return newQueryObject;
};
