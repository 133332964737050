import React, { FC, ReactNode, useCallback, useState } from 'react';
import { Trans } from '@lingui/macro';
import { getValueForField } from 'utils/getValueForField';
import { i18n } from '@lingui/core';
import { Table, Button, Dropdown, Menu, notification } from 'antd';
import { CarAuditInformation, Order } from 'types/carAudit';
import CarAuditSwitch from './CarAuditSwitch';
import PrivateOrder from './PrivateOrder';
import { CARAUDIT_SUPPORTED_LANGUAGES } from 'constants/index';
import { getCarAuditPlatformBaseUrl } from 'utils/getPlatformBaseUrl';
import Cookies from 'js-cookie';
import CarAuditUploadEquipmentPDF from './CarAuditUploadEquipmentPDF';

type InfoTableProps = {
	readonly carAudits?: Array<CarAuditInformation>;
	readonly isLoading: boolean;
	readonly advertisedID?: number;
	readonly orderId?: number;
	readonly isOrderDetail?: boolean;
};

const useDownloadCarauditPDF = (carId?: number) => {
	const [isLoading, setLoading] = useState(false);
	const generatedUrl = getCarAuditPlatformBaseUrl();

	const download = useCallback(
		(carAuditId?: string, carAuditLanguage?: string) => {
			if (isLoading) {
				return;
			}
			setLoading(true);
			window
				.fetch(process.env.REACT_APP_HTMLTOPDF_URL || '', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						content: `${generatedUrl}${
							carAuditLanguage === 'en' ? '' : `${carAuditLanguage}/`
						}caraudit/${carAuditId}?isPrint=true&locale=${carAuditLanguage}`,
						filename: `caraudit-${carId}.pdf`,
						forceCookies: {
							tokens: JSON.stringify({
								accessToken: Cookies.get('access_token'),
								refreshToken: Cookies.get('refresh_token'),
							}),
						},
						forceHeaders: {
							// turn off locale detection
							'Accept-Language': '',
							// turn off feature toggles that cause unwanted redirects
							cookie:
								'feature_toggles={"comingSoon_IT":false,"comingSoon_DE":false,"comingSoon_AT":false}',
						},
					}),
				})
				.then((response) => response.json())
				.then((response) => {
					window.open(response.url, '_blank');
					setLoading(false);
				})
				.catch((error) => {
					notification.error({
						message: error.message,
					});
					setLoading(false);
				});
		},
		[isLoading, generatedUrl, carId],
	);

	return { download, isLoading };
};

const InfoTable: FC<InfoTableProps> = ({
	carAudits,
	advertisedID,
	isLoading,
	orderId,
	isOrderDetail,
}) => {
	const { download: downloadPDF, isLoading: downloadingPdf } = useDownloadCarauditPDF(
		advertisedID,
	);

	const downloadDropdownMenu = (carAuditId: string): ReactNode => (
		<Menu>
			{CARAUDIT_SUPPORTED_LANGUAGES.map((language) => (
				<Menu.Item key={language} onClick={() => downloadPDF(carAuditId, language)}>
					{language}
				</Menu.Item>
			))}
		</Menu>
	);

	const columns = [
		{
			title: <Trans id="CAR_AUDIT_ID_CA" />,
			width: '200px',
			key: 'car_audit_id',
			render: (carAudit: CarAuditInformation) => getValueForField(carAudit.car_audit_id),
		},
		{
			title: <Trans id="CAR_AUDIT_PLATFORM_LINK" />,
			key: 'platform_link',
			render: (carAudit: CarAuditInformation) => (
				<a
					href={`${getCarAuditPlatformBaseUrl()}caraudit/${carAudit.id}`}
					target="_blank"
					rel="noreferrer"
				>
					link
				</a>
			),
		},
		{
			title: <Trans id="CAR_AUDIT_STATE_CA" />,
			width: '200px',
			dataIndex: 'status',
			key: 'caraudit_status',
		},
		{
			title: <Trans id="CAR_CHECK_BASIC_INFO_TABLE_DATE" />,
			dataIndex: 'last_changed_at',
			width: '200px',
			key: 'caraudit_last_changed_at',
			render: (item?: string) => getValueForField(item && i18n.date(new Date(item))),
		},
		isOrderDetail
			? {
				title: <Trans id="CAR_AUDIT_PRIVATE" />,
				width: '200px',
				key: 'caraudit_private',
				// eslint-disable-next-line react/display-name
				render: (carAudit: CarAuditInformation) => (
					<PrivateOrder
						carAuditId={carAudit.id}
						orderId={orderId}
						carAuditOrderId={carAudit.order?.id}
					/>
				),
			  }
			: {
				title: <Trans id="CAR_CHECK_BASIC_INFO_TABLE_OWNERSHIP" />,
				width: '100px',
				dataIndex: 'order',
				// eslint-disable-next-line react/display-name
				render: (order?: Order) =>
					order ? (
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`/order?orderId=${order.id}`}
						>
							<Trans id="CAR_CHECK_BASIC_INFO_TABLE_OWNERSHIP_PRIVATE" />
						</a>
					) : (
						<Trans id="CAR_CHECK_BASIC_INFO_TABLE_OWNERSHIP_PUBLIC" />
					),
			  },
		{
			title: <Trans id="CAR_AUDIT_SHOW_HIDE_ON_PLATFORM" />,
			width: '100px',
			key: 'caraudit_is_visible',
			// eslint-disable-next-line react/display-name
			render: (carAudit: CarAuditInformation) => (
				<CarAuditSwitch carAuditId={carAudit.id} isVisible={carAudit.is_visible} />
			),
		},
		{
			title: <Trans id="CAR_AUDIT_UPLOAD_EQUIPMENT_PDF" />,
			width: '80px',
			key: 'caraudit_upload_equipment_pdf',
			// eslint-disable-next-line react/display-name
			render: (carAudit: CarAuditInformation) => (
				<CarAuditUploadEquipmentPDF
					carAuditId={carAudit.id}
					equipmentDocumentUrl={carAudit.equipment_document_url}
				/>
			),
		},
		{
			title: <Trans id="EDITABLE_TABLE_ACTIONS" />,
			width: '200px',
			key: 'caraudit_actions',
			// eslint-disable-next-line react/display-name
			render: (carAudit: CarAuditInformation) => (
				<Dropdown
					trigger={['click']}
					overlay={downloadDropdownMenu(carAudit.id)}
					disabled={carAudit.status !== 'finished_audit'}
				>
					<Button
						disabled={carAudit.status !== 'finished_audit'}
						type="ghost"
						icon="file-pdf"
					>
						<span>
							{downloadingPdf ? (
								<Trans id="LOADING" />
							) : (
								<Trans id="CAR_CHECK_DETAIL_DOWNLOAD_PDF" />
							)}
						</span>
					</Button>
				</Dropdown>
			),
		},
	];

	return (
		<Table
			dataSource={carAudits}
			pagination={false}
			bordered
			size="small"
			loading={isLoading}
			rowKey="id"
			columns={columns}
		/>
	);
};

export default InfoTable;
