import styled from 'styled-components/macro';

export type DynamicType = 'negative' | 'positive' | 'neutral';

export const SignedPrice = styled.span<{ dynamic: DynamicType }>`
	color: ${({ dynamic }) =>
		dynamic === 'neutral'
			? '#000'
			: dynamic === 'positive'
				? ({ theme }) => theme.colors.SUCCES_GREEN
				: ({ theme }) => theme.colors.RED};
`;
