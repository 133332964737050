import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as pathnames from 'pathnames';
import Dashboard from 'pages/Dashboard';
import Login from 'pages/Login';
import CarDetail from 'pages/CarDetail';
import NotFound from 'pages/NotFound';
import AdvertisingRules from 'pages/AdvertisingRules';
import AdvertisingRuleEdit from 'pages/AdvertisingRuleEdit';
import ReviewDetail from 'pages/ReviewDetail';
import Reviews from 'pages/Reviews';
import { ExternalCarDetail } from 'pages/ExternalCarDetail';

const Routes = () => (
	<Switch>
		<Route exact path={pathnames.ROOT_PATHNAME} component={Dashboard} />
		<Route exact path={pathnames.LOGIN_PATHNAME} component={Login} />
		<Route exact path={pathnames.EXTERNAL_CAR_DETAIL_PATHNAME} component={ExternalCarDetail} />
		<Route exact path={pathnames.CAR_DETAIL_PATHNAME} component={CarDetail} />
		<Route exact path={pathnames.ADVERTISING_RULES_LISTING_PATHNAME} component={AdvertisingRules} />
		<Route exact path={pathnames.ADVERTISING_RULE_EDIT_PATHNAME} component={AdvertisingRuleEdit} />
		<Route exact path={pathnames.REVIEWS_PATHNAME} component={Reviews} />
		<Route exact path={pathnames.REVIEWS_EDIT_PATHNAME} component={ReviewDetail} />
		<Route component={NotFound} />
	</Switch>
);

export default Routes;
