import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Section from 'components/Section';
import BlacklistTable from './BlacklistTable';
import { AdvertisingRule } from 'types/advertisingRules';

const Wrapper = styled.div`
	margin-bottom: 3rem;
`;

interface BlacklistProps {
	readonly data: AdvertisingRule[];
}

const Blacklist: FunctionComponent<BlacklistProps> = ({ data }) => {
	return (
		<Wrapper>
			<Section>
				<BlacklistTable data={data}/>
			</Section>
		</Wrapper>
	);
};

export default Blacklist;
