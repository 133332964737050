import React, { FunctionComponent } from 'react';
import { GetDataError } from 'restful-react';
import { Result } from 'antd';
import { Trans } from '@lingui/macro';

type NotFoundForbiddenWrapperPropsType = {
	error: GetDataError<any> | null | undefined;
	children: any;
};

const statuses = {
	403: {
		title: 403,
		subTitle: <Trans>FORBIDDEN</Trans>,
	},
	404: {
		title: 404,
		subTitle: <Trans>RESOURCE_NOT_FOUND</Trans>,
	},
};

const NotFoundForbiddenWrapper: FunctionComponent<NotFoundForbiddenWrapperPropsType> = ({
	error,
	children,
}) => {
	if (error === null || error === undefined || (error.status !== 404 && error.status !== 403)) {
		return <>{children}</>;
	}

	return <Result status={error.status.toString() as '404' | '403'} {...statuses[error.status]} />;
};

export default NotFoundForbiddenWrapper;
