const round = (value: number | string) => {
	return Math.round((typeof value === 'string' ? parseInt(value, 10) : value ) * 100) / 100;
};

export const formatPrice = (price: number | string | null, currency = '') => {
	if (price === undefined || price === null) {
		return '';
	}
	switch (currency) {
		case 'EUR':
			return `${round(price)
				.toString()
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${'\xa0'}`)}${'\xa0'}€`;
		case 'GBP':
			return `${round(price)
				.toString()
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${'\xa0'}`)}${'\xa0'}£`;
		case 'CZK':
			return `${round(price)
				.toString()
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${'\xa0'}`)}${'\xa0'}Kč`;
		case 'HUF':
			return `${round(price)
				.toString()
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${'\xa0'}`)}${'\xa0'}Ft`;
		case 'PLN':
			return `${round(price)
				.toString()
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${'\xa0'}`)}${'\xa0'}zł`;
		case 'USD':
			return `$${round(price)
				.toString()
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${'\xa0'}`)}`;
		default:
			return `${round(price)
				.toString()
				.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${'\xa0'}`)}${'\xa0'}${currency}`;
	}
};
