import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const StyledLink = styled(Link)`
	&& {
		color: ${({ theme }) => theme.colors.LIGHT_BLUE};
		text-decoration: none;
		background-color: transparent;
		outline: none;
		cursor: pointer;
		-webkit-transition: color 0.3s;
		transition: color 0.3s;
		-webkit-text-decoration-skip: objects;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&&:hover {
		text-decoration: none;
		outline: 0;
		color: #40a9ff;
	}

	&&:active {
		text-decoration: none;
		outline: 0;
		color: ${({ theme }) => theme.colors.BLUE};
	}
`;

type BreadcrumbLinkType = {
	to: string;
	text: React.ReactNode;
};

const BreadcrumbLink = ({ to, text }: BreadcrumbLinkType) => (
	<StyledLink to={to}>{text}</StyledLink>
);

export default BreadcrumbLink;
