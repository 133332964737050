import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Input } from 'antd';
import { useLingui } from '@lingui/react';

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
`;

const SearchWrap = styled.div`
	max-width: 360px;
	width: 100%;
`;

type TableSearchHeaderPropsType = {
	placeholderTranslationKey?: string;
	handleSearch: (value: string) => void;
	onChange?: (value: string) => void;
	dataTestId: string;
	defaultValue?: string;
};

export const TableSearchHeader: FunctionComponent<TableSearchHeaderPropsType> = ({
	placeholderTranslationKey,
	handleSearch,
	onChange,
	dataTestId,
	defaultValue,
}) => {
	const { i18n } = useLingui();
	const [value, setValue] = useState(defaultValue);

	useEffect(() => {
		setValue(defaultValue);
		if (onChange) onChange(defaultValue ?? '');
	}, [defaultValue]);

	return (
		<Container>
			<SearchWrap>
				<Input.Search
					placeholder={placeholderTranslationKey && i18n._(placeholderTranslationKey)}
					onSearch={handleSearch}
					value={value}
					onChange={({ target }) => {
						setValue(target.value);
						if (onChange) onChange(target.value);
						if (!target.value) handleSearch('');
					}}
					allowClear
					data-testid={dataTestId}
				/>
			</SearchWrap>
		</Container>
	);
};
