// src: https://github.com/styled-components/styled-components-website/issues/392

import * as styledComponents from 'styled-components';
import { ThemeType } from 'stylesheets/theme';

const {
	default: styled,
	css,
	createGlobalStyle,
	keyframes,
	ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<ThemeType>;

export type ThemedStyledProps<P> = styledComponents.ThemedStyledProps<P, ThemeType>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;
