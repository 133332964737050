import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Typography, Modal } from 'antd';

const MenuFooter = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const Content = styled.div`
	padding: 20px 0;
`;

type GenericActionModalType = {
	visible: boolean;
	titleText: ReactNode;
	bodyText: ReactNode;
	leftButton?: ReactNode;
	rightButton?: ReactNode;
	onCancel: () => void;
};

export const GenericActionModal: FunctionComponent<GenericActionModalType> = ({
	visible,
	titleText,
	bodyText,
	leftButton,
	rightButton,
	onCancel,
}) => {
	const displayFooter = leftButton != null || rightButton != null;
	const footer = (
		<MenuFooter>
			{leftButton}
			{rightButton}
		</MenuFooter>
	);
	return (
		<Modal
			title={
				<div>
					<Typography.Title level={4}>{titleText}</Typography.Title>
				</div>
			}
			footer={displayFooter && footer}
			visible={visible}
			onCancel={onCancel}
		>
			<Content>
				<Typography.Text>{bodyText}</Typography.Text>
			</Content>
		</Modal>
	);
};
