import React, { FunctionComponent, Suspense, useEffect } from 'react';
import { Button, Checkbox, Form, Select } from 'antd';
import { Trans } from '@lingui/macro';
import { mapValues } from 'lodash';
import { Flex } from '../Dashboard/components/FilterDesignComponents';
import { useSelector } from 'react-redux';
import { selectSupportedReviewsCountries } from '../../selectors/referenceData';
import styled from 'styled-components/macro';
import { FilterReviewsType } from './types';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { FormComponentProps } from 'antd/lib/form';

const FilterButtonsWrap = styled.div`
	margin: 1rem 0;
`;

type ReviewsFilterType = {
	readonly form: WrappedFormUtils;
	readonly handleSubmit: (values: FilterReviewsType) => void;
	readonly initialValues: FilterReviewsType;
};

type FormProps = FormComponentProps & ReviewsFilterType;

const ReviewsFilter: FunctionComponent<ReviewsFilterType> = ({
	handleSubmit,
	form: { validateFields, getFieldDecorator, setFieldsValue, getFieldsValue },
	initialValues,
}: ReviewsFilterType) => {
	const supportedReviewsCountries = useSelector(selectSupportedReviewsCountries);

	useEffect(() => {
		setFieldsValue(initialValues);
	}, [initialValues, setFieldsValue]);

	const handleResetFilter = () => {
		const resetValues = mapValues(getFieldsValue(), () => undefined);
		setFieldsValue(resetValues);
		handleSubmit(resetValues);
	};

	return (
		<Suspense fallback={'Loading reference data...'}>
			<Form
				style={{ width: '100%', maxWidth: '360px' }}
				onSubmit={(event) => {
					event.preventDefault();
					validateFields((error: unknown, values: FilterReviewsType) => {
						if (!error) {
							handleSubmit(values);
						}
					});
				}}
				data-testid="form_filter_reviews"
			>
				{getFieldDecorator('reviewsCountry', {
					initialValue: initialValues['reviewsCountry'],
				})(
					<Select placeholder={<Trans id="COUNTRY" />} allowClear>
						{supportedReviewsCountries?.map((country) => (
							<Select.Option key={country.id} value={country.id}>
								{country.name}
							</Select.Option>
						))}
					</Select>,
				)}
				<Flex>
					{getFieldDecorator('homepage', {
						valuePropName: 'checked',
						initialValue: initialValues['homepage'],
					})(
						<Checkbox>
							<Trans id="VISIBLE_ON_HOMEPAGE" />
						</Checkbox>,
					)}

					{getFieldDecorator('visibility', {
						valuePropName: 'checked',
						initialValue: initialValues['visibility'],
					})(
						<Checkbox style={{ marginLeft: 0 }}>
							<Trans id="REVIEWS_ROW_AVAILABLE_REVIEW" />
						</Checkbox>,
					)}

					{getFieldDecorator('priority', {
						valuePropName: 'checked',
						initialValue: initialValues['priority'],
					})(
						<Checkbox style={{ marginLeft: 0 }}>
							<Trans id="REVIEWS_HIGH_PRIORITY" />
						</Checkbox>,
					)}
				</Flex>
				<FilterButtonsWrap>
					<Button
						htmlType="submit"
						type="primary"
						size="large"
						data-testid="filter_reviews_submit_button"
					>
						<Trans id="FILTER_SUBMIT" />
					</Button>
					<Button
						htmlType="button"
						type="link"
						onClick={handleResetFilter}
						data-testid="filter_reviews_reset_button"
					>
						<Trans id="FILTER_RESET" />
					</Button>
				</FilterButtonsWrap>
			</Form>
		</Suspense>
	);
};

export default Form.create<FormProps>({ name: 'filter_reviews' })(ReviewsFilter);
