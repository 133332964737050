import React from 'react';
import { connect } from 'react-redux';
import { selectUser, selectIsUserLoading } from 'selectors/user';
import { iRootState } from 'store';
import { User as UserType } from 'models/user';

const mapState = (state: iRootState) => ({
	user: selectUser(state),
	isLoading: selectIsUserLoading(state),
});

type UserProps = ReturnType<typeof mapState> & {
	children: (user: UserType | null, states: { loading: boolean }) => React.ReactNode;
};

const User = ({ children, user, isLoading }: UserProps) => (
	<>{children(user, { loading: isLoading })}</>
);

export default connect(mapState)(User);
