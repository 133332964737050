import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components/macro';
import PrivateLayout from 'components/PrivateLayout';
import { RouteComponentProps } from 'react-router';
import NotFoundForbiddenWrapper from 'components/NotFoundForbiddenWrapper';
import { Breadcrumb, Icon, PageHeader, Spin, Tabs, Typography } from 'antd';
import CenterCenter from 'components/CenterCenter';
import Navigate from 'render-components/Navigate';
import * as pathnames from 'pathnames';
import { Trans } from '@lingui/macro';
import BreadcrumbLink from 'components/BreadcrumbLink';
import useFetch from 'hooks/useFetch';
import queryString from 'query-string';
import 'react-alice-carousel/lib/alice-carousel.css';
import { theme as defaultTheme } from 'stylesheets/theme';
import { AdvertisedCarType, PriceInformationType } from 'types/car';

import Header from './components/Header';
import { Finance } from './components/sections/Finance';
import TabContent from './components/Tab/TabContent';
import TabIconWrapper from './components/Tab/TabIconWrapper';
import CarAudit from './components/sections/CarAudit';
import Blacklist from './components/sections/Blacklist';

type CarDetailPropsType = RouteComponentProps<{ id: string; tab: string }>;

const HeaderContainer = styled.div`
	margin: -20px -20px 0 -20px;
	overflow: hidden;
`;

const SectionWrapper = styled.div``;

const StyledTabs = styled(Tabs)`
	margin: 0 -20px;

	.ant-tabs-bar {
		background-color: white;
	}

	.ant-tabs-nav-container {
		width: 1240px;
		max-width: 100%;
		margin: 0 auto;
		padding: 0 20px;
	}
`;

const FinanceTab = styled.div``;

const CarAuditIcon = styled.div`
	position: relative;
	border: 1px solid ${({ theme }) => theme.colors.PRIMARY};
	border-radius: 5px;
	width: 30px;
	height: 22px;

	&:after {
		content: 'CA';
		color: ${({ theme }) => theme.colors.PRIMARY};
		font-weight: 900;
		font-size: 16px;
		position: absolute;
		top: 0;
		left: 3px;
	}
`;

const useTabs = (history: CarDetailPropsType['history']) => {
	const search = queryString.parse(history.location.search);
	const [activeTab, setActiveTab] = useState<string>(
		(search.tab as string | undefined) || 'FINANCE',
	);

	const onTabClick = (tab: string) => {
		const newSearch = queryString.stringify({
			...queryString.parse(history.location.search),
			tab,
		});

		history.replace({
			pathname: history.location.pathname,
			search: newSearch,
		});

		setActiveTab(tab);
	};

	return { activeTab, onTabClick };
};

const CarDetail: FunctionComponent<CarDetailPropsType> = ({ match, history }) => {
	const { activeTab, onTabClick } = useTabs(history);

	const { data, isLoading, error, fetchData } = useFetch<
		AdvertisedCarType & {
			price_information: PriceInformationType;
		}
	>(`/api/carvago-admin/listedcars/${match.params.id}`);

	return (
		<PrivateLayout
			noSider
			header={
				<Navigate>
					{() => (
						<PageHeader
							title={
								<Breadcrumb>
									<Breadcrumb.Item>
										<Trans>ADMIN</Trans>
									</Breadcrumb.Item>
									<Breadcrumb.Item>
										<BreadcrumbLink
											to={pathnames.ROOT_PATHNAME}
											text={<Trans>CARS_TABLE_TITLE</Trans>}
										/>
									</Breadcrumb.Item>
									<Breadcrumb.Item>
										{data ? (
											(data || {}).title
										) : (
											<Trans>CAR_DETAIL_TITLE</Trans>
										)}
									</Breadcrumb.Item>
								</Breadcrumb>
							}
						/>
					)}
				</Navigate>
			}
		>
			{!data && isLoading ? (
				<CenterCenter>
					<Spin size="large" />
				</CenterCenter>
			) : (
				data !== null && (
					<NotFoundForbiddenWrapper error={error}>
						<HeaderContainer>
							<Header
								car={data}
								refetch={fetchData}
							/>
						</HeaderContainer>

						<StyledTabs
							tabPosition="top"
							activeKey={activeTab}
							onTabClick={(key: string) => onTabClick(key)}
							animated={false}
						>
							<Tabs.TabPane
								tab={
									<FinanceTab data-testid="Tab_finance">
										{(data.purchase_price_cloned ||
											data.selling_price_cloned) && (
											<Icon
												type="warning"
												theme="filled"
												style={{ color: defaultTheme.colors.RED }}
											/>
										)}
										<Trans>TABS_FINANCE</Trans>
									</FinanceTab>
								}
								key="FINANCE"
							>
								<TabContent>
									<SectionWrapper id="Finance">
										<Typography.Title level={2}>
											<TabIconWrapper>
												<Icon type="wallet" theme="filled" />
											</TabIconWrapper>
											<Trans>FINANCE_TITLE</Trans>
										</Typography.Title>
										<Finance car={data}/>
									</SectionWrapper>
								</TabContent>
							</Tabs.TabPane>
							<Tabs.TabPane
								tab={
									<div data-testid="Tab_carCheck">
										<Trans>CAR_CHECK_TAB</Trans>
									</div>
								}
								key="CAR_CHECK"
							>
								<TabContent>
									<SectionWrapper id="car-audit">
										<Typography.Title
											level={2}
											style={{ display: 'flex', alignItems: 'center' }}
										>
											<TabIconWrapper>
												<CarAuditIcon />
											</TabIconWrapper>
											<Trans>CAR_AUDIT_TITLE</Trans>
										</Typography.Title>
										<CarAudit id={match.params.id} />
									</SectionWrapper>
								</TabContent>
							</Tabs.TabPane>
							<Tabs.TabPane
								tab={
									<div data-testid="Tab_blacklist">
										<Trans>BLACKLIST_TAB</Trans>
									</div>
								}
								key="BLACKLIST"
							>
								<TabContent>
									<SectionWrapper id="car-audit">
										<Typography.Title
											level={2}
											style={{ display: 'flex', alignItems: 'center' }}
										>
											<Trans>BLACKLIST_TAB</Trans>
										</Typography.Title>
										<Blacklist data={data.matching_advertising_rules} />
									</SectionWrapper>
								</TabContent>
							</Tabs.TabPane>
						</StyledTabs>
					</NotFoundForbiddenWrapper>
				)
			)}
		</PrivateLayout>
	);
};

export default CarDetail;
