import {Recognizer} from 'antlr4/Recognizer';
import antlr4, {Token} from 'antlr4';


class EndOfInputSyntaxError extends Error {
}

class TokenSyntaxError extends Error {
    public readonly token: string;

    constructor(token: string) {
    	super();
    	this.token = token;
    }
}

class UnexpectedParserError extends Error {
}

class ErrorCollectingErrorListener {
    private readonly errors: Error[];

    constructor() {
    	this.errors = [];
    }

    syntaxError(recognizer: Recognizer, offendingSymbol: Token): void {
    	if (offendingSymbol.type === antlr4.Token.EOF) {
    		this.errors.push(new EndOfInputSyntaxError());
    	} else {
    		this.errors.push(new TokenSyntaxError(offendingSymbol.text));
    	}
    }

    reportAmbiguity(): void {
    	this.errors.push(new UnexpectedParserError());
    }

    reportAttemptingFullContext(): void {
    	this.errors.push(new UnexpectedParserError());
    }

    reportContextSensitivity(): void {
    	this.errors.push(new UnexpectedParserError());
    }

    getParseErrors(): readonly Error[] {
    	return this.errors;
    }
}

export default ErrorCollectingErrorListener;
export {
	EndOfInputSyntaxError,
	TokenSyntaxError,
	UnexpectedParserError,
};
