import React, { FunctionComponent } from 'react';
import { Alert, Button, Typography } from 'antd';
import { FilterReviewsType, TotalVisibleReviewsPerCountry } from './types';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';

const TextButton = styled(Button)`
	margin-left: 0.4rem;
	border: none;
	font-weight: bold;
    background: transparent;
	&::active{
		background: transparent;
	}
`;

interface ReviewsAlertsProps {
	readonly reviews: TotalVisibleReviewsPerCountry[];
	readonly showVisibleReviewsPerCountry: (values: FilterReviewsType) => void;
}


const ReviewsAlerts: FunctionComponent<ReviewsAlertsProps> = ({ reviews, showVisibleReviewsPerCountry }) => {
	const showCountryReviewsCount = (country: string, countryId: number, total: number, index: number) => {

		return (
			<TextButton
				key={index}
				onClick={() => showVisibleReviewsPerCountry({
					homepage: true,
					visibility: true,
					reviewsCountry: countryId,
				})}
			>
				{country} : {total}
			</TextButton>
		);
	};

	return (
		<>
			<Alert
				style={{ marginBottom: '10px' }}
				type="warning"
				showIcon
				message={<Trans id="REVIEWS_COUNT_REQUIRED"/>}
				description={
					<>
						<Typography.Text ><Trans id="REVIEWS_AMEND_COUNT_OF_REVIEWS"/> </Typography.Text>
						{reviews.map(({ country, countryId, homepageReviewsCount }, index: number) =>
							showCountryReviewsCount(country, countryId, homepageReviewsCount, index))
						}
					</>
				}
			/>
		</>
	);
};

export default ReviewsAlerts;
