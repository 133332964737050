import moment from 'moment';

type OptionType = {
	value: number;
	label: string;
};

const getYearOptions = (start?: number, end?: number): OptionType[] => {
	const currentYear = end || parseInt(moment().format('YYYY'), 10);
	const startingYear = start || 1950;
	const fullYearList = Array.from({ length: currentYear - startingYear + 1 }, (v, i) => {
		const val = startingYear + i;
		return { value: val, label: val.toString() };
	});

	const yearList = fullYearList.slice(
		fullYearList.findIndex((item) => item.value === startingYear),
		fullYearList.findIndex((item) => item.value === currentYear) + 1,
	);

	return yearList.reverse();
};

export default getYearOptions;
