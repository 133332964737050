import React from 'react';
import { Form, InputNumber } from 'antd';
import { Trans } from '@lingui/macro';
import { MenuItemContainer } from '../MenuItemContainer';
import { FieldsProps } from '../types';

export const RatingFields: React.FC<FieldsProps> = ({ isLoading, getFieldDecorator, defaultValues }) => (
	<>
		<MenuItemContainer
			labelTranslation={<Trans id="REVIEWS_LABEL_RATING" />}
			isLoading={isLoading}
		>
			<Form.Item>
				{getFieldDecorator('rating', {
					initialValue: defaultValues.rating,
					rules: [
						{
							required: true,
							message: <Trans id="ERROR_REQUIRED_FIELD" />,
						},
					],
				})(<InputNumber step={0.1} max={5} min={0} />)}
			</Form.Item>
		</MenuItemContainer>
	</>
);
