import { UploadFile } from 'antd/lib/upload/interface';

export const generateReviewCarImageThumbnail = (file: UploadFile<Record<string, unknown> | undefined>): void => {
	let src = file?.url;

	if (!src) {
		src = new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(file?.originFileObj as Blob);
			reader.onload = () => resolve(reader.result);
		}) as any;
	}

	const image = new Image();
	image.src = src as string;
	const imgWindow = window?.open(src as string);
	imgWindow?.document.write(image.outerHTML);
};
