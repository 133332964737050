// Generated from src/AdvertisingRules.g4 by ANTLR 4.8
// jshint ignore: start
var antlr4 = require('antlr4/index');
var AdvertisingRulesListener = require('./AdvertisingRulesListener').AdvertisingRulesListener;
var AdvertisingRulesVisitor = require('./AdvertisingRulesVisitor').AdvertisingRulesVisitor;

var grammarFileName = "AdvertisingRules.g4";


var serializedATN = ["\u0003\u608b\ua72a\u8133\ub9ed\u417c\u3be7\u7786\u5964",
    "\u0003\u0018_\u0004\u0002\t\u0002\u0004\u0003\t\u0003\u0004\u0004\t",
    "\u0004\u0004\u0005\t\u0005\u0004\u0006\t\u0006\u0004\u0007\t\u0007\u0004",
    "\b\t\b\u0004\t\t\t\u0004\n\t\n\u0003\u0002\u0003\u0002\u0003\u0002\u0003",
    "\u0002\u0003\u0003\u0003\u0003\u0003\u0003\u0007\u0003\u001c\n\u0003",
    "\f\u0003\u000e\u0003\u001f\u000b\u0003\u0003\u0004\u0003\u0004\u0003",
    "\u0004\u0007\u0004$\n\u0004\f\u0004\u000e\u0004\'\u000b\u0004\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0003",
    "\u0005\u0003\u0005\u0003\u0005\u0003\u0005\u0005\u00053\n\u0005\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0003",
    "\u0006\u0003\u0006\u0003\u0006\u0003\u0006\u0005\u0006?\n\u0006\u0003",
    "\u0007\u0003\u0007\u0003\u0007\u0003\b\u0003\b\u0003\b\u0005\bG\n\b",
    "\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0005\tO\n\t\u0003",
    "\t\u0003\t\u0003\t\u0003\t\u0003\t\u0003\t\u0007\tW\n\t\f\t\u000e\t",
    "Z\u000b\t\u0003\n\u0003\n\u0003\n\u0003\n\u0002\u0003\u0010\u000b\u0002",
    "\u0004\u0006\b\n\f\u000e\u0010\u0012\u0002\u0006\u0003\u0002\u0010\u0015",
    "\u0003\u0002\u0005\u0006\u0004\u0002\t\n\u0010\u0015\u0003\u0002\u0007",
    "\b\u0002^\u0002\u0014\u0003\u0002\u0002\u0002\u0004\u0018\u0003\u0002",
    "\u0002\u0002\u0006 \u0003\u0002\u0002\u0002\b2\u0003\u0002\u0002\u0002",
    "\n>\u0003\u0002\u0002\u0002\f@\u0003\u0002\u0002\u0002\u000eF\u0003",
    "\u0002\u0002\u0002\u0010N\u0003\u0002\u0002\u0002\u0012[\u0003\u0002",
    "\u0002\u0002\u0014\u0015\u0007\r\u0002\u0002\u0015\u0016\u0007\u0003",
    "\u0002\u0002\u0016\u0017\u0007\r\u0002\u0002\u0017\u0003\u0003\u0002",
    "\u0002\u0002\u0018\u001d\u0007\u000e\u0002\u0002\u0019\u001a\u0007\u0018",
    "\u0002\u0002\u001a\u001c\u0007\u000e\u0002\u0002\u001b\u0019\u0003\u0002",
    "\u0002\u0002\u001c\u001f\u0003\u0002\u0002\u0002\u001d\u001b\u0003\u0002",
    "\u0002\u0002\u001d\u001e\u0003\u0002\u0002\u0002\u001e\u0005\u0003\u0002",
    "\u0002\u0002\u001f\u001d\u0003\u0002\u0002\u0002 %\u0007\u000f\u0002",
    "\u0002!\"\u0007\u0018\u0002\u0002\"$\u0007\u000f\u0002\u0002#!\u0003",
    "\u0002\u0002\u0002$\'\u0003\u0002\u0002\u0002%#\u0003\u0002\u0002\u0002",
    "%&\u0003\u0002\u0002\u0002&\u0007\u0003\u0002\u0002\u0002\'%\u0003\u0002",
    "\u0002\u0002()\u0005\u0002\u0002\u0002)*\t\u0002\u0002\u0002*+\u0007",
    "\u000e\u0002\u0002+3\u0003\u0002\u0002\u0002,-\u0005\u0002\u0002\u0002",
    "-.\t\u0003\u0002\u0002./\u0007\u0016\u0002\u0002/0\u0005\u0004\u0003",
    "\u000201\u0007\u0017\u0002\u000213\u0003\u0002\u0002\u00022(\u0003\u0002",
    "\u0002\u00022,\u0003\u0002\u0002\u00023\t\u0003\u0002\u0002\u000245",
    "\u0005\u0002\u0002\u000256\t\u0004\u0002\u000267\u0007\u000f\u0002\u0002",
    "7?\u0003\u0002\u0002\u000289\u0005\u0002\u0002\u00029:\t\u0003\u0002",
    "\u0002:;\u0007\u0016\u0002\u0002;<\u0005\u0006\u0004\u0002<=\u0007\u0017",
    "\u0002\u0002=?\u0003\u0002\u0002\u0002>4\u0003\u0002\u0002\u0002>8\u0003",
    "\u0002\u0002\u0002?\u000b\u0003\u0002\u0002\u0002@A\u0005\u0002\u0002",
    "\u0002AB\t\u0005\u0002\u0002B\r\u0003\u0002\u0002\u0002CG\u0005\b\u0005",
    "\u0002DG\u0005\n\u0006\u0002EG\u0005\f\u0007\u0002FC\u0003\u0002\u0002",
    "\u0002FD\u0003\u0002\u0002\u0002FE\u0003\u0002\u0002\u0002G\u000f\u0003",
    "\u0002\u0002\u0002HI\b\t\u0001\u0002IJ\u0007\u0016\u0002\u0002JK\u0005",
    "\u0010\t\u0002KL\u0007\u0017\u0002\u0002LO\u0003\u0002\u0002\u0002M",
    "O\u0005\u000e\b\u0002NH\u0003\u0002\u0002\u0002NM\u0003\u0002\u0002",
    "\u0002OX\u0003\u0002\u0002\u0002PQ\f\u0005\u0002\u0002QR\u0007\u000b",
    "\u0002\u0002RW\u0005\u0010\t\u0006ST\f\u0004\u0002\u0002TU\u0007\f\u0002",
    "\u0002UW\u0005\u0010\t\u0005VP\u0003\u0002\u0002\u0002VS\u0003\u0002",
    "\u0002\u0002WZ\u0003\u0002\u0002\u0002XV\u0003\u0002\u0002\u0002XY\u0003",
    "\u0002\u0002\u0002Y\u0011\u0003\u0002\u0002\u0002ZX\u0003\u0002\u0002",
    "\u0002[\\\u0005\u0010\t\u0002\\]\u0007\u0002\u0002\u0003]\u0013\u0003",
    "\u0002\u0002\u0002\n\u001d%2>FNVX"].join("");


var atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

var decisionsToDFA = atn.decisionToState.map( function(ds, index) { return new antlr4.dfa.DFA(ds, index); });

var sharedContextCache = new antlr4.PredictionContextCache();

var literalNames = [ null, "'.'", null, "'IN'", "'NOT IN'", "'IS NULL'", 
                     "'IS NOT NULL'", "'LIKE'", "'NOT LIKE'", "'AND'", "'OR'", 
                     null, null, null, "'='", "'!='", "'<'", "'<='", "'>'", 
                     "'>='", "'('", "')'", "','" ];

var symbolicNames = [ null, null, "WHITESPACE", "IN", "NOT_IN", "IS_NULL", 
                      "IS_NOT_NULL", "LIKE", "NOT_LIKE", "AND", "OR", "IDENTIFIER", 
                      "NUMBER_LITERAL", "STRING_LITERAL", "EQUALS", "NOT_EQUALS", 
                      "LESS_THAN", "LESS_THAN_EQUALS", "GREATER_THAN",
                      "GREATER_THAN_EQUALS", "OPEN_PARENTHESIS", "CLOSE_PARENTHESIS",
                      "COMMA" ];

var ruleNames =  [ "field", "nonEmptyNumberList", "nonEmptyStringList", 
                   "numberComparison", "stringComparison", "nullableComparison", 
                   "comparison", "expression", "start" ];

function AdvertisingRulesParser (input) {
	antlr4.Parser.call(this, input);
    this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
    this.ruleNames = ruleNames;
    this.literalNames = literalNames;
    this.symbolicNames = symbolicNames;
    return this;
}

AdvertisingRulesParser.prototype = Object.create(antlr4.Parser.prototype);
AdvertisingRulesParser.prototype.constructor = AdvertisingRulesParser;

Object.defineProperty(AdvertisingRulesParser.prototype, "atn", {
	get : function() {
		return atn;
	}
});

AdvertisingRulesParser.EOF = antlr4.Token.EOF;
AdvertisingRulesParser.T__0 = 1;
AdvertisingRulesParser.WHITESPACE = 2;
AdvertisingRulesParser.IN = 3;
AdvertisingRulesParser.NOT_IN = 4;
AdvertisingRulesParser.IS_NULL = 5;
AdvertisingRulesParser.IS_NOT_NULL = 6;
AdvertisingRulesParser.LIKE = 7;
AdvertisingRulesParser.NOT_LIKE = 8;
AdvertisingRulesParser.AND = 9;
AdvertisingRulesParser.OR = 10;
AdvertisingRulesParser.IDENTIFIER = 11;
AdvertisingRulesParser.NUMBER_LITERAL = 12;
AdvertisingRulesParser.STRING_LITERAL = 13;
AdvertisingRulesParser.EQUALS = 14;
AdvertisingRulesParser.NOT_EQUALS = 15;
AdvertisingRulesParser.LESS_THAN = 16;
AdvertisingRulesParser.LESS_THAN_EQUALS = 17;
AdvertisingRulesParser.GREATER_THAN = 18;
AdvertisingRulesParser.GREATER_THAN_EQUALS = 19;
AdvertisingRulesParser.OPEN_PARENTHESIS = 20;
AdvertisingRulesParser.CLOSE_PARENTHESIS = 21;
AdvertisingRulesParser.COMMA = 22;

AdvertisingRulesParser.RULE_field = 0;
AdvertisingRulesParser.RULE_nonEmptyNumberList = 1;
AdvertisingRulesParser.RULE_nonEmptyStringList = 2;
AdvertisingRulesParser.RULE_numberComparison = 3;
AdvertisingRulesParser.RULE_stringComparison = 4;
AdvertisingRulesParser.RULE_nullableComparison = 5;
AdvertisingRulesParser.RULE_comparison = 6;
AdvertisingRulesParser.RULE_expression = 7;
AdvertisingRulesParser.RULE_start = 8;


function FieldContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_field;
    this.entity = null; // Token
    this.property = null; // Token
    return this;
}

FieldContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
FieldContext.prototype.constructor = FieldContext;

FieldContext.prototype.IDENTIFIER = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(AdvertisingRulesParser.IDENTIFIER);
    } else {
        return this.getToken(AdvertisingRulesParser.IDENTIFIER, i);
    }
};


FieldContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterField(this);
	}
};

FieldContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitField(this);
	}
};

FieldContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitField(this);
    } else {
        return visitor.visitChildren(this);
    }
};




AdvertisingRulesParser.FieldContext = FieldContext;

AdvertisingRulesParser.prototype.field = function() {

    var localctx = new FieldContext(this, this._ctx, this.state);
    this.enterRule(localctx, 0, AdvertisingRulesParser.RULE_field);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 18;
        localctx.entity = this.match(AdvertisingRulesParser.IDENTIFIER);
        this.state = 19;
        this.match(AdvertisingRulesParser.T__0);
        this.state = 20;
        localctx.property = this.match(AdvertisingRulesParser.IDENTIFIER);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function NonEmptyNumberListContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_nonEmptyNumberList;
    this._NUMBER_LITERAL = null; // Token
    this.items = []; // of Tokens
    return this;
}

NonEmptyNumberListContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NonEmptyNumberListContext.prototype.constructor = NonEmptyNumberListContext;

NonEmptyNumberListContext.prototype.NUMBER_LITERAL = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(AdvertisingRulesParser.NUMBER_LITERAL);
    } else {
        return this.getToken(AdvertisingRulesParser.NUMBER_LITERAL, i);
    }
};


NonEmptyNumberListContext.prototype.COMMA = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(AdvertisingRulesParser.COMMA);
    } else {
        return this.getToken(AdvertisingRulesParser.COMMA, i);
    }
};


NonEmptyNumberListContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterNonEmptyNumberList(this);
	}
};

NonEmptyNumberListContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitNonEmptyNumberList(this);
	}
};

NonEmptyNumberListContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitNonEmptyNumberList(this);
    } else {
        return visitor.visitChildren(this);
    }
};




AdvertisingRulesParser.NonEmptyNumberListContext = NonEmptyNumberListContext;

AdvertisingRulesParser.prototype.nonEmptyNumberList = function() {

    var localctx = new NonEmptyNumberListContext(this, this._ctx, this.state);
    this.enterRule(localctx, 2, AdvertisingRulesParser.RULE_nonEmptyNumberList);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 22;
        localctx._NUMBER_LITERAL = this.match(AdvertisingRulesParser.NUMBER_LITERAL);
        localctx.items.push(localctx._NUMBER_LITERAL);
        this.state = 27;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===AdvertisingRulesParser.COMMA) {
            this.state = 23;
            this.match(AdvertisingRulesParser.COMMA);
            this.state = 24;
            localctx._NUMBER_LITERAL = this.match(AdvertisingRulesParser.NUMBER_LITERAL);
            localctx.items.push(localctx._NUMBER_LITERAL);
            this.state = 29;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function NonEmptyStringListContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_nonEmptyStringList;
    this._STRING_LITERAL = null; // Token
    this.items = []; // of Tokens
    return this;
}

NonEmptyStringListContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NonEmptyStringListContext.prototype.constructor = NonEmptyStringListContext;

NonEmptyStringListContext.prototype.STRING_LITERAL = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(AdvertisingRulesParser.STRING_LITERAL);
    } else {
        return this.getToken(AdvertisingRulesParser.STRING_LITERAL, i);
    }
};


NonEmptyStringListContext.prototype.COMMA = function(i) {
	if(i===undefined) {
		i = null;
	}
    if(i===null) {
        return this.getTokens(AdvertisingRulesParser.COMMA);
    } else {
        return this.getToken(AdvertisingRulesParser.COMMA, i);
    }
};


NonEmptyStringListContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterNonEmptyStringList(this);
	}
};

NonEmptyStringListContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitNonEmptyStringList(this);
	}
};

NonEmptyStringListContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitNonEmptyStringList(this);
    } else {
        return visitor.visitChildren(this);
    }
};




AdvertisingRulesParser.NonEmptyStringListContext = NonEmptyStringListContext;

AdvertisingRulesParser.prototype.nonEmptyStringList = function() {

    var localctx = new NonEmptyStringListContext(this, this._ctx, this.state);
    this.enterRule(localctx, 4, AdvertisingRulesParser.RULE_nonEmptyStringList);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 30;
        localctx._STRING_LITERAL = this.match(AdvertisingRulesParser.STRING_LITERAL);
        localctx.items.push(localctx._STRING_LITERAL);
        this.state = 35;
        this._errHandler.sync(this);
        _la = this._input.LA(1);
        while(_la===AdvertisingRulesParser.COMMA) {
            this.state = 31;
            this.match(AdvertisingRulesParser.COMMA);
            this.state = 32;
            localctx._STRING_LITERAL = this.match(AdvertisingRulesParser.STRING_LITERAL);
            localctx.items.push(localctx._STRING_LITERAL);
            this.state = 37;
            this._errHandler.sync(this);
            _la = this._input.LA(1);
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function NumberComparisonContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_numberComparison;
    this.operator = null; // Token
    this.value = null; // Token
    this.listValue = null; // NonEmptyNumberListContext
    return this;
}

NumberComparisonContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NumberComparisonContext.prototype.constructor = NumberComparisonContext;

NumberComparisonContext.prototype.field = function() {
    return this.getTypedRuleContext(FieldContext,0);
};

NumberComparisonContext.prototype.NUMBER_LITERAL = function() {
    return this.getToken(AdvertisingRulesParser.NUMBER_LITERAL, 0);
};

NumberComparisonContext.prototype.EQUALS = function() {
    return this.getToken(AdvertisingRulesParser.EQUALS, 0);
};

NumberComparisonContext.prototype.NOT_EQUALS = function() {
    return this.getToken(AdvertisingRulesParser.NOT_EQUALS, 0);
};

NumberComparisonContext.prototype.LESS_THAN = function() {
    return this.getToken(AdvertisingRulesParser.LESS_THAN, 0);
};

NumberComparisonContext.prototype.LESS_THAN_EQUALS = function() {
    return this.getToken(AdvertisingRulesParser.LESS_THAN_EQUALS, 0);
};

NumberComparisonContext.prototype.GREATER_THAN = function() {
    return this.getToken(AdvertisingRulesParser.GREATER_THAN, 0);
};

NumberComparisonContext.prototype.GREATER_THAN_EQUALS = function() {
    return this.getToken(AdvertisingRulesParser.GREATER_THAN_EQUALS, 0);
};

NumberComparisonContext.prototype.OPEN_PARENTHESIS = function() {
    return this.getToken(AdvertisingRulesParser.OPEN_PARENTHESIS, 0);
};

NumberComparisonContext.prototype.CLOSE_PARENTHESIS = function() {
    return this.getToken(AdvertisingRulesParser.CLOSE_PARENTHESIS, 0);
};

NumberComparisonContext.prototype.nonEmptyNumberList = function() {
    return this.getTypedRuleContext(NonEmptyNumberListContext,0);
};

NumberComparisonContext.prototype.IN = function() {
    return this.getToken(AdvertisingRulesParser.IN, 0);
};

NumberComparisonContext.prototype.NOT_IN = function() {
    return this.getToken(AdvertisingRulesParser.NOT_IN, 0);
};

NumberComparisonContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterNumberComparison(this);
	}
};

NumberComparisonContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitNumberComparison(this);
	}
};

NumberComparisonContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitNumberComparison(this);
    } else {
        return visitor.visitChildren(this);
    }
};




AdvertisingRulesParser.NumberComparisonContext = NumberComparisonContext;

AdvertisingRulesParser.prototype.numberComparison = function() {

    var localctx = new NumberComparisonContext(this, this._ctx, this.state);
    this.enterRule(localctx, 6, AdvertisingRulesParser.RULE_numberComparison);
    var _la = 0; // Token type
    try {
        this.state = 48;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,2,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 38;
            this.field();
            this.state = 39;
            localctx.operator = this._input.LT(1);
            _la = this._input.LA(1);
            if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << AdvertisingRulesParser.EQUALS) | (1 << AdvertisingRulesParser.NOT_EQUALS) | (1 << AdvertisingRulesParser.LESS_THAN) | (1 << AdvertisingRulesParser.LESS_THAN_EQUALS) | (1 << AdvertisingRulesParser.GREATER_THAN) | (1 << AdvertisingRulesParser.GREATER_THAN_EQUALS))) !== 0))) {
                localctx.operator = this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            this.state = 40;
            localctx.value = this.match(AdvertisingRulesParser.NUMBER_LITERAL);
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 42;
            this.field();
            this.state = 43;
            localctx.operator = this._input.LT(1);
            _la = this._input.LA(1);
            if(!(_la===AdvertisingRulesParser.IN || _la===AdvertisingRulesParser.NOT_IN)) {
                localctx.operator = this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            this.state = 44;
            this.match(AdvertisingRulesParser.OPEN_PARENTHESIS);
            this.state = 45;
            localctx.listValue = this.nonEmptyNumberList();
            this.state = 46;
            this.match(AdvertisingRulesParser.CLOSE_PARENTHESIS);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function StringComparisonContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_stringComparison;
    this.operator = null; // Token
    this.value = null; // Token
    this.listValue = null; // NonEmptyStringListContext
    return this;
}

StringComparisonContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StringComparisonContext.prototype.constructor = StringComparisonContext;

StringComparisonContext.prototype.field = function() {
    return this.getTypedRuleContext(FieldContext,0);
};

StringComparisonContext.prototype.STRING_LITERAL = function() {
    return this.getToken(AdvertisingRulesParser.STRING_LITERAL, 0);
};

StringComparisonContext.prototype.EQUALS = function() {
    return this.getToken(AdvertisingRulesParser.EQUALS, 0);
};

StringComparisonContext.prototype.NOT_EQUALS = function() {
    return this.getToken(AdvertisingRulesParser.NOT_EQUALS, 0);
};

StringComparisonContext.prototype.LESS_THAN = function() {
    return this.getToken(AdvertisingRulesParser.LESS_THAN, 0);
};

StringComparisonContext.prototype.LESS_THAN_EQUALS = function() {
    return this.getToken(AdvertisingRulesParser.LESS_THAN_EQUALS, 0);
};

StringComparisonContext.prototype.GREATER_THAN = function() {
    return this.getToken(AdvertisingRulesParser.GREATER_THAN, 0);
};

StringComparisonContext.prototype.GREATER_THAN_EQUALS = function() {
    return this.getToken(AdvertisingRulesParser.GREATER_THAN_EQUALS, 0);
};

StringComparisonContext.prototype.LIKE = function() {
    return this.getToken(AdvertisingRulesParser.LIKE, 0);
};

StringComparisonContext.prototype.NOT_LIKE = function() {
    return this.getToken(AdvertisingRulesParser.NOT_LIKE, 0);
};

StringComparisonContext.prototype.OPEN_PARENTHESIS = function() {
    return this.getToken(AdvertisingRulesParser.OPEN_PARENTHESIS, 0);
};

StringComparisonContext.prototype.CLOSE_PARENTHESIS = function() {
    return this.getToken(AdvertisingRulesParser.CLOSE_PARENTHESIS, 0);
};

StringComparisonContext.prototype.nonEmptyStringList = function() {
    return this.getTypedRuleContext(NonEmptyStringListContext,0);
};

StringComparisonContext.prototype.IN = function() {
    return this.getToken(AdvertisingRulesParser.IN, 0);
};

StringComparisonContext.prototype.NOT_IN = function() {
    return this.getToken(AdvertisingRulesParser.NOT_IN, 0);
};

StringComparisonContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterStringComparison(this);
	}
};

StringComparisonContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitStringComparison(this);
	}
};

StringComparisonContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitStringComparison(this);
    } else {
        return visitor.visitChildren(this);
    }
};




AdvertisingRulesParser.StringComparisonContext = StringComparisonContext;

AdvertisingRulesParser.prototype.stringComparison = function() {

    var localctx = new StringComparisonContext(this, this._ctx, this.state);
    this.enterRule(localctx, 8, AdvertisingRulesParser.RULE_stringComparison);
    var _la = 0; // Token type
    try {
        this.state = 60;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,3,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 50;
            this.field();
            this.state = 51;
            localctx.operator = this._input.LT(1);
            _la = this._input.LA(1);
            if(!((((_la) & ~0x1f) == 0 && ((1 << _la) & ((1 << AdvertisingRulesParser.LIKE) | (1 << AdvertisingRulesParser.NOT_LIKE) | (1 << AdvertisingRulesParser.EQUALS) | (1 << AdvertisingRulesParser.NOT_EQUALS) | (1 << AdvertisingRulesParser.LESS_THAN) | (1 << AdvertisingRulesParser.LESS_THAN_EQUALS) | (1 << AdvertisingRulesParser.GREATER_THAN) | (1 << AdvertisingRulesParser.GREATER_THAN_EQUALS))) !== 0))) {
                localctx.operator = this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            this.state = 52;
            localctx.value = this.match(AdvertisingRulesParser.STRING_LITERAL);
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 54;
            this.field();
            this.state = 55;
            localctx.operator = this._input.LT(1);
            _la = this._input.LA(1);
            if(!(_la===AdvertisingRulesParser.IN || _la===AdvertisingRulesParser.NOT_IN)) {
                localctx.operator = this._errHandler.recoverInline(this);
            }
            else {
            	this._errHandler.reportMatch(this);
                this.consume();
            }
            this.state = 56;
            this.match(AdvertisingRulesParser.OPEN_PARENTHESIS);
            this.state = 57;
            localctx.listValue = this.nonEmptyStringList();
            this.state = 58;
            this.match(AdvertisingRulesParser.CLOSE_PARENTHESIS);
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function NullableComparisonContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_nullableComparison;
    this.operator = null; // Token
    return this;
}

NullableComparisonContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
NullableComparisonContext.prototype.constructor = NullableComparisonContext;

NullableComparisonContext.prototype.field = function() {
    return this.getTypedRuleContext(FieldContext,0);
};

NullableComparisonContext.prototype.IS_NULL = function() {
    return this.getToken(AdvertisingRulesParser.IS_NULL, 0);
};

NullableComparisonContext.prototype.IS_NOT_NULL = function() {
    return this.getToken(AdvertisingRulesParser.IS_NOT_NULL, 0);
};

NullableComparisonContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterNullableComparison(this);
	}
};

NullableComparisonContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitNullableComparison(this);
	}
};

NullableComparisonContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitNullableComparison(this);
    } else {
        return visitor.visitChildren(this);
    }
};




AdvertisingRulesParser.NullableComparisonContext = NullableComparisonContext;

AdvertisingRulesParser.prototype.nullableComparison = function() {

    var localctx = new NullableComparisonContext(this, this._ctx, this.state);
    this.enterRule(localctx, 10, AdvertisingRulesParser.RULE_nullableComparison);
    var _la = 0; // Token type
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 62;
        this.field();
        this.state = 63;
        localctx.operator = this._input.LT(1);
        _la = this._input.LA(1);
        if(!(_la===AdvertisingRulesParser.IS_NULL || _la===AdvertisingRulesParser.IS_NOT_NULL)) {
            localctx.operator = this._errHandler.recoverInline(this);
        }
        else {
        	this._errHandler.reportMatch(this);
            this.consume();
        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ComparisonContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_comparison;
    return this;
}

ComparisonContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ComparisonContext.prototype.constructor = ComparisonContext;

ComparisonContext.prototype.numberComparison = function() {
    return this.getTypedRuleContext(NumberComparisonContext,0);
};

ComparisonContext.prototype.stringComparison = function() {
    return this.getTypedRuleContext(StringComparisonContext,0);
};

ComparisonContext.prototype.nullableComparison = function() {
    return this.getTypedRuleContext(NullableComparisonContext,0);
};

ComparisonContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterComparison(this);
	}
};

ComparisonContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitComparison(this);
	}
};

ComparisonContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitComparison(this);
    } else {
        return visitor.visitChildren(this);
    }
};




AdvertisingRulesParser.ComparisonContext = ComparisonContext;

AdvertisingRulesParser.prototype.comparison = function() {

    var localctx = new ComparisonContext(this, this._ctx, this.state);
    this.enterRule(localctx, 12, AdvertisingRulesParser.RULE_comparison);
    try {
        this.state = 68;
        this._errHandler.sync(this);
        var la_ = this._interp.adaptivePredict(this._input,4,this._ctx);
        switch(la_) {
        case 1:
            this.enterOuterAlt(localctx, 1);
            this.state = 65;
            this.numberComparison();
            break;

        case 2:
            this.enterOuterAlt(localctx, 2);
            this.state = 66;
            this.stringComparison();
            break;

        case 3:
            this.enterOuterAlt(localctx, 3);
            this.state = 67;
            this.nullableComparison();
            break;

        }
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


function ExpressionContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_expression;
    this.operator = null; // Token
    return this;
}

ExpressionContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
ExpressionContext.prototype.constructor = ExpressionContext;

ExpressionContext.prototype.OPEN_PARENTHESIS = function() {
    return this.getToken(AdvertisingRulesParser.OPEN_PARENTHESIS, 0);
};

ExpressionContext.prototype.expression = function(i) {
    if(i===undefined) {
        i = null;
    }
    if(i===null) {
        return this.getTypedRuleContexts(ExpressionContext);
    } else {
        return this.getTypedRuleContext(ExpressionContext,i);
    }
};

ExpressionContext.prototype.CLOSE_PARENTHESIS = function() {
    return this.getToken(AdvertisingRulesParser.CLOSE_PARENTHESIS, 0);
};

ExpressionContext.prototype.comparison = function() {
    return this.getTypedRuleContext(ComparisonContext,0);
};

ExpressionContext.prototype.AND = function() {
    return this.getToken(AdvertisingRulesParser.AND, 0);
};

ExpressionContext.prototype.OR = function() {
    return this.getToken(AdvertisingRulesParser.OR, 0);
};

ExpressionContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterExpression(this);
	}
};

ExpressionContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitExpression(this);
	}
};

ExpressionContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitExpression(this);
    } else {
        return visitor.visitChildren(this);
    }
};



AdvertisingRulesParser.prototype.expression = function(_p) {
	if(_p===undefined) {
	    _p = 0;
	}
    var _parentctx = this._ctx;
    var _parentState = this.state;
    var localctx = new ExpressionContext(this, this._ctx, _parentState);
    var _prevctx = localctx;
    var _startState = 14;
    this.enterRecursionRule(localctx, 14, AdvertisingRulesParser.RULE_expression, _p);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 76;
        this._errHandler.sync(this);
        switch(this._input.LA(1)) {
        case AdvertisingRulesParser.OPEN_PARENTHESIS:
            this.state = 71;
            this.match(AdvertisingRulesParser.OPEN_PARENTHESIS);
            this.state = 72;
            this.expression(0);
            this.state = 73;
            this.match(AdvertisingRulesParser.CLOSE_PARENTHESIS);
            break;
        case AdvertisingRulesParser.IDENTIFIER:
            this.state = 75;
            this.comparison();
            break;
        default:
            throw new antlr4.error.NoViableAltException(this);
        }
        this._ctx.stop = this._input.LT(-1);
        this.state = 86;
        this._errHandler.sync(this);
        var _alt = this._interp.adaptivePredict(this._input,7,this._ctx)
        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
            if(_alt===1) {
                if(this._parseListeners!==null) {
                    this.triggerExitRuleEvent();
                }
                _prevctx = localctx;
                this.state = 84;
                this._errHandler.sync(this);
                var la_ = this._interp.adaptivePredict(this._input,6,this._ctx);
                switch(la_) {
                case 1:
                    localctx = new ExpressionContext(this, _parentctx, _parentState);
                    this.pushNewRecursionContext(localctx, _startState, AdvertisingRulesParser.RULE_expression);
                    this.state = 78;
                    if (!( this.precpred(this._ctx, 3))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 3)");
                    }
                    this.state = 79;
                    localctx.operator = this.match(AdvertisingRulesParser.AND);
                    this.state = 80;
                    this.expression(4);
                    break;

                case 2:
                    localctx = new ExpressionContext(this, _parentctx, _parentState);
                    this.pushNewRecursionContext(localctx, _startState, AdvertisingRulesParser.RULE_expression);
                    this.state = 81;
                    if (!( this.precpred(this._ctx, 2))) {
                        throw new antlr4.error.FailedPredicateException(this, "this.precpred(this._ctx, 2)");
                    }
                    this.state = 82;
                    localctx.operator = this.match(AdvertisingRulesParser.OR);
                    this.state = 83;
                    this.expression(3);
                    break;

                } 
            }
            this.state = 88;
            this._errHandler.sync(this);
            _alt = this._interp.adaptivePredict(this._input,7,this._ctx);
        }

    } catch( error) {
        if(error instanceof antlr4.error.RecognitionException) {
	        localctx.exception = error;
	        this._errHandler.reportError(this, error);
	        this._errHandler.recover(this, error);
	    } else {
	    	throw error;
	    }
    } finally {
        this.unrollRecursionContexts(_parentctx)
    }
    return localctx;
};


function StartContext(parser, parent, invokingState) {
	if(parent===undefined) {
	    parent = null;
	}
	if(invokingState===undefined || invokingState===null) {
		invokingState = -1;
	}
	antlr4.ParserRuleContext.call(this, parent, invokingState);
    this.parser = parser;
    this.ruleIndex = AdvertisingRulesParser.RULE_start;
    return this;
}

StartContext.prototype = Object.create(antlr4.ParserRuleContext.prototype);
StartContext.prototype.constructor = StartContext;

StartContext.prototype.expression = function() {
    return this.getTypedRuleContext(ExpressionContext,0);
};

StartContext.prototype.EOF = function() {
    return this.getToken(AdvertisingRulesParser.EOF, 0);
};

StartContext.prototype.enterRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.enterStart(this);
	}
};

StartContext.prototype.exitRule = function(listener) {
    if(listener instanceof AdvertisingRulesListener ) {
        listener.exitStart(this);
	}
};

StartContext.prototype.accept = function(visitor) {
    if ( visitor instanceof AdvertisingRulesVisitor ) {
        return visitor.visitStart(this);
    } else {
        return visitor.visitChildren(this);
    }
};




AdvertisingRulesParser.StartContext = StartContext;

AdvertisingRulesParser.prototype.start = function() {

    var localctx = new StartContext(this, this._ctx, this.state);
    this.enterRule(localctx, 16, AdvertisingRulesParser.RULE_start);
    try {
        this.enterOuterAlt(localctx, 1);
        this.state = 89;
        this.expression(0);
        this.state = 90;
        this.match(AdvertisingRulesParser.EOF);
    } catch (re) {
    	if(re instanceof antlr4.error.RecognitionException) {
	        localctx.exception = re;
	        this._errHandler.reportError(this, re);
	        this._errHandler.recover(this, re);
	    } else {
	    	throw re;
	    }
    } finally {
        this.exitRule();
    }
    return localctx;
};


AdvertisingRulesParser.prototype.sempred = function(localctx, ruleIndex, predIndex) {
	switch(ruleIndex) {
	case 7:
			return this.expression_sempred(localctx, predIndex);
    default:
        throw "No predicate with index:" + ruleIndex;
   }
};

AdvertisingRulesParser.prototype.expression_sempred = function(localctx, predIndex) {
	switch(predIndex) {
		case 0:
			return this.precpred(this._ctx, 3);
		case 1:
			return this.precpred(this._ctx, 2);
		default:
			throw "No predicate with index:" + predIndex;
	}
};


exports.AdvertisingRulesParser = AdvertisingRulesParser;
