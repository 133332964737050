import { Icon, Popover } from 'antd';
import React, { ReactNode } from 'react';
import {Trans} from "@lingui/macro";

interface Props {
	readonly title: ReactNode;
	readonly entityProperties: ReadonlyMap<string, ReadonlySet<string>>;
	readonly exampleQuery: string;
}

const blacklistGoogleDoc = 'https://docs.google.com/document/d/1ixZe4aiX9osXvW3v0VcL0xYMMXVcQ8_Uvdimhjpxr9g';

const QueryTooltip = (props: Props) => {
	return (
		<Popover
			title={props.title}
			content={(
				<div>
					<p><Trans id="ADVERTISING_RULE_QUERY_TOOLTIP_ENTITY_PROPERTIES" />:</p>
					<ul>
						{Array.from(props.entityProperties.entries()).map(([entity, properties]) => (
							<li key={entity}>
								{entity}
								<ul>
									{Array.from(properties.keys()).map((property) => (
										<li key={property}>{property}</li>
									))}
								</ul>
							</li>
						))}
					</ul>
					<p><Trans id="ADVERTISING_RULE_QUERY_TOOLTIP_COMPARISON_OPERATORS" />:<br />=,
						!=, &lt;, &gt;, &lt;=, &gt;=, IN, NOT IN, LIKE
					</p>
					<p><Trans id="ADVERTISING_RULE_QUERY_TOOLTIP_LOGIC_OPERATORS" />:<br />AND, OR</p>
					<p><Trans id="ADVERTISING_RULE_QUERY_TOOLTIP_PARENTHESES" />.</p>
					<p>
						<Trans id="ADVERTISING_RULE_QUERY_TOOLTIP_EXAMPLE" />:<br />
						{props.exampleQuery}
					</p>
					<p><a href={blacklistGoogleDoc} target="_blank" rel="noopener noreferrer"><Trans id="ADVERTISING_RULE_QUERY_TOOLTIP_MORE_INFORMATION" /></a></p>
				</div>
			)}
		>
			<Icon type="info-circle" />
		</Popover>
	);
};

export default QueryTooltip;
