import { trimEnd, trimStart, get } from 'lodash';
import Cookies from 'js-cookie';
import { FetchError, UnauthenticatedError } from 'utils/error';
import { detectLocale } from '../models/locale/utils';

interface ValidationMessages {
	readonly [key: string]: string;
}

class ValidationError extends FetchError {
	public readonly validationMessages: ValidationMessages;

	constructor(validationMessages: ValidationMessages) {
		super('Validation error');
		this.validationMessages = validationMessages;
	}
}

const apiFetch = async (input: string, init?: RequestInit) => {
	const normalizedApiUrl = trimEnd(process.env.REACT_APP_API_URI, '/');
	const normalizedInput = `/${trimStart(input, '/')}`;

	const headers: any = {};

	const accessToken = Cookies.get('access_token');

	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`;
	}

	const currency = Cookies.get('currency') || 'CZK';
	if (currency) {
		headers['x-currency'] = currency;
	}

	headers['x-locale'] = detectLocale();

	const response = await window.fetch(normalizedApiUrl + normalizedInput, {
		...init,
		headers: {...headers, ...get(init, 'headers', {})},
	});

	if (response.ok) {
		return response;
	}

	if (response.status === 401) {
		throw new UnauthenticatedError();
	}

	if (response.headers.get('content-type') !== 'application/json') {
		throw new FetchError('Response Content-Type is not JSON.');
	}

	const body = await response.json();

	if (typeof body.message === 'string') {
		throw new FetchError(body.message, response.status);
	}

	if (typeof body.message === 'object' && body.message != null) {
		throw new ValidationError(body.message);
	}

	throw new FetchError();
};

export default apiFetch;
export {
	FetchError,
	ValidationError,
};
