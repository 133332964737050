import React, { FC, useCallback, useState } from 'react';
import { Switch, notification } from 'antd';
import { InformationResponse } from 'types/carAudit';
import useFetch from 'hooks/useFetch';

type PrivateOrderProps = {
	orderId?: number;
	carAuditOrderId?: number;
	carAuditId?: string;
};

const PrivateOrder: FC<PrivateOrderProps> = ({ orderId, carAuditOrderId, carAuditId }) => {
	const [isPrivateOrder, setIsPrivateOrder] = useState<boolean>(Boolean(carAuditOrderId));

	const { fetchData: patchDataPrivateOrder } = useFetch<InformationResponse>(
		`/api/carvago-admin/order/${orderId}`,
		{
			init: {
				method: 'PATCH',
			},
			lazy: true,
			indicators: [isPrivateOrder],
		},
	);

	const handleClick = useCallback(async () => {
		setIsPrivateOrder((isHidden) => !isHidden);
		try {
			await patchDataPrivateOrder(
				{},
				{ car_audit_information: isPrivateOrder ? null : { id: carAuditId } },
			);
		} catch (error) {
			setIsPrivateOrder((isHidden) => !isHidden);
			notification.error({
				message: error.message,
			});
		}
	}, [carAuditId, isPrivateOrder, patchDataPrivateOrder]);

	return <Switch checked={isPrivateOrder} size="small" onClick={handleClick} />;
};

export default PrivateOrder;
