import React from 'react';
import { Trans } from '@lingui/react';

interface Props {
	hidden: boolean;
	error: boolean;
}

const CarVisibility: React.FC<Props> = ({ hidden, error }) => {
	if (error) {
		return null;
	}
	if (hidden) {
		return <Trans id="CARS_TABLE_HIDDEN_ADD" />;
	}
	return (
		<Trans id="CARS_TABLE_VISIBLE_ADD" />
	);
};

export default CarVisibility;
