import { AdvertisingRulesVisitor}  from './generated/AdvertisingRulesVisitor';

class InvalidEntityError extends Error {
    public readonly entity: string;

    constructor(entity: string) {
    	super();
    	this.entity = entity;
    }
}

class InvalidEntityPropertyError extends Error {
    public readonly entity: string;

    public readonly property: string;

    constructor(entity: string, property: string) {
    	super();
    	this.entity = entity;
    	this.property = property;
    }
}

class EntityPropertiesValidationVisitor extends AdvertisingRulesVisitor {
    private readonly entityProperties: ReadonlyMap<string, ReadonlySet<string>>;

    private readonly errors: Error[];

    constructor(entityProperties: ReadonlyMap<string, ReadonlySet<string>>) {
    	super();
    	this.entityProperties = entityProperties;
    	this.errors = [];
    }

    visitField(context: { readonly entity: { readonly text: string }, readonly property: { readonly text: string } }) {
    	const properties = this.entityProperties.get(context.entity.text);
    	if (properties == null) {
    		this.errors.push(new InvalidEntityError(context.entity.text));

    	} else if (!properties.has(context.property.text)) {
    		this.errors.push(new InvalidEntityPropertyError(context.entity.text, context.property.text));
    	}

    	// traversing this part of the tree is not needed
    }

    visitNonEmptyNumberList() { // eslint-disable-line class-methods-use-this
    	// traversing this part of the tree is not needed
    }

    visitNonEmptyStringList() { // eslint-disable-line class-methods-use-this
    	// traversing this part of the tree is not needed
    }

    public getValidationErrors(): readonly Error[] {
    	return this.errors;
    }
}

export default EntityPropertiesValidationVisitor;
export {
	InvalidEntityError,
	InvalidEntityPropertyError,
};
