import styled from 'styled-components/macro';
import { Collapse, Divider, Row } from 'antd';

const FormItem = styled.div`
	width: calc(50% - 6px);
`;

const FormItemsWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const CustomRow = styled(Row)`
	margin-top: 10px;

	&:first-child {
		margin-top: 0;
	}
`;

const CustomDivider = styled(Divider)`
	margin: 0;
`;

const FormBlock = styled.div`
	padding: 20px 0;
`;

const Flex = styled.div`
	display: flex;
	gap: 15px;
	flex-direction: column;
	margin-top: 20px;
`;

const FeaturesRow = styled(Row)`
	padding-bottom: 20px;
`;

const CustomCollapse = styled(Collapse)`
	.ant-collapse-content-box {
		padding: 0;
	}
	.ant-collapse-header {
		padding-left: 28px !important;
		display: inline-block !important;

		.ant-collapse-arrow {
			left: 0 !important;
		}
	}
	.ant-collapse-item {
		border: 0 !important;
	}
`;

export {
	Flex,
	FormBlock,
	CustomRow,
	FormItemsWrap,
	FormItem,
	CustomDivider,
	FeaturesRow,
	CustomCollapse,
};
