import React from 'react';
import { Form, InputNumber, Switch } from 'antd';
import { Trans } from '@lingui/macro';
import { MenuItemContainer } from '../MenuItemContainer';
import { FieldsProps } from '../types';

export const ReviewPositionFields: React.FC<FieldsProps> = ({
	isLoading,
	getFieldDecorator,
	defaultValues,
}) => (
	<>
		<MenuItemContainer
			labelTranslation={<Trans id="REVIEWS_IS_PRIORITIZED" />}
			isLoading={isLoading}
		>
			<Form.Item>
				{getFieldDecorator('isPrioritized', {
					valuePropName: 'checked',
					initialValue: defaultValues.isPrioritized,
					rules: [
						{
							required: true,
							message: <Trans id="ERROR_REQUIRED_FIELD" />,
						},
					],
				})(<Switch />)}
			</Form.Item>
		</MenuItemContainer>
		<MenuItemContainer
			labelTranslation={<Trans id="REVIEWS_ORDERING_NUMBER" />}
			isLoading={isLoading}
		>
			<Form.Item>
				{getFieldDecorator('orderingNumber', {
					initialValue: defaultValues.orderingNumber,
					validateTrigger: 'onBlur',
					normalize: (value: string) => value === "" || value == null ? null : Number(value),
					rules: [
						{
							type: 'integer',
							message: <Trans id="ERROR_INTEGER" />,
							transform: (value: string) => Number(value),
						},
					],
				})(
					<InputNumber
						step={1}
						max={Number.MAX_SAFE_INTEGER}
						min={Number.MIN_SAFE_INTEGER}
					/>,
				)}
			</Form.Item>
		</MenuItemContainer>
	</>
);
