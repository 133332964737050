import React from 'react';
import { connect } from 'react-redux';
import { Dispatch as RDispatch } from 'redux';
import { Get } from 'restful-react';
import { Dispatch } from 'store';
import Cookies from 'js-cookie';

const mapDispatch = (dispatch: RDispatch) => ({
	setUser: (dispatch as Dispatch).user.setUser,
	setIsUserLoading: (dispatch as Dispatch).user.setIsUserLoading,
});

type UserProviderProps = ReturnType<typeof mapDispatch> & {
	children: React.ReactNode;
};

const UserProvider = ({ children, setUser, setIsUserLoading }: UserProviderProps) => {
	const accessToken = Cookies.get('access_token');

	if (!accessToken) {
		return <>{children}</>;
	}

	return (
		<Get path="/api/me">
			{(data, { loading }) => {
				setIsUserLoading(loading);
				setUser(data);

				return children;
			}}
		</Get>
	);
};

export default connect(() => ({}), mapDispatch)(UserProvider);
