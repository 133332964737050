import React from 'react';
import ReactDOM from 'react-dom';
import { trimEnd } from 'lodash';
import Cookies from 'js-cookie';
import { notification } from 'antd';
import createFetch from 'utils/autoRefreshTokenFetch';

import App from './App';
import sentry from './utils/sentry';

const createRefreshRequest = () => {
	return new Request(`${trimEnd(process.env.REACT_APP_API_URI, '/')}/oauth/v2/token`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			grant_type: 'refresh_token',
			refresh_token: Cookies.get('refresh_token'),
			client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
			client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
		}),
	});
};

const onRefreshResponse = async (response: Response, originalRequest: Request) => {
	const data: {
		access_token?: string;
		refresh_token?: string;
	} = await response.json();

	data.access_token && Cookies.set('access_token', data.access_token);
	data.refresh_token && Cookies.set('refresh_token', data.refresh_token);

	return new Request(originalRequest, {
		headers: {
			Authorization: `Bearer ${data.access_token || ''}`,
		},
	});
};

sentry();

notification.config({
	duration: 5,
});

window.fetch = createFetch(createRefreshRequest, onRefreshResponse);

ReactDOM.render(<App />, document.getElementById('root'));
