import React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'antd';
import { Trans } from '@lingui/macro';
import sentry from 'utils/sentry';

import Navigation from './Navigation';

interface ErrorBoundaryState {
	readonly error: Error | null;
}

const { captureException } = sentry();

const Layout = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
`;

const Container = styled.div`
	max-width: 1000px;
	width: 100%;
	margin: 100px auto 0;
`;

const ErrorMessage = styled.div`
	font-size: 18px;
	padding: 20px 0;
`;

class ErrorBoundary extends React.Component<Record<string, any>, ErrorBoundaryState> {
	constructor(props: Record<string, any>) {
		super(props);

		this.state = {
			error: null,
		};
	}

	static getDerivedStateFromError(error: Error) {
		return { error };
	}

	componentDidCatch (error: Error) {
		captureException(error);
	}

	render () {
		const {error} = this.state;
		if (error == null) {
			return this.props.children;
		}
		return (
			<>
				<Navigation />
				<Layout>
					<Container>
						<Typography.Title>
							<Trans id="ERROR_COMPONENT_TITLE" />
						</Typography.Title>
						<ErrorMessage>
							{error.message}
						</ErrorMessage>
						{error.stack && (
							<details>
								<code>
									{error.stack}
								</code>
							</details>
						)}
					</Container>
				</Layout>
			</>
		);
	}
}

export default ErrorBoundary;
