import { Typography, Icon, Tabs } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { CarDetailContent } from '../../CarDetailContent';

const TabContent = styled(CarDetailContent)`
	width: 1240px;
	padding: 20px 0;
`;

export default TabContent;
