import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components/macro';
import AliceCarousel from 'react-alice-carousel';
import { get } from 'lodash';
import { CarImage } from 'types/car';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { getResizedImageUrl } from 'utils/images';

type GalleryPropsType = {
	images: CarImage[] | null | undefined;
	className?: string;
};

const GalleryContainer = styled.div`
	position: relative;
	padding: 1rem;
`;

type SlideButtonPropsType = ButtonProps & {
	position: 'left' | 'right';
};

const SlideButton = styled(Button)<SlideButtonPropsType>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	${({ position }) => {
		if (position === 'left') {
			return css`
				left: 20px;
			`;
		}

		if (position === 'right') {
			return css`
				right: 20px;
			`;
		}
		return '';
	}}
`;

const Image = styled.img`
	margin: 0 auto;
	display: block;
`;

const Gallery: FunctionComponent<GalleryPropsType> = ({ images, className }) => {
	const carouselRef = React.useRef<
		AliceCarousel & { slideNext: () => void; slidePrev: () => void }
			>(null);

	if (!images || images.length === 0) {
		return null;
	}

	return (
		<GalleryContainer className={className}>
			<AliceCarousel
				dotsDisabled
				buttonsDisabled
				ref={carouselRef}
				responsive={{
					0: {
						items: 1,
					},
					450: {
						items: 2,
					},
					900: {
						items: 3,
					},
					1350: {
						items: 4,
					},
				}}
				stagePadding={{
					paddingLeft: 0,
					paddingRight: 0,
				}}
				items={
					images
						? images.map(
							(item) =>
								get(item, 'path') && (
									<Image
										key={item.id}
										src={getResizedImageUrl(item.path, 400, 300)}
									/>
								),
						  )
						: undefined
				}
			/>
			<SlideButton
				type="primary"
				shape="circle"
				icon="left"
				position="left"
				onClick={() => {
					if (carouselRef !== null && carouselRef.current !== null) {
						carouselRef.current.slidePrev();
					}
				}}
			/>
			<SlideButton
				type="primary"
				shape="circle"
				icon="right"
				position="right"
				onClick={() => {
					if (carouselRef !== null && carouselRef.current !== null) {
						carouselRef.current.slideNext();
					}
				}}
			/>
		</GalleryContainer>
	);
};

export default Gallery;
