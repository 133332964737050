import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { MY_CARS_TABLE_PAGE_SIZE } from 'constants/index';
import { take } from 'lodash';
import { AdvertisedCarTypeStringId } from 'types/car';

type CarCountAndData = {
	carCount: number;
	carData: AdvertisedCarTypeStringId[];
};

/**
 * Custom hook to modify car count and data array length by limit and page from query param in URL
 */
export const useCarCountAndData = (
	carTotalCount: number,
	carDataWithPrices: AdvertisedCarTypeStringId[],
): CarCountAndData => {
	const location = useLocation();
	const { page, limit } = queryString.parse(location.search, { parseNumbers: true });

	const carCount = useMemo(() => {
		if (typeof limit === 'number' && carTotalCount > limit) {
			return limit;
		}
		return carTotalCount;
	}, [limit, carTotalCount]);

	const carData = useMemo(() => {
		// if there's a limit and we're on the last page, don't show cars above the set limit
		if (
			typeof limit === 'number' &&
			typeof page === 'number' &&
			limit < page * MY_CARS_TABLE_PAGE_SIZE
		) {
			return take(carDataWithPrices, limit % MY_CARS_TABLE_PAGE_SIZE);
		}
		return carDataWithPrices;
	}, [limit, page, carDataWithPrices]);

	return { carCount, carData };
};
