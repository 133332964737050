/* eslint-disable react/display-name */
import React, { FunctionComponent, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Table } from 'antd';
import { DetailInfoModal, DetailInfoModalType } from '../../../../AdvertisingRules/components/DetailInfoModal';
import { theme } from '../../../../../stylesheets/theme';
import { AdvertisingRule } from 'types/advertisingRules';
import { AdvertisingRuleCarQueryCell, AdvertisingRuleNameCell, AdvertisingRuleUserQueryCell } from 'components/BlacklistCells';
import { Link, useParams } from 'react-router-dom';

type getTableColumnsParams = {
	readonly onClick: (bodyText: string, titleText: DetailInfoModalType) => void;
	readonly carId: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTableColumns = ({
	onClick,
	carId,
}: getTableColumnsParams) => [
	{
		title: <Trans id="ADVERTISING_RULE_TABLE_HEAD_NAME" />,
		dataIndex: 'name',
		render: (name: string, row: any) =>
			<AdvertisingRuleNameCell content={name} row={row} onClick={onClick} />,
		width: 200,
	},
	{
		title: <Trans id="ADVERTISING_RULE_CAR_QUERY" />,
		dataIndex: 'advertised_car_query',
		render: (query: string) =>
			<AdvertisingRuleCarQueryCell content={query} onClick={onClick} />,
		width: 200,
	},
	{
		title: <Trans id="ADVERTISING_RULE_USER_QUERY" />,
		dataIndex: 'user_query',
		render: (query: string) =>
			<AdvertisingRuleUserQueryCell content={query} onClick={onClick} />,
		width: 200,
	},
	{
		title: <Trans id="ADVERTISING_RULE_TABLE_HEAD_ACTIVE" />,
		dataIndex: 'active',
		render: (isActive: boolean) => {
			return (
				isActive
					? <span style={{ color: theme.colors.SUCCES_GREEN }}><Trans id="ADVERTISING_RULE_ACTIVE" /></span>
					: <Trans id="ADVERTISING_RULE_INACTIVE" />
			);
		},
		width: 150,
	},
	{
		title: <Trans id="ADVERTISING_RULE_TABLE_HEAD_ACTION" />,
		dataIndex: 'controls',
		render: (_: any, row: any) => {
			return (
				<Link
					to={{
						pathname: `/advertising-rule-edit/${row.id}`,
						state: { carId },
					}}
				>
					<Trans id="CAR_DETAIL_HEADER_CAR_CHECK_SHOW" />
				</Link>
			);
		},
		width: 200,
	},
];

interface BlacklistTableProps {
	readonly data: AdvertisingRule[];
}

const BlacklistTable: FunctionComponent<BlacklistTableProps> = ({ data }) => {
	const [isDetailInfoModalVisible, setIsDetailInfoModalVisible] = useState(false);
	const [detailInfoModalBodyText, setDetailInfoModalBodyText] = useState('');
	const [detailInfoModalType, setDetailInfoModalType] = useState(DetailInfoModalType.DESCRIPTION);

	const onClick = (bodyText: string, type: DetailInfoModalType) => {
		setDetailInfoModalBodyText(bodyText);
		setDetailInfoModalType(type);
		setIsDetailInfoModalVisible(true);
	};
	const onCancel = () => {
		setIsDetailInfoModalVisible(false);
	};
	const { id: carId } = useParams();

	return (
		<>
			<Table
				data-testid="Car-detail-advertising-rules-table"
				columns={getTableColumns({
					onClick,
					carId,
				})}
				dataSource={data}
				scroll={{ x: 1080 }}
				rowKey={({ id }: any) => id}
				size="middle"
				pagination={data.length < 20 ? false : undefined}
			/>
			<DetailInfoModal
				isModalOpened={isDetailInfoModalVisible}
				onCancel={onCancel}
				type={detailInfoModalType}
				bodyText={detailInfoModalBodyText}
			/>
		</>
	);
};

export default BlacklistTable;
