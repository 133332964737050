import { MY_CARS_TABLE_PAGE_SIZE } from 'constants/index';

import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components/macro';
import Section from 'components/Section';
import { Table } from 'antd';
import { get } from 'lodash';
import { useCarData } from 'hooks/useCarData';
import { useCarCountAndData } from 'hooks/useCarCountAndData';
import { TableSearchHeader } from 'components/TableSearchHeader';

import { getTableColumns } from './TableColumns';
import { CarTableType } from '../types';
import parseQuery from 'utils/parseQuery';
import queryString from 'query-string';

// #region styles

const TableSection = styled(Section)`
	margin-top: 30px;
`;

const CustomTable = styled(Table)`
	.ant-table-row td {
		padding: 5px 10px;
		font-size: 12px;
	}
`;

const CarTable: FunctionComponent<CarTableType> = ({
	searchQuery,
	activeFilters,
	handleFilterSubmit,
	handleSearch,
}) => {
	const { isLoading, carCount: carTotalCount, carDataWithPrices, fetchCarData } = useCarData({
		carQuery: `/api/carvago-admin/listedcars`,
		lazy: false,
	});

	useEffect(() => {
		const body = parseQuery(queryString.parse((searchQuery as unknown) as string));
		body.limit = MY_CARS_TABLE_PAGE_SIZE;
		fetchCarData(body);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchQuery]);

	const { carCount, carData } = useCarCountAndData(carTotalCount, carDataWithPrices);

	return (
		<>
			<TableSearchHeader
				placeholderTranslationKey="FILTER_SEARCH_PLACEHOLDER"
				handleSearch={handleSearch}
				dataTestId="search_cars_input"
			/>
			<TableSection>
				<CustomTable
					columns={getTableColumns({ isArchive: false })}
					dataSource={carData}
					loading={isLoading}
					scroll={{ x: 1080 }}
					rowKey={({ id }: any) => id}
					pagination={
						carCount >= MY_CARS_TABLE_PAGE_SIZE && {
							current: get(activeFilters, 'page'),
							position: 'both',
							total: carCount,
							pageSize: MY_CARS_TABLE_PAGE_SIZE,
							onChange: (page) => handleFilterSubmit({ ...activeFilters, page }),
							size: 'middle',
						}
					}
					size="middle"
					rowClassName={(record, index: number) => `CarTable-row-${index}`}
				/>
			</TableSection>
		</>
	);
};

export default CarTable;
