import { DEFAULT_TABLE_PAGE_SIZE } from 'constants/index';

import React, { FunctionComponent } from 'react';
import styled from 'styled-components/macro';
import Section from 'components/Section';
import { Table } from 'antd';
import { get } from 'lodash';
import { useCarData } from 'hooks/useCarData';
import { TableSearchHeader } from 'components/TableSearchHeader';

import { getTableColumns } from './TableColumns';
import { CarTableType } from '../types';
import {useCarCountAndData} from "../../../hooks/useCarCountAndData";

// #region styles

const TableSection = styled(Section)`
	margin-top: 30px;
`;

const CustomTable = styled(Table)`
	.ant-table-row td {
		padding: 5px 10px;
		font-size: 12px;
	}
`;

// #endregion

const ArchiveCars: FunctionComponent<CarTableType> = ({
	searchQuery,
	activeFilters,
	handleFilterSubmit,
	handleSearch,
}) => {
	const { isLoading, carCount: carTotalCount, carDataWithPrices } = useCarData({
		carQuery: `/api/carvago-admin/listedcars/archive?${searchQuery}`,
		countQuery: `/api/carvago-admin/listedcars/archive/count?${searchQuery}`,
	});

	const { carCount, carData } = useCarCountAndData(carTotalCount, carDataWithPrices);

	return (
		<>
			<TableSearchHeader
				placeholderTranslationKey="FILTER_SEARCH_PLACEHOLDER"
				handleSearch={handleSearch}
				dataTestId="search_cars_input"
			/>
			<TableSection>
				<CustomTable
					columns={getTableColumns({ isArchive: true })}
					dataSource={carData}
					loading={isLoading}
					scroll={{ x: 1080 }}
					rowKey={({ id }: any) => id}
					pagination={
						carCount >= DEFAULT_TABLE_PAGE_SIZE && {
							current: get(activeFilters, 'page'),
							position: 'both',
							total: carCount,
							pageSize: DEFAULT_TABLE_PAGE_SIZE,
							onChange: (page) => handleFilterSubmit({ ...activeFilters, page }),
							size: 'middle',
						}
					}
					size="middle"
					rowClassName={(record, index: number) => `Dashboard-row-${index}`}
				/>
			</TableSection>
		</>
	);
};

export default ArchiveCars;
