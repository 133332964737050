import { DropdownOptionType } from 'types/enum';
import { UserDealerOptionType } from '../types/user';

export const MILEAGE_FILTER_LIST: DropdownOptionType[] = [
	{ value: 10000, label: 'od 10 000 km' },
	{ value: 20000, label: 'od 20 000 km' },
	{ value: 30000, label: 'od 30 000 km' },
	{ value: 50000, label: 'od 50 000 km' },
	{ value: 80000, label: 'od 80 000 km' },
	{ value: 130000, label: 'od 130 000 km' },
	{ value: 200000, label: 'od 200 000 km' },
	{ value: 400000, label: 'od 400 000 km' },
];

export const MANUFACTURE_YEAR_FILTER_LIST: DropdownOptionType[] = [
	{ value: 1, label: 'od 1 roku' },
	{ value: 2, label: 'od 2 let' },
	{ value: 3, label: 'od 3 let' },
	{ value: 5, label: 'od 5 let' },
	{ value: 8, label: 'od 8 let' },
	{ value: 13, label: 'od 13 let' },
	{ value: 20, label: 'od 20 let' },
];

export const IMAGE_COUNT_FILTER_LIST: DropdownOptionType[] = [
	{ value: 1, label: 'do 1' },
	{ value: 2, label: 'do 2' },
	{ value: 3, label: 'do 3' },
	{ value: 5, label: 'do 5' },
	{ value: 8, label: 'do 8' },
	{ value: 13, label: 'do 13' },
	{ value: 20, label: 'do 20' },
	{ value: 40, label: 'do 40' },
];

export const RATING_FILTER_LIST: DropdownOptionType[] = [
	{ value: 1, label: 'do 1*' },
	{ value: 2, label: 'do 2*' },
	{ value: 3, label: 'do 3*' },
	{ value: 4, label: 'do 4*' },
	{ value: 5, label: 'do 5*' },
];

export const RATING_COUNT_FILTER_LIST: DropdownOptionType[] = [
	{ value: 1, label: 'do 1 hodn.' },
	{ value: 10, label: 'do 10 hodn.' },
	{ value: 20, label: 'do 20 hodn.' },
	{ value: 30, label: 'do 30 hodn.' },
	{ value: 50, label: 'do 50 hodn.' },
	{ value: 80, label: 'do 80 hodn.' },
	{ value: 130, label: 'do 130 hodn.' },
	{ value: 200, label: 'do 200 hodn.' },
];

export const CAR_COUNT_FILTER_LIST: DropdownOptionType[] = [
	{ value: 10, label: 'do 10' },
	{ value: 20, label: 'do 20' },
	{ value: 30, label: 'do 30' },
	{ value: 50, label: 'do 50' },
	{ value: 80, label: 'do 80' },
	{ value: 130, label: 'do 130' },
	{ value: 200, label: 'do 200' },
	{ value: 400, label: 'do 400' },
];

export const CUSTOMER_OPTIONS: UserDealerOptionType[] = [
	{
		value: true,
		label: 'B2B',
	},
	{
		value: false,
		label: 'B2C',
	},
];
