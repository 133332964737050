export const ROOT_PATHNAME = '/';
export const LOGIN_PATHNAME = '/login';
export const EXTERNAL_CAR_DETAIL_PATHNAME = '/car-detail/n/:id';
export const CAR_DETAIL_PATHNAME = '/car-detail/:id';
export const ADVERTISING_RULES_LISTING_PATHNAME = '/advertising-rules';
export const ADVERTISING_RULE_EDIT_PATHNAME = '/advertising-rule-edit/:id?';
export const WHITELIST_PATHNAME = '/whitelist';
export const WHITELIST_DETAIL_PATHNAME = '/whitelist-detail/:id?';
export const REVIEWS_PATHNAME = '/reviews';
export const REVIEWS_EDIT_PATHNAME = '/reviews-edit/:id?';
