import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'constants/index';

import React, { FC, useEffect } from 'react';
import 'antd/dist/antd.css';
import { I18nProvider } from '@lingui/react';
import { i18n } from "@lingui/core";
import { RestfulProvider, GetDataError } from 'restful-react';
import { Provider as ReduxProvider} from 'react-redux';
import UserProvider from 'components/UserProvider';
import Cookies from 'js-cookie';
import GlobalStyles from 'components/GlobalStyles';
import { ThemeProvider } from 'stylesheets/styled-components';
import { theme } from 'stylesheets/theme';
import store from 'store';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'components/Routes';
import { APIError, AuthErrors } from 'types';
import getResponseErrorData from 'utils/getResponseErrorData';
import ErrorBoundary from 'components/ErrorBoundary';
import { detectLocale, loadTranslations } from './models/locale/utils';

const App: FC = () => {
	useEffect(() => {
		const locale = detectLocale(store);
		store.dispatch.locale.setLocaleEffect(locale);
		loadTranslations(locale);
	}, []);

	const requestOptions = () => {
		const accessToken = Cookies.get('access_token');

		if (!accessToken) {
			return {};
		}

		return {
			headers: {
				Authorization: `Bearer ${accessToken}`,
				'x-locale': DEFAULT_LOCALE || '',
				'X-Currency': DEFAULT_CURRENCY || '',
			},
		};
	};

	const onError = (error: GetDataError<APIError<AuthErrors>>) => {
		const errorData = getResponseErrorData(error);

		if (errorData.error === 'invalid_grant') {
			Cookies.remove('access_token');
			Cookies.remove('refresh_token');

			store.dispatch.user.removeUser();
		}
	};

	return (
		<I18nProvider i18n={i18n}>
			<ErrorBoundary>
				<ReduxProvider store={store}>
					<RestfulProvider
						base={process.env.REACT_APP_API_URI || ''}
						onError={onError}
						requestOptions={requestOptions}
					>
						<UserProvider>
							<ThemeProvider theme={theme}>
								<>
									<BrowserRouter>
										<Routes />
									</BrowserRouter>

									<GlobalStyles />
								</>
							</ThemeProvider>
						</UserProvider>
					</RestfulProvider>
				</ReduxProvider>
			</ErrorBoundary>
		</I18nProvider>
	);
};

export default App;
