import { DEFAULT_CURRENCY } from 'constants/index';

import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { Table, Typography, Switch, notification } from 'antd';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import useFetch from 'hooks/useFetch';
import { PriceHistory } from 'types/priceHistory';
import { AdvertisedCarType } from 'types/car';
import { ExchangeRate } from 'types/exchangeRate';
import { formatPrice } from 'utils/formatters';
import { ColumnProps } from 'antd/lib/table';
import { UserType } from 'types/user';
import { SignedPrice, DynamicType } from 'components/SignedPrice';
import { getValueForField } from 'utils/getValueForField';

import OriginalCurrencyPricePopover from '../../OriginalCurrencyPricePopover';

const UpdatedMail = styled.div`
	max-width: 170px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

type PriceHistoryTablePropsType = {
	car: AdvertisedCarType;
};

type EnrichedPriceHistory = PriceHistory & {
	diff: null | number;
};

type RowToggleStateType = {
	[key: number]: boolean;
};

type PayloadType = {
	id: number;
	hidden: boolean;
};

const getDynamicFromDiff = (diff: number | null): DynamicType => {
	if (diff === null) {
		return 'neutral';
	}

	switch (true) {
		case diff > 0:
			return 'positive';
		case diff < 0:
			return 'negative';
		default:
			return 'neutral';
	}
};

const useRowToggleState = () => {
	const [state, setState] = useState<RowToggleStateType>({});

	const setRowToggleState = (rowId: number, checked: boolean) => {
		setState({
			...state,
			[rowId]: checked,
		});
	};

	const setInitialState = (data: PriceHistory[]) => {
		const toggleData = data.reduce(
			(acc, item) => ({
				...acc,
				[item.id]: !item.hidden,
			}),
			{},
		);

		setState(toggleData);
	};

	// prettier-ignore
	return [state, setRowToggleState, setInitialState] as [
		RowToggleStateType,
		(rowId: number, checked: boolean) => void,
		(data: PriceHistory[]) => void
	];
};

const enrichData = (data: PriceHistory[]): EnrichedPriceHistory[] =>
	data.map((item, index) => {
		const isLastItem = index === data.length - 1;
		if (isLastItem) {
			return {
				...item,
				diff: null,
			};
		}

		const diffValue = data[index].price - data[index + 1].price;

		return {
			...item,
			diff: diffValue,
		};
	});

export const PriceHistoryTable = ({ car }: PriceHistoryTablePropsType) => {
	const carId = car.id;
	const { data: tableData, isLoading } = useFetch<PriceHistory[]>(
		`/api/carvago-admin/listedcars/${carId}/price-history`,
		{ indicators: [car] },
	);
	const { i18n } = useLingui();

	const [rowToggleState, setRowToggleState, setToggleInitialState] = useRowToggleState();
	const [tableDataState, setTableDataState] = useState(enrichData(tableData || []));

	useEffect(() => {
		setTableDataState([...(tableDataState || [])]);
	}, [rowToggleState]);

	useEffect(() => {
		tableData && setTableDataState(enrichData(tableData));
		tableData && setToggleInitialState(tableData);
	}, [tableData]);

	const { fetchData: patch } = useFetch<never, { priceHistoryId: number }, PayloadType>(
		`/api/carvago-admin/listedcars/${carId}/price-history/:priceHistoryId`,
		{ init: { method: 'PATCH' }, lazy: true },
	);

	const exchangeRatesModel = useFetch<ExchangeRate[]>('/api/carvago-admin/exchange-rates');

	const columns: Array<ColumnProps<EnrichedPriceHistory>> = useMemo(
		() => [
			{
				title: <Trans>FINANCE_TABLE_TITLE_CHANGES_ID</Trans>,
				dataIndex: 'id',
				key: 'ID',
				className: 'Price_history_table_price_id',
			},
			{
				title: <Trans>FINANCE_TABLE_TITLE_TIME_OF_CHANGE</Trans>,
				dataIndex: 'created_at',
				key: 'time_of_change',
				render: (data: string) => (
					<span data-testid="Price_history_table_time_change">
						{moment(data).format('D.M.Y, H:mm')}
					</span>
				),
			},
			{
				title: <Trans>FINANCE_TABLE_TITLE_UPDATED_BY</Trans>,
				dataIndex: 'updated_by',
				key: 'updated_by',
				render: (data?: UserType) => (
					<UpdatedMail
						data-testid="Price_history_table_time_updated_by"
						title={data?.email || 'Revolt'}
					>
						{data?.email || 'Revolt'}
					</UpdatedMail>
				),
			},
			{
				title: <Trans>FINANCE_TABLE_TITLE_ORIGINAL_CURRENCY_PRICE</Trans>,
				key: 'original_price',
				render: (data: EnrichedPriceHistory) => (
					<OriginalCurrencyPricePopover row={data} exchangeRatesModel={exchangeRatesModel}>
						<span data-testid="Dealer_price_history_table_original_price">
							{data.original_price_currency.name === DEFAULT_CURRENCY
								? formatPrice(data.price, data.price_currency.name)
								: formatPrice(data.original_price, data.original_price_currency.name)}
						</span>
					</OriginalCurrencyPricePopover>
				),
			},
			{
				title: <Trans>FINANCE_TABLE_TITLE_NEW_PRICE</Trans>,
				key: 'new_price',
				render: (data: EnrichedPriceHistory) => (
					<div data-testid="Price_history_table_new_price">
						{formatPrice(data?.price, data?.price_currency?.name)}&nbsp;
						<SignedPrice dynamic={getDynamicFromDiff(data?.diff)}>
							({formatPrice(data?.diff || 0, data?.price_currency?.name)} )
						</SignedPrice>
					</div>
				),
			},
			{
				title: <Trans>FINANCE_TABLE_TITLE_PRICE_WITHOUT_VAT</Trans>,
				key: 'price_without_vat',
				render: (data: EnrichedPriceHistory) => (
					<span data-testid="Price_history_table_price_without_vat">
						{getValueForField(
							data?.price_without_vat &&
								formatPrice(data?.price_without_vat || 0, data?.price_currency.name),
						)}
					</span>
				),
			},
			{
				title: <Trans>FINANCE_TABLE_TITLE_VAT</Trans>,
				dataIndex: 'vat_rate',
				key: 'VAT',
				render: (data: number) => getValueForField(data && `${data} %`),
			},
			{
				title: <Trans>FINANCE_TABLE_TITLE_PRICE_HISTORY_VISIBILITY</Trans>,
				key: 'price_visibility',
				render: (data: EnrichedPriceHistory, _: EnrichedPriceHistory, index: number) => {
					const disabled =
						(Object.keys(rowToggleState).filter((item) => rowToggleState[parseInt(item, 10)])
							.length === 1 &&
							rowToggleState[data.id]) ||
						index === 0;

					return (
						<Switch
							title={disabled ? i18n._(t`FINANCE_TABLE_DISABLED_VISIBILITY_SWITCH`) : ''}
							size="small"
							checked={rowToggleState[data.id]}
							disabled={disabled}
							defaultChecked
							data-testid="Price_history_table_visibility_button"
							onChange={(checked: boolean) => {
								setRowToggleState(data.id, checked);

								patch(
									{ priceHistoryId: data.id },
									{
										id: data.id,
										hidden: !checked,
									},
								).catch((error) => {
									notification.error({
										message: error.message,
									});

									setRowToggleState(data.id, !checked);
								});
							}}
						/>
					);
				},
			},
		],
		[car, exchangeRatesModel],
	);

	return (
		<>
			<Typography.Title level={3}>
				<Trans>PRICE_HISTORY_TABLE_TITLE</Trans>
			</Typography.Title>
			<Table
				rowKey={(record: any) => record?.id}
				bordered
				onRow={(_, index) => ({ 'data-testid': `Price_history_table_row_${index + 1}` })}
				loading={isLoading}
				columns={columns}
				dataSource={tableDataState || []}
				pagination={false}
				scroll={{ x: 700 }}
				size="small"
			/>
		</>
	);
};
