import { DEFAULT_CURRENCY, DEFAULT_LOCALE } from 'constants/index';

import { useState, useEffect, useCallback } from 'react';
import { trimEnd, trimStart, get } from 'lodash';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { throwFetchError, UnauthenticatedError } from 'utils/error';

interface IParams {
	[key: string]: any;
}

export type ModelType<
	TResponseData = any,
	TParams extends IParams = IParams,
	TBody extends IParams = IParams
> = {
	response: Response;
	data: TResponseData | null;
	error: any;
	isLoading: boolean;
	fetchData: (params?: TParams, body?: TBody) => Promise<TResponseData>;
	totalCount: number;
};

interface IFetchOptions {
	init?: RequestInit;
	indicators?: any[];
	lazy?: boolean;
	body?: IParams;
}

const useFetch = <
	TResponseData = any,
	TParams extends IParams = IParams,
	TBody extends IParams = IParams
>(
		input: string,
		{ init, indicators, lazy = false, body }: IFetchOptions = { lazy: false },
	): ModelType<TResponseData, TParams, TBody> => {
	const [response, setResponse] = useState<any>(null);
	const [data, setData] = useState<TResponseData | null>(null);
	const [totalCount, settotalCount] = useState<number>(0);
	const [resError, setResError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const normalizedApiUrl = trimEnd(process.env.REACT_APP_API_URI, '/');
	const normalizedInput = `/${trimStart(input, '/')}`;

	const headers: any = {};

	const accessToken = Cookies.get('access_token');

	if (accessToken) {
		headers.Authorization = `Bearer ${accessToken}`;
	}

	const currency = Cookies.get('currency') || DEFAULT_CURRENCY;
	if (currency) {
		headers['x-currency'] = currency;
	}

	const locale = Cookies.get('locale') || DEFAULT_LOCALE;
	if (locale) {
		headers['x-locale'] = locale;
	}

	const fetchData = useCallback(async (params?: TParams, body?: TBody): Promise<TResponseData> => {
		setIsLoading(true);

		const finalParams: IParams = params || {};
		const finalInput = Object.keys(finalParams).reduce((acc, param) => {
			const regex = new RegExp(`:${param}(/|$)`, 'g');
			let res = acc;

			if (acc.match(regex)) {
				res = acc.replace(regex, `${finalParams[param]}$1`);
				delete finalParams[param];
			}

			return res;
		}, normalizedInput);

		const query = queryString.stringify(finalParams, {arrayFormat: 'bracket'});
		const search = Object.keys(finalParams).length
			? `${finalInput.indexOf('?') === -1 ? '?' : '&'}${query}`
			: '';

		try {
			const res = await fetch(normalizedApiUrl + finalInput + search, {
				...init,
				...(body != null
					? {
						body: new Blob([JSON.stringify(body)], { type: 'application/json' }),
					}
					: {}),
				headers: { ...headers, ...get(init, 'headers', {}) },
			});

			if (!res.ok) {
				if (res.status === 401) {
					throw new UnauthenticatedError();
				}
				await throwFetchError(res);
			}

			let responseData;

			if (res.headers.get('content-type') === 'application/json') {
				responseData = await res.json();
			} else {
				responseData = await res.text();
				responseData = responseData.length ? responseData : null;
			}

			const headerCount = res.headers.get('x-total');
			settotalCount(headerCount ? Number(headerCount) : 0);

			setResponse(res);
			setData(responseData);

			return responseData;
		} catch (error) {
			setResError(error);

			throw error;
		} finally {
			setIsLoading(false);
		}
	}, indicators ?? [input]);

	useEffect(() => {
		if (!lazy) {
			fetchData();
		}
	}, indicators ?? [input, body]);
	return { response, data, error: resError, isLoading, fetchData, totalCount };
};

const someLoading = (models: readonly ModelType[]): boolean => models.some((model: ModelType): boolean => model.isLoading);

const someError = (models: readonly ModelType[]): boolean => models.some((model: ModelType): boolean => model.error != null);

export default useFetch;
export {
	someLoading,
	someError,
};
