import React, { FunctionComponent } from 'react';
import { Table } from 'antd';
import { Trans } from '@lingui/react';
import { formatPrice } from 'utils/formatters';
import { CountryType } from 'types/country';
import { useSelector } from 'react-redux';
import { selectReferenceData } from 'selectors/referenceData';

const columns = [
	{
		title: <Trans id="CAR_PRICE_TABLE_PREVIEW_CURRENCY_TITLE" />,
		dataIndex: 'preview_currency',
	},
	{
		title: <Trans id="CAR_PRICE_TABLE_PREVIEW_VAT_TITLE" />,
		dataIndex: 'preview_vat',
	},
	{
		title: <Trans id="CAR_PRICE_TABLE_PREVIEW_MARGIN_TITLE" />,
		dataIndex: 'preview_margin',
	},
	{
		title: <Trans id="CAR_PRICE_TABLE_PREVIEW_WITHOUT_VAT_TITLE" />,
		dataIndex: 'preview_without_vat',
	},
	{
		title: <Trans id="CAR_PRICE_TABLE_PREVIEW_PRICE_TITLE" />,
		dataIndex: 'preview_price',
	},
];

interface SuggestedPrice {
	readonly price_information: {
		readonly price: number;
		readonly price_without_vat: number | null;
		readonly vat_reclaimable: boolean;
		readonly vat_rate: number | null;
		readonly currency: {
			readonly id: number;
			readonly name: string;
		};
		readonly margin_data: {
			readonly margin_price: number;
		}
		readonly price_data: {
			readonly price: number;
			readonly price_without_vat: number;
			readonly vat_rate: number;
		}
		readonly nice_price_data: {
			readonly price: number;
			readonly price_without_vat: number;
		}
	}
	readonly country: {
		readonly id: number;
	}
}

interface CarPricePreviewProps {
	readonly suggestedPrices: SuggestedPrice[];
}

const CarPricePreview: FunctionComponent<CarPricePreviewProps> = ({ suggestedPrices }) => {
	const countries = useSelector(selectReferenceData).allowedCountryOptions as CountryType[];

	if (!suggestedPrices) {
		return null;
	}

	const rows = suggestedPrices.map((item: SuggestedPrice) => {
		const countryItem = item.price_information;
		const currencyName = countryItem.currency.name;
		const country = countries.find((country) => item.country.id === country.value);
		return {
			preview_currency: `${country?.label} (${currencyName})`,
			preview_vat: countryItem.vat_reclaimable ? `${countryItem.price_data.vat_rate} %` : '-',
			preview_margin: formatPrice(
				countryItem.margin_data.margin_price,
				currencyName,
			),
			preview_without_vat: countryItem.vat_reclaimable
				? formatPrice(
					countryItem.nice_price_data.price_without_vat,
					currencyName,
				)
				: '-',
			preview_price: formatPrice(
				countryItem.nice_price_data.price,
				currencyName,
			),
		};
	});

	return (
		<Table
			dataSource={rows}
			columns={columns}
			size="small"
			pagination={false}
			loading={false}
			bordered
			rowKey={(record) => record.preview_currency}
		/>
	);
};

export default CarPricePreview;
