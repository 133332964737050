import apiFetch from '../../utils/apiFetch';
import { Review, TranslatedReviewsProps } from './types';

export const fetchTotalVisibleReviewsPerCountry = async (id: number): Promise<number> => {
	const response = await apiFetch(
		`/api/carvago-admin/reviews?limit=1&homepage=true&country=${id}&is-visible=true`,
	);
	return parseInt(response.headers.get('x-total') ?? '0', 10);
};

export const toggleReviewHomepageVisibility = async (data: Review): Promise<Response> => {
	const putObject = getPutObject(data);

	return await apiFetch(`/api/carvago-admin/reviews/${data.id}`, {
		method: 'PUT',
		body: JSON.stringify({
			...putObject,
			homepage: !data.homepage,
		}),
		headers: {
			'Content-Type': 'application/json',
		},
	});
};

const getPutObject = (data : Review) => {
	const { layout, customer_name, country, language, rating,
		recommends_carvago, review, answer, model_family, is_visible,verified_buyer, review_source, review_url,year_of_manufacture,
		review_translation, homepage,
	} = data;

	return {
		layout,
		customer_name,
		country: {
			id: country.id,
		},
		language: {
			id: language.id,
		},
		rating,
		recommends_carvago,
		review,
		answer,
		model_family: model_family ? {
			id: model_family.id,
		} : null,
		is_visible,
		verified_buyer: verified_buyer,
		review_source: review_source,
		review_url: review_url,
		year_of_manufacture: year_of_manufacture,
		review_translation: review_translation && getTranslations(review_translation),
		homepage,
	};
};

const getTranslations = (translations: TranslatedReviewsProps[]) => {
	translations.map(({ id, language, review, answer }) => {

		return {
			id,
			language: {
				id: language.id,
			},
			review,
			answer,
		};
	});
};
