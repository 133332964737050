import apiFetch from '../../../utils/apiFetch';
import { notification } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { Review } from '../../Reviews/types';
import { History } from 'history';
import { PhotoProps } from '../types';
import { UploadFile } from 'antd/es/upload/interface';

type SetLoading = Dispatch<SetStateAction<boolean>>

export const handleUploadCarReviewImage = async (
	fileList: UploadFile<PhotoProps>[] | PhotoProps[],
	setFileLoading: SetLoading,
	data?: Review,
	history?: History,
) => {
	if (fileList.length === 0) {
		history?.push(`/reviews`);
		return;
	}
	const formData = new FormData();
	fileList.forEach((file: any) => {
		return formData.append('file', file);
	});

	if (formData.get('file') === 'undefined') { // it returns undefined in string
		history?.push(`/reviews`);
		return;
	}

	try {
		if (data?.id) {
			const newPhotoId = fileList[0]?.uid;
			if (data?.photo?.id !== newPhotoId) {
				setFileLoading(true);
				await apiFetch(`/api/carvago-admin/reviews/${data?.id}/photo`, {
					method: 'POST',
					body: formData,
				});
				history?.push(`/reviews`);
			}
		}
	}
	catch (error) {
		notification.error({
			message: error.message,
		});
	}
};
