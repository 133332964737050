export const theme = {
	colors: {
		PRIMARY: '#3E47DD',
		LIGHT_BLUE: '#1890ff',
		BLUE: '#096dd9',
		SUCCES_GREEN: '#52c41a',
		GREEN: '#0a9707',
		RED: '#f5222d',
		ORANGE: '#FAAD14',
		DARK_ORANGE: '#7a4b05',
		GRAY: '#6F7887',
		LIGHT_GRAY: '#BFBFBF',
		BORDER_GRAY: '#e8e8e8',
		BACKGROUND_LIGHT_GRAY: '#f0f2f5',
		BACKGROUND_DARK_GRAY: '#E4E6E9',
		BACKGROUND_GRAY: '#f6f6f7',
		BACKGROUND_LIGHT_BLUE: '#dae8f6',
	} as const,
} as const;

export type ThemeType = {
	colors: {
		[key in keyof typeof theme.colors]: string;
	};
};

export type ThemeExportType = {
	theme: ThemeType;
};
