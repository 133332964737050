import React, { FC } from 'react';
import apiFetch from 'utils/apiFetch';
import { StyledUploadCar } from '../styled';
import { handleValidateReviewCarImageProperties } from './handleValidateReviewCarImageProperties';
import { generateReviewCarImageThumbnail } from './generateReviewCarImageThumbnail';
import { UploadCardProps } from '../types';
import { Button, Upload } from 'antd';
import { RcCustomRequestOptions, RcFile } from 'antd/lib/upload/interface';

export const UploadCar: FC<UploadCardProps> = ({ data, fileList, setFileList, apiLoading }) => {
	const handleRemove = async () => {
		if (data?.id) {
			await apiFetch(`/api/carvago-admin/reviews/${data.id}/photo`, {
				method: 'DELETE',
			});
		}
		setFileList(() => []);
	};

	const handleBeforeUpload = async (file: RcFile) => {
		const isValid = await handleValidateReviewCarImageProperties(file);
		if (!isValid) {
			setFileList((state: RcFile[]) => [...state]);
			return false;
		}
		setFileList((state: RcFile[]) => [...state, file]);

		return false;
	};

	//  we use andt's <Upload/> component simply as file selector
	// this will stop the file to be uploaded automatically to nowhere
	const dummyRequest = ({ onSuccess, file }: RcCustomRequestOptions) => {
		setTimeout(() => {
			onSuccess({ response: 'ok' }, file);
		}, 0);
	};

	return (
		<StyledUploadCar>
			<Upload
				customRequest={dummyRequest}
				// @ts-ignore
				beforeUpload={handleBeforeUpload}
				accept="image/png, image/jpeg, image/webp"
				onPreview={generateReviewCarImageThumbnail}
				fileList={fileList}
				onRemove={handleRemove}
				listType="picture"
			>
				{fileList?.length === 0 &&
					(apiLoading ? 'Loading' : <Button>+ Upload image</Button>)}
			</Upload>
		</StyledUploadCar>
	);
};
