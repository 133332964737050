import React, { useEffect, FunctionComponent, useState, useRef } from 'react';
import { get, isEmpty } from 'lodash';
import { Col } from 'antd';
import { FiltersType } from 'types/filters';
import apiFetch from 'utils/apiFetch';
import { MakeAndModelItem } from './MakeAndModelItem';
import { WrappedFormUtils } from 'antd/lib/form/Form';

type Values = {
	[key: string]: any;
};

type MakeAndModelProps = {
	initialValues: FiltersType;
	form: WrappedFormUtils<Values>;
};

type MakeAndModelFamily = {
	makeId: string;
	modelId: string;
};

type MakeAndModelFamilyCollection = Record<string, string[]>;

const getMakeForModelFamily = (modelId: string | number) =>
	apiFetch(`/api/model-families/${modelId}`)
		.then((response) => response.json())
		.then((response) => ({ makeId: response?.make?.id, modelId } as MakeAndModelFamily));

const getMakeForModelFamilyGroup = (modelGroupId: string | number) =>
	apiFetch(`/api/model-family-groups/${modelGroupId}`)
		.then((response) => response.json())
		.then(
			(response) =>
				({
					makeId: response?.make?.id,
					modelId: `group_${modelGroupId}`,
				} as MakeAndModelFamily),
		);

const collectModelFamiliesPerMake = (
	responses: Array<MakeAndModelFamily>,
): MakeAndModelFamilyCollection =>
	responses.reduce((result, modelFamily) => {
		const makeId = modelFamily.makeId;

		if (!result[makeId]) {
			result[makeId] = [modelFamily.modelId as string];
		} else {
			result[makeId].push(modelFamily.modelId as string);
		}

		return result;
	}, {} as MakeAndModelFamilyCollection);

export const MakeAndModel: FunctionComponent<MakeAndModelProps> = ({
	form,
	initialValues = {},
}) => {
	const { setFieldsValue } = form;
	const lastKey = useRef(0);
	const [makersCount, setMakersCount] = useState([{ name: `id-${lastKey.current}` }]);

	useEffect(() => {
		const modelFamilies = get(initialValues, 'model-family', []) as string[];
		const modelFamilyGroups = get(initialValues, 'model-family-group', []) as string[];
		const modelPercentage = get(initialValues, 'model-percentage', []);

		if ((!isEmpty(modelFamilyGroups) || !isEmpty(modelFamilies)) && !initialValues.make) {
			const modelPromises = [
				...modelFamilyGroups.map((id) => getMakeForModelFamilyGroup(id as string)),
				...modelFamilies.map((id) => getMakeForModelFamily(id as string)),
			];

			Promise.all(modelPromises || [])
				.then(collectModelFamiliesPerMake)
				.then((collectedModelFamilies) => {
					const makeIds = Object.keys(collectedModelFamilies);
					const makeModelFields = makeIds.map((id, index) => ({ name: `id-${index}` }));

					const makeModelValues = makeIds.reduce(
						(result, id, index) => ({
							...result,
							[`make-id-${index}`]: [Number(id)],
							[`model-family-make-id-${index}`]: collectedModelFamilies[id],
							[`model-percentage-id-${index}`]: modelPercentage[index],
						}),
						{},
					);

					setMakersCount(makeModelFields);
					setFieldsValue(makeModelValues);
				});
		}
	}, []);

	return (
		<Col span={8}>
			{makersCount.map((data, index) => {
				return (
					<MakeAndModelItem
						key={data.name}
						name={data.name}
						form={form}
						showRemove={makersCount.length > 1}
						showAdd={index === makersCount.length - 1}
						onAddClick={() =>
							setMakersCount((makers) => [
								...makers,
								{ name: `id-${++lastKey.current}` },
							])
						}
						onRemoveClick={() =>
							setMakersCount((makers) =>
								makers.filter(({ name }) => !name.startsWith(data.name)),
							)
						}
					/>
				);
			})}
		</Col>
	);
};
