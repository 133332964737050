import React from 'react';
import { Input, Form, Switch, Tooltip } from 'antd';
import { Trans } from '@lingui/macro';
import { MenuItemContainer } from '../MenuItemContainer';
import { FieldsProps, ReviewProps, FormValues } from '../types';
import { Wrapper, Language, Label, TranslationsLabel, InputWrap } from '../styled';

export const ReviewAboutFields: React.FC<FieldsProps> = ({
	isLoading,
	getFieldDecorator,
	defaultValues,
}) => {
	const requiredReviewData = (defaultValues: FormValues) =>
		defaultValues.translations?.map((translation: ReviewProps) => ({
			review: translation.review,
			id: translation.id,
			language: translation.language.id,
			languageName: translation.language.name,
			answer: translation.answer,
		}));

	const translations = defaultValues.translations;
	const isTranslations = translations && translations.length > 0;

	return (
		<>
			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_LABEL_RECOMMENDS" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('recommendsCarvago', {
						valuePropName: 'checked',
						initialValue: defaultValues.recommendsCarvago,
					})(<Switch />)}
				</Form.Item>
			</MenuItemContainer>

			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_LABEL_REVIEW_TEXT" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('review', {
						initialValue: defaultValues.review,
						rules: [
							{
								required: true,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
					})(<Input.TextArea />)}
				</Form.Item>
			</MenuItemContainer>

			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_LABEL_ANSWER" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('answer', {
						initialValue: defaultValues.answer,
					})(<Input />)}
				</Form.Item>
			</MenuItemContainer>

			{isTranslations && (
				<>
					<TranslationsLabel>
						<Trans id="REVIEWS_LABEL_TRANSLATIONS" />
					</TranslationsLabel>
					<Wrapper>
						<Form.Item>
							{defaultValues.translations?.map((translation: ReviewProps) => (
								<React.Fragment key={translation.id}>
									{getFieldDecorator('translations', {
										initialValue: requiredReviewData(defaultValues),
									})(<Language>{translation.language.name}</Language>)}
								</React.Fragment>
							))}
						</Form.Item>

						<InputWrap>
							<Label>
								<Trans id="REVIEWS_LABEL_TRANSLATIONS_REVIEWS" />
							</Label>
							<Form.Item>
								{defaultValues.translations?.map((translation: ReviewProps) => (
									<Tooltip
										key={translation.id}
										placement="top"
										title={translation.review}
									>
										{getFieldDecorator(
											`translations-${translation.language.id}`,
											{
												initialValue: translation.review,
											},
										)(<Input />)}
									</Tooltip>
								))}
							</Form.Item>
						</InputWrap>
						<InputWrap>
							<Label>
								<Trans id="REVIEWS_LABEL_TRANSLATIONS_ANSWERS" />
							</Label>
							<Form.Item>
								{defaultValues.translations?.map((translation: ReviewProps) => {
									return (
										<Tooltip
											key={translation.id}
											placement="top"
											title={translation.answer}
										>
											{getFieldDecorator(
												`translations-answer-${translation.language.id}`,
												{
													initialValue: translation.answer,
												},
											)(<Input />)}
										</Tooltip>
									);
								})}
							</Form.Item>
						</InputWrap>
					</Wrapper>
				</>
			)}

			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_VISIBLE_ON_HOMEPAGE" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('homepage', {
						valuePropName: 'checked',
						initialValue: defaultValues.homepage,
						rules: [
							{
								required: true,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
					})(<Switch />)}
				</Form.Item>
			</MenuItemContainer>
		</>
	);
};
