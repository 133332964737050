import { createModel } from '@rematch/core';
import { localeEffects } from './effects';
// eslint-disable-next-line import/no-cycle
import { RootModel } from '../index';
import { LocaleType } from '../../constants';

export const locale = createModel<RootModel>()({
	state: null as LocaleType | null,
	reducers: {
		setLocale: (state, payload: LocaleType) => {
			return payload;
		},
	},
	effects: localeEffects,
});
