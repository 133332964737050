import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import { Trans } from '@lingui/macro';
import { GenericActionModal } from 'components/modals/GenericActionModal';

type CancelConfirmModalType = {
	isModalOpen: boolean;
	onCancel: () => void;
	onConfirm: () => void;
};

export const CancelConfirmModal: FunctionComponent<CancelConfirmModalType> = ({
	isModalOpen,
	onCancel,
	onConfirm,
}) => (
	<GenericActionModal
		titleText={<Trans id="REVIEWS_TITLE_DELETE_CONFIRM_MODAL_TITLE" />}
		bodyText={<Trans id="REVIEWS_DELETE_CONFIRM_MODAL_CONTENT" />}
		leftButton={
			<Button type="link" onClick={onCancel}>
				<Trans id="REVIEWS_DELETE_CONFIRM_MODAL_BACK_BUTTON" />
			</Button>
		}
		rightButton={
			<Button type="primary" size="large" onClick={onConfirm}>
				<Trans id="REVIEWS_DELETE_CONFIRM_MODAL_CONFIRM_BUTTON" />
			</Button>
		}
		visible={isModalOpen}
		onCancel={onCancel}
	/>
);
