import { ExchangeRate } from 'types/exchangeRate';
import { round } from 'lodash';

const assertSupported = (currencyName: string, rates: ExchangeRate[]) => {
	const rate = rates.find(({ currency }) => {
		return currency.name === currencyName;
	});

	if (rate === undefined) {
		throw Error(`Currency ${currencyName} is not supported.`);
	}

	return rate;
};

const convertToBaseCurrency = (price: number, fromCurrencyName: string, rates: ExchangeRate[]) => {
	const fromCurrencyRate = assertSupported(fromCurrencyName, rates);

	return price / fromCurrencyRate.rate;
};

export const convert = (
	price: number,
	fromCurrencyName: string,
	toCurrencyName: string,
	rates: ExchangeRate[],
) => {
	const toCurrencyRate = assertSupported(toCurrencyName, rates);
	const baseCurrencyPrice = convertToBaseCurrency(price, fromCurrencyName, rates);

	return round(baseCurrencyPrice * toCurrencyRate.rate, 2);
};

export const getExchangeRate = (
	fromCurrencyName: string,
	toCurrencyName: string,
	rates: ExchangeRate[],
) => {
	return convert(1, fromCurrencyName, toCurrencyName, rates);
};
