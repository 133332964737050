import React, { FunctionComponent, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Divider, Modal, Input, Button, Table, Tag, Form } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { t } from '@lingui/macro';
import { Trans, useLingui } from '@lingui/react';
import useFetch from 'hooks/useFetch';

interface RowType {
	readonly id: string;
	readonly key: number;
	readonly match: boolean;
}

const MenuFooter = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

const Content = styled.div`
	padding: 20px 0;
`;

interface AdvertisingRulePreviewModalType {
	readonly form: WrappedFormUtils;
	readonly visible: boolean;
	readonly onCancel: () => void;
	readonly userQuery: string;
	readonly carQuery: string;
}

const parseCarIdsInput = (input: string): readonly string[] => {
	return input
		.split(',')
		.map((carId) => carId.trim())
		.filter((carId) => carId.length > 0);
};

const validateInput = (ids: readonly string[]): boolean => {
	const validated = ids.map((id: string): boolean => {
		return id.match(/^[1-9][0-9]*$/) != null;
	});
	return !validated.includes(false);
};

const renderTag = (text: unknown, record: RowType) => {
	const { match, key } = record;
	const color = match ? 'green' : 'red';
	const status = match ? (
		<Trans id="ADVERTISING_RULE_PREVIEW_MATCH" />
	) : (
		<Trans id="ADVERTISING_RULE_PREVIEW_NO_MATCH" />
	);
	return (
		<Tag color={color} key={key}>
			{status}
		</Tag>
	);
};

const columns = [
	{
		title: <Trans id="ADVERTISING_RULE_PREVIEW_CAR_ID" />,
		dataIndex: 'id',
	},
	{
		title: <Trans id="ADVERTISING_RULE_PREVIEW_STATUS" />,
		dataIndex: 'match',
		render: renderTag,
	},
];

const AdvertisingRulePreviewModal: FunctionComponent<AdvertisingRulePreviewModalType> = ({
	form: { getFieldDecorator },
	visible,
	onCancel,
	userQuery,
	carQuery,
}) => {
	const [inputText, setInputText] = useState('');
	const [dataSource, setDataSource] = useState<RowType[]>([]);
	const [matchCount, setMatchCount] = useState(null);
	const [loading, setLoading] = useState(false);
	const { i18n } = useLingui();
	const { fetchData } = useFetch(`/api/carvago-admin/advertising-rule/preview`, {
		init: {
			method: 'POST',
			body: JSON.stringify({
				advertised_car_query: carQuery,
				user_query: userQuery,
			}),
			headers: {
				'content-type': 'application/json',
			},
		},
		indicators: [carQuery, userQuery],
		lazy: true,
	});

	const validator = useCallback(
		(rule: unknown, value: string, callback: (error?: boolean) => void): boolean => {
			const ids = parseCarIdsInput(value);
			const isValid = validateInput(ids);
			if (isValid) {
				callback();
			} else {
				callback(isValid);
			}
			return isValid;
		},
		[parseCarIdsInput, validateInput],
	);

	const test = useCallback((): void => {
		const ids = parseCarIdsInput(inputText);

		if (!validateInput(ids)) {
			return;
		}
		setLoading(true);
		const params = ids.length > 0 ? { ids } : undefined;
		fetchData(params) // when fetching without params none are appended at the end of url
			.then((response) => {
				const matchingAdvertisedCarsCount = response.matching_advertised_cars_count ?? 0;
				const idDataSource: RowType[] = ids.map((id, index) => {
					const match = response.previewed_advertised_cars_match_list?.[id] ?? false;
					return {
						id,
						key: index,
						match,
					};
				});
				setDataSource(idDataSource);
				setMatchCount(matchingAdvertisedCarsCount);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [parseCarIdsInput, inputText, validateInput, setLoading, fetchData, setDataSource, setMatchCount]);

	useEffect(() => {
		if (visible) {
			test();
		}
	}, [visible]);

	const cancelButton = (
		<Button type="link" onClick={onCancel}>
			<Trans id="ADVERTISING_RULE_CANCEL_CONFIRM_MODAL_BACK_BUTTON" />
		</Button>
	);
	const testButton = (
		<Button type="primary" onClick={test}>
			<Trans id="TEST" />
		</Button>
	);
	const footer = (
		<MenuFooter>
			{cancelButton}
			{testButton}
		</MenuFooter>
	);

	return (
		<Modal footer={footer} visible={visible} onCancel={onCancel} title={i18n._(t`TEST`)}>
			<Content>
				<Form.Item>
					{getFieldDecorator('ids', {
						initialValue: '',
						validateTrigger: 'onBlur',
						rules: [
							{
								validator,
								message: <Trans id="INVALID_INPUT_ERROR" />,
							},
						],
					})(
						<Input.TextArea
							rows={5}
							name="ids"
							onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
								setInputText(event.target.value);
							}}
							placeholder={i18n._(t`ADVERTISING_RULE_PREVIEW_TEXTAREA_PLACEHOLDER`)}
						/>,
					)}
				</Form.Item>
			</Content>
			<Divider />
			<div>
				{
					loading
						? <br />
						: (<Trans id="ADVERTISING_RULE_PREVIEW_MATCH_COUNT" values={{ matchCount }} />)
				}
			</div>
			<Content>
				<Table
					dataSource={dataSource}
					columns={columns}
					size="small"
					pagination={false}
					scroll={{ y: 300 }}
					loading={loading}
				/>
			</Content>
		</Modal>
	);
};

export default Form.create<AdvertisingRulePreviewModalType>({ name: 'advertising_preview_modal' })(
	AdvertisingRulePreviewModal,
);
