import { createGlobalStyle } from 'stylesheets/styled-components';

const GlobalStyles = createGlobalStyle`
	body {
		margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
		overscroll-behavior-y: none;
	}

	code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

	#root {
		height: 100%;
	}


	.ant-select-selection--multiple {
		.ant-select-selection__rendered {
			overflow: hidden;
			
			ul {
				white-space: nowrap;

				li {
					float: none;
					overflow: visible;
					display: inline-block;
					vertical-align: top;
				}
			}

			&:after {
				display: none;
			}
		}
	}
	
	.ant-table-scroll {
		.ant-table-body {
			overflow-x: auto!important;
		}
	}

	.ant-anchor-wrapper {
		overflow: hidden;
	}

	h1.ant-typography, .ant-typography h1 {
		font-size: 24px !important;
	}

	h2.ant-typography, .ant-typography h2 {
		font-size: 20px !important;
	}

	h3.ant-typography, .ant-typography h3 {
		font-size: 18px !important;
	}

	h4.ant-typography, .ant-typography h4 {
		font-size: 16px !important;
	}

	.ant-table-body > table {
		border: 1px solid ${({ theme }) => theme.colors.BORDER_GRAY};
		.tbody {
			tr{
				&:last-child{
					td {
						border-bottom: none;
					}
				}
			}
		}
	}
	.ant-form-item-control {
		line-height: 1;

		.ant-select-selection--multiple {
			ul {
				white-space: normal;
				padding-right: 10px;

				li {
					&:nth-last-child(2) {
 						 max-width: calc(100% - 15px);
					}
				}
			}
		}
	}
`;

export default GlobalStyles;
