export const DEFAULT_CURRENCY = 'CZK';
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_TABLE_PAGE_SIZE = 24;
export const MY_CARS_TABLE_PAGE_SIZE = 100;

export type LocaleType = 'cs' | 'en';
export const SUPPORTED_LOCALES: LocaleType[] = ['en', 'cs'];

export const HTTP_RESPONSE_CODE = { NO_CONTENT: 204 };

export const CONTENT_WIDTH = '1240px';

// SELLER TYPES
export const SELLERTYPE_PRIVATE_SELLER = 'SELLERTYPE_PRIVATE_SELLER';
export const SELLERTYPE_DEALERSHIP = 'SELLERTYPE_DEALERSHIP';
export const SELLERTYPE_CARVAGO_EXPORT_PARTNER = 'SELLERTYPE_CARVAGO_EXPORT_PARTNER';
export const SELLERTYPE_PARTNER_DEALERSHIP = 'SELLERTYPE_PARTNER_DEALERSHIP';
export const SELLERTYPE_CARVAGO = 'SELLERTYPE_CARVAGO';
export const SELLERTYPE_CARVAGO_PARTNER = 'SELLERTYPE_CARVAGO_PARTNER';

export const RESERVATION = 'reservation';

export const ADVERTISED_CAR_QUERY_PARAM = 'advertised-car-id';
export const B2B_EMAIL_QUERY_PARAM = 'cbp';

export const FEED_SUPPORTED_LANGUAGES = [
	'cs',
	'en',
	'sk',
	'pl',
	'de',
	'fr',
	'es',
	'it',
	'fr',
	'ro',
	'ua',
	'uk',
	'sq',
	'sr',
];
export const CARAUDIT_SUPPORTED_LANGUAGES = [
	'cs',
	'en',
	'sk',
	'pl',
	'de',
	'it',
	'fr',
	'ro',
	'ua',
	'es',
	'uk',
	'sq',
	'sr',
];
export const REVIEWS_SUPPORTED_COUNTRIES = [
	'CZ',
	'SK',
	'PL',
	'IT',
	'DE',
	'AT',
	'RO',
	'CH',
	'ES',
	'UA',
	'XK',
];
export const MARGIN_NOT_AVAILABLE = 'N/A';

export const MAXIMUM_BLACKLIST_QUERY_LENGTH = 70;
