import { useSelector } from 'react-redux';
import getYearOptions from 'utils/getYearOptions';
import React from 'react';
import { Form, Switch, Select } from 'antd';
import { Trans } from '@lingui/macro';
import { MenuItemContainer } from '../MenuItemContainer';
import { OptionSelect } from 'components/OptionSelect';
import { selectReferenceData } from 'selectors/referenceData';
import filterOption from 'components/FilterCars/utils/filterOption';
import { makeModelsFromDataWithGroup } from 'utils/activeFilters';
import { NestedItem } from '../styled';
import { CarInfoProps, ModelOptionType } from '../types';

export const CarInfoFields: React.FC<CarInfoProps> = ({ modelData, isLoading, defaultValues, getFieldDecorator, setFieldsValue }) => {
	const referenceData = useSelector(selectReferenceData);
	const yearOptions = getYearOptions();

	return (
		<>
			<MenuItemContainer
				labelTranslation={<Trans id="FILTERS_MODEL_PLACEHOLDER" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('make', {
						initialValue: defaultValues.make,
						rules: [
							{
								required: true,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
					})(
						<OptionSelect
							options={referenceData.makeOptions}
							placeholder={<Trans id="FILTERS_MAKE_PLACEHOLDER" />}
							onChange={() => {
								// reset model when make changes
								setFieldsValue({
									'modelFamily': [],
								});
							}}
							allowClear
						/>,
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator('modelFamily', {
						initialValue: defaultValues.modelFamily,
						rules: [
							{
								required: true,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
					})(
						<Select
							showSearch
							placeholder={<Trans id="FILTERS_MODEL_PLACEHOLDER" />}
							loading={isLoading}
							allowClear
							filterOption={filterOption}
						>
							{modelData &&
								makeModelsFromDataWithGroup(modelData).map((option: ModelOptionType) => (
									<Select.Option key={option.value} value={option.value}>
										{option.nested ? <NestedItem>{option.label}</NestedItem> : option.label}
									</Select.Option>
								))}
						</Select>,
					)}
				</Form.Item>
			</MenuItemContainer>

			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_LABEL_YEAR_MANUFACTURE" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('yearOfManufacture', {
						initialValue: defaultValues.yearOfManufacture,
					})(
						<OptionSelect
							options={yearOptions}
							placeholder={<Trans id="FILTER_YEAR_FROM_PLACEHOLDER" />}
							allowClear
						/>,
					)}
				</Form.Item>
			</MenuItemContainer>

			<MenuItemContainer
				labelTranslation={<Trans id="REVIEWS_ROW_AVAILABLE" />}
				isLoading={isLoading}
			>
				<Form.Item>
					{getFieldDecorator('visibility', {
						valuePropName: 'checked',
						initialValue: defaultValues.visibility,
						rules: [
							{
								required: true,
								message: <Trans id="ERROR_REQUIRED_FIELD" />,
							},
						],
					})(<Switch />)}
				</Form.Item>
			</MenuItemContainer>
		</>
	);
};
