// Circular dependency with RootModel type can't be avoided and doesn't cause issues
/* eslint-disable import/no-cycle */
import { user } from './user';
import { referenceData } from './referenceData';
import { locale } from './locale';
import { Models } from '@rematch/core';

export interface RootModel extends Models<RootModel> {
	referenceData: typeof referenceData,
	user: typeof user,
	locale: typeof locale,
}

export const models: RootModel = {
	referenceData,
	user,
	locale,
};
