import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import { Trans } from '@lingui/macro';
import { GenericActionModal } from 'components/modals/GenericActionModal';

type CancelConfirmModalType = {
	isModalOpened: boolean;
	onCancel: () => void;
	onConfirm: () => void;
};

export const CancelConfirmModal: FunctionComponent<CancelConfirmModalType> = ({
	isModalOpened,
	onCancel,
	onConfirm,
}) => (
	<GenericActionModal
		titleText={<Trans>ADVERTISING_RULE_DELETE_CONFIRM_MODAL_TITLE</Trans>}
		bodyText={<Trans>ADVERTISING_RULE_DELETE_CONFIRM_MODAL_CONTENT</Trans>}
		leftButton={
			<Button type="link" onClick={onCancel}>
				<Trans>ADVERTISING_RULE_DELETE_CONFIRM_MODAL_BACK_BUTTON</Trans>
			</Button>
		}
		rightButton={
			<Button type="primary" size="large" onClick={onConfirm}>
				<Trans>ADVERTISING_RULE_DELETE_CONFIRM_MODAL_CONFIRM_BUTTON</Trans>
			</Button>
		}
		visible={isModalOpened}
		onCancel={onCancel}
	/>
);
