import React, { FunctionComponent } from 'react';
import { Checkbox } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { get } from 'lodash';

type Values = {
	[key: string]: any;
};

type FeatureCheckboxType = FormComponentProps<Values> & {
	referenceData: any;
	type: string;
	initialValues?: Array<number | string>;
};

export const FeatureCheckbox: FunctionComponent<FeatureCheckboxType> = ({
	type,
	referenceData,
	form,
	initialValues = [],
}) => {
	const found_feature = get(referenceData, 'featuresOptions', []).find(
		(item: any) => item.const_key === type,
	);

	const handleChange = () => {
		const features = form.getFieldValue('feature') || [];
		const arr = features.find((item: any) => item === found_feature.id)
			? features.filter((item: any) => item !== found_feature.id)
			: [...features, found_feature.id];

		form.setFieldsValue({
			feature: arr,
		});
	};

	return found_feature ? (
		<Checkbox onChange={handleChange} defaultChecked={initialValues.includes(found_feature.id)}>
			{found_feature.label}
		</Checkbox>
	) : null;
};
