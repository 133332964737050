import { UserType } from 'types/user';

import { EnumType, Country } from './enum';
import { CurrencyType } from './currency';
import { AdvertisingRule } from './advertisingRules';

export type CarLicensePlate = { plate?: string; country: Country };

export type CarImage = {
	id: number;
	path: string;
};

export type CarSellerType = {
	id: number;
	name: string;
	type: {
		const_key: string;
		name?: string;
	};
	address: string;
	city: string;
	street: string;
	country: {
		name: string;
	};
};

export type CarType = {
	id: number;
	title: string;
	power: number;
	power_hp: number;
	manufactured_from: string;
	manufactured_to: string;
	model_edition: {
		id: number;
		model_family: {
			id: number;
			make: {
				id: number;
			};
		};
	};
	car_style: {
		id: number;
	};
	fuel_type: {
		id: number;
	};
	door_count: {
		id: number;
	};
};

export type AdvertisedCarCountType = {
	count: number;
};

export interface CarPrices {
	purchase_price_without_vat: number;
	vat: number;
}
export enum CarStatus {
	ACCEPTED = 'accepted',
	ACTIVE = 'active',
	RESERVED = 'reserved',
	SOLD = 'sold',
	DELISTED = 'delisted',
	ERROR = 'error',
	PREPARED = 'prepared',
	COMPLAINT = 'complaint',
	REJECTED = 'rejected',
}

export type CarPricesRecord = Record<number, CarPrices>;

export type AdvertisedCarType = CarPrices & {
	car: {
		id: number;
	};
	teas_vehicle_id: string;
	id: number;
	title: string;
	slug: string;
	description: string;
	vin: string;
	price: number;
	registration_date: string;
	external_url: string | null;
	external_id: string;
	price_currency: CurrencyType;
	hidden?: boolean;
	in_preparation?: boolean;
	image?: CarImage;
	main_image: string;
	marginPercentage: number;
	fuel_type: {
		name: string;
	};
	seller?: CarSellerType;
	status: string;
	status_name: string;
	url: string;
	error_reason: string;
	images: CarImage[] | undefined;
	mileage: number;
	car_style: EnumType;
	transmission: EnumType;
	cubic_capacity: number;
	days_on_display: number;
	price_without_vat?: number;
	vat_rate?: number;

	gefco_pickup_point?: { id: number };
	carvago_pickup_point?: { id: number };

	location_city?: string;
	location_country?: { id: number; name: string };
	location_number?: number;
	location_post_code?: number;
	location_street?: string;

	purchase_price_cloned?: boolean;
	selling_price_cloned?: boolean;
	cloned_advertised_car?: {
		id: number;
	};
	first_crawl?: string;
	created_at: string;
	updated_at: string;
	exact_price: number;
	exact_price_without_vat: number;
	exact_price_without_margin?: number;
	exact_price_without_margin_without_vat?: number;
	vat_reclaimable: boolean;
	user?: {
		id: number;
		email: string;
		seller?: CarSellerType;
	};
	original_advertised_car?: AdvertisedCarType;
	platform?: boolean;
	hide_vin?: boolean;

	grade: string;
	users: [];
	matching_advertising_rules: AdvertisingRule[];
	available_in_feed: boolean | null;
	available_in_feed_computed: boolean | null;
};

export type selectedCarType = {
	id: number;
	grade: string;
	users: Array<UserType & { key: number }>;
};

export type AdvertisedCarTypeStringId = AdvertisedCarType & {
	car: {
		id: string;
	};
	id: string;
};

export interface PriceInformationType {
	currency: CurrencyType;
	vat_reclaimable: boolean;
	margin_data: {
		price_with_margin: number;
		margin_price: number;
		margin_percent: number;
		price_margin_fixed: true;
	};
	nice_price_data: {
		price: number;
		price_without_vat: number;
	};
	price_data: {
		price: number;
		vat_rate: number;
		price_without_vat: number;
	};
	original_price: {
		price: number;
		price_without_vat: number;
		vat_rate: number;
		currency: CurrencyType;
		vat_reclaimable: boolean;
	};
}

export interface OfferAdvertisedCar {
	readonly blacklisted?: boolean;
	readonly exact_price?: number;
	readonly exact_price_without_vat?: number;
	readonly id: number;
	readonly image?: CarImage;
	readonly original_currency?: CurrencyType;
	readonly price?: number;
	readonly price_currency?: CurrencyType;
	readonly price_without_vat?: number;
	readonly purchase_price_without_vat?: number;
	readonly status_name?: string;
	readonly tradable?: boolean;
	readonly vat?: number;
	readonly vat_rate?: number;
	readonly vat_reclaimable?: boolean;
}

export enum CarSellerTypeEnum {
	SELLERTYPE_PRIVATE_SELLER = 'SELLERTYPE_PRIVATE_SELLER',
	SELLERTYPE_DEALERSHIP = 'SELLERTYPE_DEALERSHIP',
	SELLERTYPE_CARVAGO_EXPORT_PARTNER = 'SELLERTYPE_CARVAGO_EXPORT_PARTNER',
	SELLERTYPE_PARTNER_DEALERSHIP = 'SELLERTYPE_PARTNER_DEALERSHIP',
	SELLERTYPE_CARVAGO = 'SELLERTYPE_CARVAGO',
	SELLERTYPE_CARVAGO_PARTNER = 'SELLERTYPE_CARVAGO_PARTNER',
}
