import { createModel } from '@rematch/core';
import Cookies from 'js-cookie';
// eslint-disable-next-line import/no-cycle
import { RootModel } from './index';

export type UserState = {
	user: User | null;
	isUserLoading: boolean;
};

export type User = {
	email: string;
	id: number;
};

export const user = createModel<RootModel>()({
	state: {
		user: null,
		accessToken: null,
		isUserLoading: false,
	} as UserState,
	reducers: {
		setUser: (state, payload: User) => {
			return {
				...state,
				user: payload,
			};
		},
		removeUser: (state) => {
			Cookies.remove('access_token');
			Cookies.remove('refresh_token');
			return {
				...state,
				user: null,
				accessToken: null,
			};
		},
		setIsUserLoading: (state, payload: boolean) => {
			return {
				...state,
				isUserLoading: payload,
			};
		},
	},
});
