import { formatThousands, formatPrice } from 'utils/formatters';

export const POWER_LIST_KW = [
	{ value: 1, label: '25', realValue: 25 },
	{ value: 2, label: '37', realValue: 37 },
	{ value: 3, label: '44', realValue: 44 },
	{ value: 4, label: '55', realValue: 55 },
	{ value: 5, label: '66', realValue: 66 },
	{ value: 6, label: '74', realValue: 74 },
	{ value: 7, label: '87', realValue: 87 },
	{ value: 8, label: '96', realValue: 96 },
	{ value: 9, label: '110', realValue: 110 },
	{ value: 10, label: '147', realValue: 147 },
	{ value: 11, label: '185', realValue: 185 },
	{ value: 12, label: '223', realValue: 223 },
	{ value: 13, label: '263', realValue: 263 },
	{ value: 14, label: '296', realValue: 296 },
	{ value: 15, label: '334', realValue: 334 },
];

export const POWER_LIST_HP = [
	{ value: 1, label: '34', realValue: 34 },
	{ value: 2, label: '50', realValue: 50 },
	{ value: 3, label: '60', realValue: 60 },
	{ value: 4, label: '74', realValue: 74 },
	{ value: 5, label: '89', realValue: 89 },
	{ value: 6, label: '100', realValue: 100 },
	{ value: 7, label: '117', realValue: 117 },
	{ value: 8, label: '129', realValue: 129 },
	{ value: 9, label: '148', realValue: 148 },
	{ value: 10, label: '198', realValue: 198 },
	{ value: 11, label: '249', realValue: 249 },
	{ value: 12, label: '300', realValue: 300 },
	{ value: 13, label: '353', realValue: 353 },
	{ value: 14, label: '397', realValue: 397 },
	{ value: 15, label: '448', realValue: 448 },
];

export const MILEAGE_LIST = [
	{ value: 2500, label: formatThousands(2500) },
	{ value: 5000, label: formatThousands(5000) },
	{ value: 10000, label: formatThousands(10000) },
	{ value: 15000, label: formatThousands(15000) },
	{ value: 20000, label: formatThousands(20000) },
	{ value: 30000, label: formatThousands(30000) },
	{ value: 40000, label: formatThousands(40000) },
	{ value: 50000, label: formatThousands(50000) },
	{ value: 60000, label: formatThousands(60000) },
	{ value: 70000, label: formatThousands(70000) },
	{ value: 80000, label: formatThousands(80000) },
	{ value: 90000, label: formatThousands(90000) },
	{ value: 100000, label: formatThousands(100000) },
	{ value: 125000, label: formatThousands(125000) },
	{ value: 150000, label: formatThousands(150000) },
	{ value: 175000, label: formatThousands(175000) },
	{ value: 200000, label: formatThousands(200000) },
];

export const PACKAGE_VALUES_LIST = [
	{ value: 300000, label: formatPrice(300000, 'CZK') },
	{ value: 500000, label: formatPrice(500000, 'CZK') },
	{ value: 700000, label: formatPrice(700000, 'CZK') },
	{ value: 1000000, label: formatPrice(1000000, 'CZK') },
	{ value: 1500000, label: formatPrice(1500000, 'CZK') },
	{ value: 2000000, label: formatPrice(2000000, 'CZK') },
	{ value: 3000000, label: formatPrice(3000000, 'CZK') },
	{ value: 4000000, label: formatPrice(4000000, 'CZK') },
	{ value: 5000000, label: formatPrice(5000000, 'CZK') },
	{ value: 7000000, label: formatPrice(7000000, 'CZK') },
	{ value: 10000000, label: formatPrice(10000000, 'CZK') },
	{ value: 15000000, label: formatPrice(15000000, 'CZK') },
	{ value: 20000000, label: formatPrice(20000000, 'CZK') },
];

export const PACKAGE_MARGIN_PERCENT_LIST = [
	{ value: 5, label: '5 %' },
	{ value: 10, label: '10 %' },
	{ value: 15, label: '15 %' },
	{ value: 20, label: '20 %' },
	{ value: 25, label: '25 %' },
	{ value: 30, label: '30 %' },
	{ value: 40, label: '40 %' },
	{ value: 50, label: '50 %' },
];
