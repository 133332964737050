import { FormValues, ModifiedReview } from './types';

export const getTranslationsData = (values: FormValues) => {

	const filteredTranslationsOthersData = Object.fromEntries(Object.entries(values).filter(([key]) => key === 'translations'));

	const modifiedTranslationObject = Object.values(filteredTranslationsOthersData).map((originalItem: ModifiedReview[]) => {

		const getNewReviews = originalItem.map((review: ModifiedReview) => {
			return Object.entries(values).find(([key]) => key === `translations-${review.language}`);
		});
		const getNewAnswer = originalItem.map((answer: ModifiedReview) => {
			return Object.entries(values).find(([key]) => key === `translations-answer-${answer.language}`);
		});

		const newReviewsObject = Object.fromEntries(getNewReviews.map((answerEntry: any) => {
			const [key, value] = answerEntry;
			const newKey = key.replace("translations-", "");
			return [newKey, value];
		}));

		const newAnswerObject = Object.fromEntries(getNewAnswer.map((answerEntry: any) => {
			const [key, value] = answerEntry;
			const newKey = key.replace("translations-answer-", "");
			return [newKey, value];
		}));

		return originalItem.map((item: ModifiedReview) => {
			const isAnswerEmpty = !(newAnswerObject[item.language]?.trim());
			return {
				answer: isAnswerEmpty ? null : newAnswerObject[item.language],
				id: item.id,
				language: {
					id: item.language,
				},
				review: newReviewsObject[item.language],
			};
		});
	});

	return modifiedTranslationObject;
};
