import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Layout } from 'antd';
import logoImage from 'static/images/logo.svg';

interface NavigationProps {
	readonly header?: ReactNode;
}

export const StyledNavigation = styled(Layout.Header)<any>`
	background: #fff;
	padding: 0 30px;
`;

const Navigation: FunctionComponent<NavigationProps> = ({
	header,
}) => (
	<StyledNavigation>
		{header || (
			<a href="/">
				<img src={logoImage} width="102" height="20" alt="" />
			</a>
		)}
	</StyledNavigation>
);

export default Navigation;
