import { SELLERTYPE_CARVAGO } from 'constants/index';

import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { AdvertisedCarType } from 'types/car';
import styledMap from 'styled-map';
import { Alert, Col, Descriptions, Divider, Icon, notification, Row, Select, Tag, Tooltip, Typography } from 'antd';
import { Mutate } from 'restful-react';
import { Plural, Trans } from '@lingui/macro';
import { getStatusColor } from 'utils/getStatusColor';
import CarvagoIcon from 'static/images/carvago-rounded-icon.svg';
import Gallery from 'components/Gallery';
import { useLingui } from '@lingui/react';
import { theme as defaultTheme } from 'stylesheets/theme';
import { getValueForField } from 'utils/getValueForField';

import { CarDetailContent } from '../CarDetailContent';
import { trimEnd } from 'lodash';


type HeaderPropsType = {
	car: AdvertisedCarType;
	refetch: any;
};

const Container = styled.div`
	background-color: #fff;
`;

const TitleDivider = styled(Divider)`
	border: none;
	background-image: linear-gradient(to right, rgb(232, 232, 232) 50%, rgba(232, 232, 232, 0) 0%);
	background-position: bottom;
	background-size: 20px 1px;
	background-repeat: repeat-x;
	height: 1px;
`;

const Dl = styled.dl`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const Dt = styled.dt`
	margin-right: 5px;
`;

const Dd = styled.dd`
	margin-right: 40px;
	display: flex;
`;

const LinkEllipsis = styled.a`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
`;

const TitleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: wrap;
`;

const IconWrapper = styled.div`
	color: #979fad;
	margin-right: 15px;
	display: inline-block;
`;

const StyledTag = styled(Tag)`
	height: 32px;
	display: inline-flex;
	align-items: center;
	vertical-align: top;
`;

const IconHolder = styled.div`
	margin-right: 10px;
	display: inline-block;
`;

const DescriptionItem = styled.div`
	display: flex;
	max-width: 100%;
	margin-right: 20px;
	overflow: hidden;
`;

const Controls = styled.div`
	margin-bottom: 10px;
`;

const VinInfoContainer = styled.div`
	margin-left: 4px;
	margin-right: 4px;
`;

const StyledDescriptions = styled(Descriptions)`
	& > div {
		overflow-x: auto;
	}
`;

const StyledSelect = styled(Select)<any>`
	width: 130px;
	font-weight: 600;
	margin-right: 8px;
	color: ${styledMap('selectStatus', {
		inPreparation: '#eb5907',
		inStock: '#48ad16',
		isHidden: '#eb5907',
		isVisible: '#48ad16',
		isActive: '#48ad16',
		notActive: '#eb5907',
	})};
`;

const { Option } = Select;
const forbiddenCarStatuses = ['accepted', 'reserved', 'error', 'prepared', 'complaint', 'rejected'];

const Header: FunctionComponent<HeaderPropsType> = ({
	car,
}) => {
	const [inPreparation, setInPreparation] = useState<boolean | undefined>(car.in_preparation);
	const [hidden, setHidden] = useState<boolean | undefined>(car.hidden);
	const [isActive, setIsActive] = useState<boolean>(car.status === 'active');
	const { i18n } = useLingui();

	const shouldShowTooltip = forbiddenCarStatuses.includes(car.status);

	useEffect(() => {
		setInPreparation(car.in_preparation);
		setHidden(car.hidden);
		setIsActive(car.status === 'active');
	}, [car.id]);


	return (
		<Container>
			<Gallery images={car.images} />
			{car?.cloned_advertised_car?.id && (
				<Alert
					style={{ textAlign: 'center', backgroundColor: defaultTheme.colors.BACKGROUND_GRAY }}
					message={
						<div>
							<IconHolder>
								<Icon type="warning" theme="filled" style={{ color: defaultTheme.colors.RED }} />
							</IconHolder>
							<Typography.Text>
								<strong>
									<Trans>CAR_DETAIL_CLONED_CAR</Trans>:
								</strong>
							</Typography.Text>{' '}
							<Typography.Text>
								<Trans>CAR_DETAIL_CLONED_CAR_INFO</Trans>:{' '}
								<LinkEllipsis
									href={`${window.location.origin}/car-detail/${car.cloned_advertised_car?.id}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{`${window.location.origin}/car-detail/${car.cloned_advertised_car?.id}`}
								</LinkEllipsis>
							</Typography.Text>
						</div>
					}
					type="error"
					banner
					showIcon={false}
				/>
			)}
			<CarDetailContent>
				<TitleWrapper>
					<Typography.Title level={2}>{car.title}</Typography.Title>
					<Controls>
						{/*if the status dropdown is hidden due to forbidden car status, show the tooltip*/}
						{shouldShowTooltip ? <Tooltip placement="topLeft" title={car.error_reason}>
							<StyledTag color={getStatusColor(car.status)} data-testid="car_detail_status">
								{car.status}
							</StyledTag>
						</Tooltip> : <Mutate verb="PATCH" path={() => `/api/carvago-admin/listedcars/${car.id}/status`}>
							{(patch) => (
								<StyledSelect
									onChange={(value: string) => {
										const isCarActive = value === 'active';
										setIsActive(isCarActive);
										patch({ status: value }).catch((error) => {
											notification.error({ message: error.message });
											setIsActive(!isCarActive);
										});
									}}
									selectStatus={isActive ? 'isActive' : 'notActive'}
									defaultValue={car.status}
									data-testid="car_detail_status"
								>
									<Option value={'active'} key="status-option-1">
										<Trans>CAR_DETAIL_CAR_STATUS_ACTIVE</Trans>
									</Option>
									<Option value={'sold'} key="status-option-2">
										<Trans>CAR_DETAIL_CAR_STATUS_SOLD</Trans>
									</Option>
									<Option value={'delisted'} key="status-option-3">
										<Trans>CAR_DETAIL_CAR_STATUS_DELISTED</Trans>
									</Option>
								</StyledSelect>
							)}
						</Mutate>}

						<Mutate verb="PATCH" path={() => `/api/carvago-admin/listedcars/${car.id}`}>
							{(patch) => (
								<StyledSelect
									onChange={(data: any) => {
										const isPreparation = data === 'in-preparation';
										setInPreparation(isPreparation);
										patch({
											in_preparation: isPreparation,
										}).catch((error) => {
											notification.error({
												message: error.message,
											});
											setInPreparation(!isPreparation);
										});
									}}
									defaultValue={car.in_preparation ? 'in-preparation' : 'in-stock'}
									selectStatus={inPreparation ? 'inPreparation' : 'inStock'}
									data-testid="car_detail_in_preparation"
									data-test-value={car.in_preparation}
								>
									<Option value="in-preparation" key="status-option-1">
										<Trans>CAR_DETAIL_IN_PREPARATION_STATUS</Trans>
									</Option>
									<Option value="in-stock" key="status-option-2">
										<Trans>CAR_DETAIL_IN_STOCK_STATUS</Trans>
									</Option>
								</StyledSelect>
							)}
						</Mutate>

						<Mutate verb="PATCH" path={() => `/api/carvago-admin/listedcars/${car.id}/flag/hidden`}>
							{(patch) => (
								<StyledSelect
									onChange={(data: any) => {
										const isHidden = data === 'is-hidden';
										setHidden(isHidden);
										patch({
											hidden: isHidden,
										}).catch((error) => {
											notification.error({
												message: error.message,
											});
											setHidden(!isHidden);
										});
									}}
									defaultValue={car.hidden ? 'is-hidden' : 'is-visible'}
									selectStatus={hidden ? 'isHidden' : 'isVisible'}
									data-testid="car_detail_hidden"
									data-test-value={car.hidden}
								>
									<Option value="is-hidden" key="status-option-1">
										<Trans>CAR_DETAIL_CAR_IS_HIDDEN</Trans>
									</Option>
									<Option value="is-visible" key="status-option-2">
										<Trans>CAR_DETAIL_CAR_IS_VISIBLE</Trans>
									</Option>
								</StyledSelect>
							)}
						</Mutate>
					</Controls>
				</TitleWrapper>

				<Row>
					{car?.seller?.type.const_key === SELLERTYPE_CARVAGO && (
						<Col sm={4} data-testid="car_detail_seller_carvago">
							<IconWrapper>
								<img src={CarvagoIcon} alt="Carvago" />
							</IconWrapper>
							Carvago Cars
						</Col>
					)}
					{car?.seller && car?.seller.address && (
						<Col sm={6} data-testid="car_detail_seller_location">
							<Dt>
								<IconWrapper>
									<Icon type="home" />
								</IconWrapper>
								{car.seller.name}
							</Dt>
							{car.seller.address}, {car.seller.city}, {car.seller.country.name}
						</Col>
					)}
					<Col sm={6} data-testid="car_detail_user">
						{car.user?.email && (
							<IconWrapper>
								<Icon type="user" />
							</IconWrapper>
						)}
						{car.user?.email}
					</Col>
				</Row>

				<TitleDivider dashed />
				<Dl>
					<DescriptionItem>
						<Dt>VIN:</Dt>
						<Dd>
							{car.vin}
							<Typography.Paragraph
								copyable={{
									text: car.vin,
								}}
							/>
							<VinInfoContainer>
										(
								{car.hide_vin ? (
									<Trans id="CAR_DETAIL_HEADER_VIN_HIDDEN" />
								) : (
									<Trans id="CAR_DETAIL_HEADER_VIN_VISIBLE" />
								)}
										)
							</VinInfoContainer>
						</Dd>
					</DescriptionItem>
					<DescriptionItem>
						<Dt>ID:</Dt>
						<Dd>
							{car.id}
							<Typography.Paragraph
								copyable={{
									text: car.id.toString(),
								}}
							/>
						</Dd>
					</DescriptionItem>
					<DescriptionItem>
						<Dt>URL:</Dt>
						<Dd>
							<LinkEllipsis
								href={`${trimEnd(process.env.REACT_APP_PLATFORM_URL, '/')}/car/${car.id}/${
									car.slug
								}`}
								target="_blank"
								rel="noopener noreferrer"
								data-testid="car_detail_platform_url"
							>
								{`${trimEnd(process.env.REACT_APP_PLATFORM_URL, '/')}/car/${car.id}/${car.slug}`}
							</LinkEllipsis>
							<Typography.Paragraph
								copyable={{
									text: `${trimEnd(process.env.REACT_APP_PLATFORM_URL, '/')}/car/${car.id}/${
										car.slug
									}`,
								}}
							/>
						</Dd>
					</DescriptionItem>
					<DescriptionItem>
						<Dt>EXTERNAL_ID:</Dt>
						<Dd>
							{car.external_id}
							<Typography.Paragraph
								copyable={{
									text: car.external_id.toString(),
								}}
							/>
						</Dd>
					</DescriptionItem>
					<DescriptionItem>
						{car.original_advertised_car && (
							<>
								<Dt>
									<Trans>CAR_DETAIL_HEADER_ORIGINAL_LINK</Trans>:
								</Dt>
								<Dd>
									<LinkEllipsis
										href={`/car-detail/${car.original_advertised_car.id}`}
										target="_blank"
										rel="noopener noreferrer"
										data-testid="car_detail_source_url"
									>
										{`${window.location.origin}/car-detail/${car.original_advertised_car.id}`}
									</LinkEllipsis>
								</Dd>
							</>
						)}
					</DescriptionItem>

					{car.external_url && (
						<DescriptionItem>
							<Dt>
								<Trans>CAR_DETAIL_HEADER_SOURCE</Trans>:
							</Dt>
							<Dd>
								<LinkEllipsis href={car.external_url} target="_blank" rel="noopener noreferrer">
									{car.external_url}
								</LinkEllipsis>
							</Dd>
						</DescriptionItem>
					)}
				</Dl>
				<StyledDescriptions
					layout="vertical"
					bordered
					column={7}
					size="small"
					data-testid="car_detail_basic_info_table"
				>
					<Descriptions.Item label={<Trans>CAR_DETAIL_HEADER_MILEAGE</Trans>}>
						{getValueForField(
							car?.mileage != null && (
								<>
									{i18n.number(car.mileage)}
									&nbsp;km
								</>
							),
						)}
					</Descriptions.Item>
					<Descriptions.Item label={<Trans>CAR_DETAIL_HEADER_FIRST_REGISTRATION_DATE</Trans>}>
						{getValueForField(
							car?.registration_date && i18n.date(new Date(car.registration_date)),
						)}
					</Descriptions.Item>
					<Descriptions.Item label={<Trans>CAR_DETAIL_HEADER_CAR_STYLE</Trans>}>
						{getValueForField(car?.car_style?.name)}
					</Descriptions.Item>
					<Descriptions.Item label={<Trans>CAR_DETAIL_HEADER_TRANSMISSION</Trans>}>
						{getValueForField(car?.transmission?.name)}
					</Descriptions.Item>
					<Descriptions.Item label={<Trans>CAR_DETAIL_HEADER_CUBIC_CAPACITY</Trans>}>
						{getValueForField(
							car?.cubic_capacity != null && (
								<>
									{i18n.number(car.cubic_capacity)}
									&nbsp;cc
								</>
							),
						)}
					</Descriptions.Item>

					<Descriptions.Item label={<Trans>CAR_DETAIL_HEADER_DOD</Trans>}>
						<Plural value={car.days_on_display} other="# days" one="# day" />
					</Descriptions.Item>
				</StyledDescriptions>
			</CarDetailContent>
		</Container>
	);
};

export default Header;
