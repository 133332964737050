import React, { FunctionComponent } from 'react';
import { Button } from 'antd';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { GenericActionModal } from 'components/modals/GenericActionModal';

interface CancelConfirmModalType {
	readonly isModalOpen: boolean;
	readonly onCancel: () => void;
}

export const CancelConfirmModal: FunctionComponent<CancelConfirmModalType> = ({
	isModalOpen,
	onCancel,
}) => (
	<GenericActionModal
		titleText={<Trans id="REVIEWS_DETAIL_CANCEL_CONFIRM_MODAL_TITLE" />}
		bodyText={<Trans id="REVIEWS_DETAIL_CANCEL_CONFIRM_MODAL_CONTENT" />}
		leftButton={
			<Button type="link" onClick={onCancel}>
				<Trans id="REVIEWS_DETAI_CANCEL_CONFIRM_MODAL_BACK_BUTTON" />
			</Button>
		}
		rightButton={
			<Link to="/reviews">
				<Button type="primary" size="large">
					<Trans id="REVIEWS_DETAIL_CANCEL_CONFIRM_MODAL_LEAVE_BUTTON" />
				</Button>
			</Link>
		}
		visible={isModalOpen}
		onCancel={onCancel}
	/>
);
