import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Divider, Tabs, Typography } from 'antd';
import FilterCars from 'components/FilterCars';
import Layout from 'components/PrivateLayout';
import Section from 'components/Section';
import useFetch from 'hooks/useFetch';
import { isEmpty, omitBy } from 'lodash';
import queryString from 'query-string';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FeedType } from 'types/feed';
import { FiltersType } from 'types/filters';
import { paramsFromActiveFilters } from 'utils/activeFilters';
import parseQuery from 'utils/parseQuery';
import { omitter } from '../../utils/omitter';
import ArchiveTable from './components/ArchiveTable';
import CarTable from './components/CarTable';
import Feed from './components/Feed';
import { mapFilters, mapUrlParams } from './mapUrlParams';

const { TabPane } = Tabs;

// #region styles

const TableSection = styled(Section)`
	margin-top: 30px;
`;

// #endregion

type DashboardType = RouteComponentProps;

const transformTextToArray = (text?: string) => {
	if (text && text.length > 0) {
		return text
			.split(';')
			.map((id) => id.trim())
			.filter((id, index, self) => id.length > 0 && self.indexOf(id, index + 1) < 0);
	} else {
		return undefined;
	}
};

const Dashboard: FunctionComponent<DashboardType> = ({ history }) => {
	const [activeFilters, setActiveFilters] = useState<FiltersType>({});
	const [searchString, setSearchString] = useState('');
	const [feedParams, setFeedParams] = useState<FeedType>({});
	const [collapsed, setCollapsed] = useState(true);
	const [feedUrl, setFeedUrl] = useState({
		facebook: '',
		google: '',
	});
	const { i18n } = useLingui();

	const advertisedCarIds = transformTextToArray(activeFilters?.['advertised-car-id']);

	const finalActiveFilters = {
		...activeFilters,
		'advertised-car-id': advertisedCarIds,
	};
	const searchQuery = searchString
		? queryString.stringify( { ...finalActiveFilters, search: encodeURIComponent(searchString) }, { arrayFormat: 'bracket' })
		: queryString.stringify(finalActiveFilters, { arrayFormat: 'bracket' });

	const makeFeedUrl = (query: string) => {
		const apiUrl = process.env.REACT_APP_FEED_URI;
		setFeedUrl({
			facebook: `${apiUrl}marketing/feed/facebook.xml?${query}`,
			google: `${apiUrl}marketing/feed/google.csv?${query}`,
		});
	};

	useEffect(() => {
		const filters = parseQuery(queryString.parse(history.location.search));

		const mappedActiveFilters = omitBy(mapFilters(filters), omitter);
		const mappedFeedParams = omitBy(mapUrlParams(filters), omitter);

		const advertisedCarIds = mappedActiveFilters['advertised-car-id'];
		if (advertisedCarIds && Array.isArray(advertisedCarIds) && advertisedCarIds.length > 0) {
			mappedActiveFilters['advertised-car-id'] = (advertisedCarIds as number[])
				.filter((id, index, self) => !isNaN(id) && self.indexOf(id, index + 1) < 0)
				.join('; ');
		}

		setActiveFilters(mappedActiveFilters);
		setFeedParams(mappedFeedParams);
	}, [history.location.search, setActiveFilters, setFeedParams]);

	const handleFilterSubmit = (values: FiltersType) => {
		setActiveFilters(values);

		const advertisedCarIds = transformTextToArray(values?.['advertised-car-id']);

		const search = {
			...values,
			...feedParams,
		} as any;

		if (advertisedCarIds && advertisedCarIds.length > 0) {
			search['advertised-car-id'] = advertisedCarIds;
		} else {
			delete search['advertised-car-id'];
		}

		history.push({
			pathname: history.location.pathname,
			search: paramsFromActiveFilters(search),
		});
	};

	const handleFeedGenerate = (values: FeedType) => {
		setFeedParams(values);

		if (searchQuery || !isEmpty(values)) {
			const sortWithDirection = values.sort?.split('_') || [];
			const direction = sortWithDirection[1];
			const sort = direction ? sortWithDirection[0] : values.sort;

			let advertisedCarIds;
			if (
				activeFilters &&
				activeFilters['advertised-car-id'] &&
				activeFilters['advertised-car-id'].length > 0
			) {
				advertisedCarIds = transformTextToArray(activeFilters['advertised-car-id']);
			}

			const finalValues = {
				page: 1,
				...activeFilters,
				...values,
				...(sort && { sort }),
				...(direction && { direction }),
				'advertised-car-id': advertisedCarIds ? advertisedCarIds : undefined,
			};

			const search = queryString.stringify(finalValues, { arrayFormat: 'bracket' });

			makeFeedUrl(search);
		}
	};

	const onFiltersChange = () => {
		setFeedUrl({
			facebook: '',
			google: '',
		});
	};

	return (
		<Layout collapsed={collapsed} setCollapsed={setCollapsed}>
			<FilterCars
				handleSubmit={handleFilterSubmit}
				initialValues={activeFilters}
				onFormChange={onFiltersChange}
			/>
			<Feed
				onFeedGenerate={handleFeedGenerate}
				googleFeedUrl={feedUrl.google}
				fbFeedUrl={feedUrl.facebook}
				initialValues={feedParams}
				onFormChange={onFiltersChange}
			/>
			<TableSection>
				<Typography.Title level={2}>
					<Trans>CARS_TABLE_TITLE</Trans>
				</Typography.Title>

				<Divider dashed />

				<Tabs defaultActiveKey="1">
					<TabPane tab={i18n._(t`TABLE_LABEL_CARS`)} key="1">
						<CarTable
							searchQuery={searchQuery}
							activeFilters={activeFilters}
							handleFilterSubmit={handleFilterSubmit}
							handleSearch={setSearchString}
						/>
					</TabPane>
					<TabPane tab={i18n._(t`TABLE_LABEL_ARCHIVE`)} key="2">
						<ArchiveTable
							searchQuery={searchQuery}
							activeFilters={activeFilters}
							handleFilterSubmit={handleFilterSubmit}
							handleSearch={setSearchString}
						/>
					</TabPane>
				</Tabs>
			</TableSection>
		</Layout>
	);
};

export default Dashboard;
