import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { Icon, Spin } from 'antd';

const Container = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

const Label = styled.div`
	font-weight: 600;
	margin-right: 3rem;
`;

const ChildrenContainer = styled.div`
	width: 30rem;
`;

interface MenuItemProps {
	readonly labelTranslation: ReactNode;
	readonly isLoading?: boolean;
}

export const MenuItemContainer: FunctionComponent<MenuItemProps> = ({
	labelTranslation,
	children,
	isLoading,
}) => {
	if (isLoading) {
		return (
			<Spin
				indicator={<Icon type="loading" style={{ fontSize: '1.4rem' }} />}
				style={{ marginRight: '1rem' }}
			/>
		);
	}

	return (
		<Container>
			<Label>{labelTranslation}</Label>
			<ChildrenContainer>{children}</ChildrenContainer>
		</Container>
	);
};
