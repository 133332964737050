import React, { FunctionComponent } from 'react';
import { CarImage } from './CarImage';
import { CarThumbnailProps } from './types';
import { ReviewEditLink } from 'pages/Reviews/ReviewsTableColumn';

const CarReviewsImage: FunctionComponent<CarThumbnailProps> = ({ reviewId, photo }) => {
	const getPath = photo?.storage_service_id ?? null;
	const getNamespace = photo?.namespace;

	return (
		<ReviewEditLink reviewId={reviewId}>
			<CarImage imagePath={getPath} namespace={getNamespace} />
		</ReviewEditLink>
	);
};

export default CarReviewsImage;
