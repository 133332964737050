import Cookies from 'js-cookie';
import { LocaleType } from 'constants/index';
import { Store } from '../../store';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from '../../constants';
import { activateLocale, loadLocale } from '../../locale/activateLocale';
import { selectLocale } from './selectors';

const parseLocale = (locale: string): string | undefined =>
	locale.trim().split(/-|_/)[0];

const getLocale = (store?: Store): string | undefined => {
	if (store) {
		return selectLocale(store.getState()) ?? Cookies.get('locale');
	}
	return Cookies.get('locale');
};

const getLocaleFromBrowser = (): string | undefined =>
	parseLocale(
		navigator.language ||
		// @ts-expect-error navigator doesn't know alternative usages
		navigator.userLanguage,
	);

const getValidLocale = (locale: string | undefined): LocaleType => {
	if (SUPPORTED_LOCALES.includes(locale as LocaleType)) {
		return locale as LocaleType;
	}
	return DEFAULT_LOCALE;
};

export const detectLocale = (
	store?: Store,
): LocaleType => {
	const locale = getLocale(store) || getLocaleFromBrowser();

	return getValidLocale(locale);
};

export const loadTranslations = async (locale: LocaleType): Promise<void> => {
	const messages = await loadLocale(locale);
	activateLocale(locale, messages);
};
