import React from 'react';
import styled from 'styled-components/macro';
import { Menu, Dropdown, Switch, Icon, notification } from 'antd';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { History } from 'history';
import apiFetch from 'utils/apiFetch';

import { DetailInfoModalType } from './DetailInfoModal';
import { AdvertisingRuleCarQueryCell, AdvertisingRuleNameCell, AdvertisingRuleUserQueryCell } from '../../../components/BlacklistCells';

const { Item } = Menu;

const StyledDropdown = styled(Dropdown)`
	cursor: pointer;
`;

interface getTableColumnsParams {
	readonly fetchData: () => Promise<any>;
	readonly history: History;
	readonly deleteFilterAfterConfirmation: (id: string) => void;
	readonly onClick: (bodyText: string, titleText: DetailInfoModalType) => void;
	readonly errorMessage: string;
}

export const getTableColumns = ({
	fetchData,
	history,
	deleteFilterAfterConfirmation,
	onClick,
	errorMessage,
}: getTableColumnsParams) => [
	{
		title: <Trans>ADVERTISING_RULE_TABLE_HEAD_NAME</Trans>,
		dataIndex: 'name',
		render: (name: string, row: any) =>
			<AdvertisingRuleNameCell content={name} row={row} onClick={onClick} />,
	},
	{
		title: <Trans>ADVERTISING_RULE_CAR_QUERY</Trans>,
		dataIndex: 'advertised_car_query',
		render: (query: string) =>
			<AdvertisingRuleCarQueryCell content={query} onClick={onClick} />,
		width: 300,
	},
	{
		title: <Trans>ADVERTISING_RULE_USER_QUERY</Trans>,
		dataIndex: 'user_query',
		render: (query: string) =>
			<AdvertisingRuleUserQueryCell content={query} onClick={onClick} />,
		width: 250,
	},
	{
		title: <Trans>ADVERTISING_RULE_TABLE_HEAD_ACTIVE</Trans>,
		dataIndex: 'active',
		render: (checked: boolean, row: any) => (
			<Switch
				checked={checked}
				onChange={(newCheckState: boolean) => {
					apiFetch(`/api/carvago-admin/advertising-rule/${row.id}`, {
						method: 'PUT',
						body: JSON.stringify({
							...row,
							active: newCheckState,
						}),
						headers: {
							'Content-Type': 'application/json',
						},
					})
						.then(() => {
							fetchData();
						})
						.catch(() => {
							notification.error({
								message: errorMessage,
							});
						});
				}}
				data-testid="Advertising-rules-column-active"
			/>
		),
		width: 100,
	},
	{
		title: <Trans>ADVERTISING_RULE_TABLE_HEAD_ACTION</Trans>,
		key: 'controls',
		dataIndex: 'id',
		render: (id: string, row: any) => (
			<StyledDropdown
				overlay={
					<Menu>
						<Item
							key="0"
							onClick={() => {
								apiFetch(`/api/carvago-admin/advertising-rule`, {
									method: 'POST',
									body: JSON.stringify({
										...row,
									}),
									headers: {
										'Content-Type': 'application/json',
									},
								})
									.then(async (response) => {
										const newFilter = await response.json();

										history.push(`/advertising-rule-edit/${newFilter.id}`);
									})
									.catch(() => {
										notification.error({
											message: errorMessage,
										});
									});
							}}
							data-testid="Advertising-rules-column-controls-duplicate"
						>
							<Trans>ADVERTISING_RULE_ROW_CONTROL_DUPLICATE</Trans>
						</Item>
						<Item key="1">
							<Link
								to={`/advertising-rule-edit/${id}`}
								data-testid="Advertising-rules-column-controls-edit"
							>
								<Trans>ADVERTISING_RULE_ROW_CONTROL_EDIT</Trans>
							</Link>
						</Item>
						<Item
							key="2"
							onClick={() => {
								deleteFilterAfterConfirmation(id);
							}}
							data-testid="Advertising-rules-column-controls-delete"
						>
							<Trans>ADVERTISING_RULE_ROW_CONTROL_DELETE</Trans>
						</Item>
					</Menu>
				}
			>
				<Icon type="ellipsis" data-testid="Advertising-rules-column-controls" />
			</StyledDropdown>
		),
		width: 100,
	},
];
